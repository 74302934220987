import { useTheme } from "styled-components";
import Button from "../../components/ds/Button";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import { NavLink } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <div className={`bg-[${useTheme().colors.surface50Background}]`}>
      <div
        className={
          "container mx-auto h-screen flex xl:block items-center justify-center"
        }
      >
        <div className={"xl:flex grid xl:flex-row items-center xl:h-full"}>
          <div className={"xl:w-2/6 row-start-2"}>
            <div
              className={
                "font-semibold xl:text-[28px] text-2xl mb-4 text-center " +
                `text-[${useTheme().colors.text90}]`
              }
            >
              Page not found :(
            </div>

            <div
              className={
                "font-medium xl:text-lg text-sm mb-6 text-center " +
                `text-[${useTheme().colors.text60}]`
              }
            >
              this page doesn’t exist or was removed.
              <br />
              We suggest you back to home.
            </div>

            <NavLink to={"/"}>
              <Button
                text={"Back to dashboard"}
                className={"flex mx-auto items-center h-14"}
                buttonStyleType={"BPrimary"}
                icon={<ArrowLeft2Outline fill={useTheme().colors.text20} />}
              />
            </NavLink>
          </div>
          <div className={"xl:w-4/6 row-start-1"}>
            <svg
              width="1211"
              height="723"
              viewBox="0 0 1211 723"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: "100%", height: "100%" }}
            >
              <path
                d="M413.991 241.237V213.533L496.348 95.6649H537.4V210.51H559.563V241.237H537.4V277H502.14V241.237H413.991ZM504.407 133.443L452.777 210.51H504.407V133.443ZM576.975 182.807C576.975 153.759 582.18 131.008 592.59 114.554C603.167 98.0995 620.629 89.8723 644.975 89.8723C669.321 89.8723 686.699 98.0995 697.109 114.554C707.687 131.008 712.976 153.759 712.976 182.807C712.976 212.022 707.687 234.94 697.109 251.563C686.699 268.185 669.321 276.496 644.975 276.496C620.629 276.496 603.167 268.185 592.59 251.563C582.18 234.94 576.975 212.022 576.975 182.807ZM678.22 182.807C678.22 170.382 677.381 159.972 675.701 151.577C674.19 143.014 671 136.046 666.131 130.673C661.43 125.3 654.378 122.613 644.975 122.613C635.573 122.613 628.437 125.3 623.568 130.673C618.866 136.046 615.676 143.014 613.997 151.577C612.486 159.972 611.73 170.382 611.73 182.807C611.73 195.567 612.486 206.313 613.997 215.044C615.508 223.607 618.698 230.575 623.568 235.948C628.437 241.153 635.573 243.755 644.975 243.755C654.378 243.755 661.514 241.153 666.383 235.948C671.252 230.575 674.442 223.607 675.953 215.044C677.464 206.313 678.22 195.567 678.22 182.807ZM733.246 241.237V213.533L815.603 95.6649H856.655V210.51H878.818V241.237H856.655V277H821.395V241.237H733.246ZM823.662 133.443L772.032 210.51H823.662V133.443Z"
                fill="#EAEAEC"
              />
              <path
                d="M2.23852 440.106C5.24611 444.295 7.38488 446.028 11.7161 448.88C12.9038 449.662 16.3793 450.557 17.6984 450.338C19.2903 450.074 21.3636 449.647 22.8587 449.017C24.1334 448.481 25.3 447.879 26.2915 446.951C27.2888 446.018 28.9526 444.427 29.4894 443.128C30.2347 441.324 29.57 438.3 28.6916 436.638C27.1573 433.735 24.5165 431.897 21.7519 434.485C19.2554 436.822 20.1099 441.43 21.1724 444.312C21.8185 446.065 23.257 447.293 24.5036 448.625C26.0986 450.329 27.2775 451.588 29.8202 451.891C34.6042 451.912 38.5287 450.614 40.7857 446.144C41.9125 443.913 42.2051 441.277 42.7123 438.869C43.2452 436.34 42.8723 434.502 41.9317 432.133C40.997 429.78 39.0076 428.576 36.6847 430.073C35.195 431.033 36.0063 432.375 36.3083 433.744C36.8352 436.132 37.7705 438.361 39.4693 440.175C41.0697 441.885 43.2351 443.406 45.464 444.166C47.7956 444.96 50.8869 444.015 52.9948 443.046C56.1837 441.581 57.4725 440.039 58.3768 436.596C59.2287 433.353 60.8475 430.409 59.3268 427.146C58.5648 425.511 57.7401 424.056 56.5092 422.741C55.5921 421.761 55.438 421.615 54.1527 421.77C52.9685 421.912 51.373 422.929 50.7257 423.954C49.8281 425.376 52.162 428.856 52.7834 430.112C54.9655 434.523 58.115 438.298 63.2896 438.563C69.0402 438.858 73.9204 434.26 77.8384 430.592"
                stroke="#BEC6CB"
                stroke-width="3.1377"
                stroke-linecap="round"
              />
              <path
                d="M1089.38 475.744C1085.31 475.744 1083.64 472.864 1085.67 469.332C1086.84 467.284 1086.14 464.674 1084.09 463.504L1080.2 461.277C1078.42 460.219 1076.13 460.849 1075.07 462.627L1074.82 463.054C1072.8 466.587 1069.47 466.587 1067.42 463.054L1067.17 462.627C1066.16 460.849 1063.86 460.219 1062.09 461.277L1058.19 463.504C1056.15 464.674 1055.45 467.307 1056.62 469.354C1058.67 472.864 1057 475.744 1052.93 475.744C1050.59 475.744 1048.65 477.657 1048.65 480.019V483.979C1048.65 486.319 1050.57 488.254 1052.93 488.254C1057 488.254 1058.67 491.134 1056.62 494.667C1055.45 496.714 1056.15 499.324 1058.19 500.494L1062.09 502.722C1063.86 503.779 1066.16 503.149 1067.22 501.372L1067.46 500.944C1069.49 497.412 1072.82 497.412 1074.87 500.944L1075.11 501.372C1076.17 503.149 1078.47 503.779 1080.24 502.722L1084.14 500.494C1086.18 499.324 1086.88 496.692 1085.71 494.667C1083.66 491.134 1085.33 488.254 1089.4 488.254C1091.74 488.254 1093.68 486.342 1093.68 483.979V480.019C1093.65 477.679 1091.74 475.744 1089.38 475.744ZM1071.15 489.312C1067.13 489.312 1063.84 486.027 1063.84 481.999C1063.84 477.972 1067.13 474.687 1071.15 474.687C1075.18 474.687 1078.47 477.972 1078.47 481.999C1078.47 486.027 1075.18 489.312 1071.15 489.312Z"
                fill="#F5F5F6"
              />
              <path
                d="M228.379 689.744C224.307 689.744 222.642 686.864 224.667 683.332C225.837 681.284 225.139 678.674 223.092 677.504L219.199 675.277C217.422 674.219 215.127 674.849 214.069 676.627L213.822 677.054C211.797 680.587 208.467 680.587 206.419 677.054L206.172 676.627C205.159 674.849 202.864 674.219 201.087 675.277L197.194 677.504C195.147 678.674 194.449 681.307 195.619 683.354C197.667 686.864 196.002 689.744 191.929 689.744C189.589 689.744 187.654 691.657 187.654 694.019V697.979C187.654 700.319 189.567 702.254 191.929 702.254C196.002 702.254 197.667 705.134 195.619 708.667C194.449 710.714 195.147 713.324 197.194 714.494L201.087 716.722C202.864 717.779 205.159 717.149 206.217 715.372L206.464 714.944C208.489 711.412 211.819 711.412 213.867 714.944L214.114 715.372C215.172 717.149 217.467 717.779 219.244 716.722L223.137 714.494C225.184 713.324 225.882 710.692 224.712 708.667C222.664 705.134 224.329 702.254 228.402 702.254C230.742 702.254 232.677 700.342 232.677 697.979V694.019C232.654 691.679 230.742 689.744 228.379 689.744ZM210.154 703.312C206.127 703.312 202.842 700.027 202.842 695.999C202.842 691.972 206.127 688.687 210.154 688.687C214.182 688.687 217.467 691.972 217.467 695.999C217.467 700.027 214.182 703.312 210.154 703.312Z"
                fill="#F5F5F6"
              />
              <path
                d="M1173.7 130.33L1163.24 127.529L1174.06 87.1666C1176.58 77.7487 1174.01 75.0177 1168.37 81.0749L1164.29 85.451L1129.82 122.479C1125.1 127.522 1125.12 132.697 1129.86 133.966L1140.32 136.767L1129.5 177.13C1126.98 186.548 1129.55 189.279 1135.19 183.221L1139.27 178.845L1173.74 141.817C1178.46 136.774 1178.43 131.599 1173.7 130.33Z"
                fill="#F5F5F6"
              />
              <path
                d="M339.018 122.047L332.026 120.174L339.259 93.1817C340.947 86.8835 339.229 85.0572 335.452 89.1079L332.728 92.0344L309.675 116.797C306.517 120.17 306.535 123.63 309.703 124.479L316.694 126.352L309.461 153.344C307.774 159.643 309.491 161.469 313.268 157.418L315.993 154.492L339.045 129.729C342.204 126.357 342.185 122.896 339.018 122.047Z"
                fill="#F5F5F6"
              />
              <path
                d="M95.5382 208.211C90.7623 216.886 85.2905 225.17 79.8505 233.438"
                stroke="#BEC6CB"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M78.1544 208C79.6814 209.069 80.8827 210.873 82.1705 212.205C85.2246 215.361 88.4332 218.363 91.5337 221.474C94.7129 224.663 97.5271 228.285 100.838 231.32"
                stroke="#BEC6CB"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M646.825 590.045C740.125 590.045 815.76 573.712 815.76 553.565C815.76 533.418 740.125 517.086 646.825 517.086C553.524 517.086 477.889 533.418 477.889 553.565C477.889 573.712 553.524 590.045 646.825 590.045Z"
                fill="#EFEFEF"
              />
              <path
                d="M394.975 522.252C402.216 522.252 408.086 516.384 408.086 509.145C408.086 501.907 402.216 496.039 394.975 496.039C387.734 496.039 381.864 501.907 381.864 509.145C381.864 516.384 387.734 522.252 394.975 522.252Z"
                fill="#002B51"
              />
              <path
                d="M506.92 525.384C516.955 525.384 525.091 517.251 525.091 507.219C525.091 497.187 516.955 489.055 506.92 489.055C496.884 489.055 488.749 497.187 488.749 507.219C488.749 517.251 496.884 525.384 506.92 525.384Z"
                fill="#002B51"
              />
              <path
                d="M715.995 470.387L780.452 509.193H888.978"
                stroke="#002B51"
                stroke-width="10.5237"
                stroke-linecap="round"
              />
              <path
                d="M568.003 469.73L503.545 508.537H395.019"
                stroke="#002B51"
                stroke-width="10.5237"
                stroke-linecap="round"
              />
              <path
                d="M602.205 499.328L557.479 551.947L458.162 594.042"
                stroke="#002B51"
                stroke-width="10.5237"
                stroke-linecap="round"
              />
              <path
                d="M685.733 499.984L730.459 552.603L829.777 594.698"
                stroke="#002B51"
                stroke-width="10.5237"
                stroke-linecap="round"
              />
              <path
                d="M780.419 525.384C790.455 525.384 798.59 517.251 798.59 507.219C798.59 497.187 790.455 489.055 780.419 489.055C770.384 489.055 762.249 497.187 762.249 507.219C762.249 517.251 770.384 525.384 780.419 525.384Z"
                fill="#002B51"
              />
              <path
                d="M668.633 154.505C668.655 150.081 667.398 145.744 665.015 142.017C662.631 138.289 659.221 135.328 655.195 133.49C651.17 131.653 646.699 131.016 642.319 131.656C637.94 132.297 633.839 134.187 630.509 137.101C627.178 140.015 624.76 143.828 623.544 148.082C622.329 152.336 622.368 156.85 623.657 161.083C624.945 165.316 627.429 169.086 630.809 171.942C634.189 174.798 638.323 176.618 642.712 177.182V505.327C640.955 506.011 639.491 507.288 638.575 508.936C637.66 510.584 637.349 512.501 637.697 514.354C638.045 516.207 639.03 517.88 640.482 519.084C641.934 520.288 643.76 520.947 645.647 520.947C647.533 520.947 649.36 520.288 650.811 519.084C652.263 517.88 653.248 516.207 653.596 514.354C653.944 512.501 653.634 510.584 652.718 508.936C651.802 507.288 650.339 506.011 648.581 505.327V177.182C654.112 176.491 659.201 173.806 662.893 169.631C666.585 165.456 668.626 160.077 668.633 154.505Z"
                fill="#002B51"
              />
              <path
                d="M643.67 530.199C723.018 530.199 787.343 465.897 787.343 386.576C787.343 307.255 723.018 242.953 643.67 242.953C564.321 242.953 499.996 307.255 499.996 386.576C499.996 465.897 564.321 530.199 643.67 530.199Z"
                fill="#DCE1E2"
              />
              <g style={{ mixBlendMode: "multiply" }} opacity="0.3">
                <path
                  d="M662.838 478.655C642 478.646 621.414 474.108 602.505 465.355C583.596 456.602 566.818 443.842 553.333 427.962C539.848 412.081 529.98 393.459 524.411 373.386C518.842 353.313 517.707 332.27 521.083 311.715C510.022 329.797 503.101 350.103 500.818 371.175C498.534 392.247 500.944 413.563 507.876 433.594C514.807 453.624 526.087 471.873 540.907 487.031C555.727 502.189 573.72 513.88 593.594 521.265C613.468 528.651 634.731 531.547 655.857 529.747C676.983 527.947 697.449 521.495 715.786 510.854C734.123 500.213 749.876 485.647 761.917 468.201C773.957 450.754 781.985 430.86 785.425 409.946C772.596 430.941 754.583 448.287 733.116 460.319C711.649 472.351 687.449 478.665 662.838 478.655Z"
                  fill="#A3A7A7"
                />
              </g>
              <path
                d="M646.083 441.985C693.382 441.985 731.726 403.654 731.726 356.371C731.726 309.088 693.382 270.758 646.083 270.758C598.783 270.758 560.439 309.088 560.439 356.371C560.439 403.654 598.783 441.985 646.083 441.985Z"
                fill="#002B51"
              />
              <path
                d="M646.076 301.636C656.903 301.636 667.488 304.845 676.491 310.857C685.495 316.869 692.513 325.415 696.659 335.414C700.805 345.412 701.893 356.415 699.785 367.032C697.676 377.648 692.467 387.402 684.815 395.059C677.163 402.717 667.411 407.934 656.793 410.053C646.175 412.171 635.167 411.095 625.16 406.961C615.154 402.827 606.598 395.82 600.575 386.825C594.551 377.831 591.33 367.254 591.319 356.43C591.334 341.917 597.108 328.003 607.373 317.741C617.639 307.479 631.558 301.707 646.076 301.692V301.636ZM646.076 293.137C633.553 293.137 621.312 296.849 610.9 303.804C600.487 310.758 592.372 320.643 587.58 332.209C582.788 343.774 581.534 356.5 583.977 368.778C586.42 381.056 592.45 392.334 601.305 401.185C610.16 410.037 621.442 416.065 633.724 418.507C646.006 420.95 658.736 419.696 670.306 414.906C681.875 410.115 691.764 402.003 698.721 391.594C705.678 381.186 709.392 368.948 709.392 356.43C709.392 348.118 707.754 339.888 704.572 332.209C701.39 324.53 696.726 317.552 690.847 311.675C684.967 305.798 677.988 301.135 670.306 297.955C662.624 294.774 654.391 293.137 646.076 293.137Z"
                fill="#D3D8DA"
              />
              <path
                d="M665.216 380.314C680.238 380.314 692.415 368.141 692.415 353.124C692.415 338.107 680.238 325.934 665.216 325.934C650.194 325.934 638.016 338.107 638.016 353.124C638.016 368.141 650.194 380.314 665.216 380.314Z"
                fill="#D3D8DA"
              />
              <path
                d="M454.171 607.755C461.412 607.755 467.282 601.888 467.282 594.649C467.282 587.411 461.412 581.543 454.171 581.543C446.931 581.543 441.061 587.411 441.061 594.649C441.061 601.888 446.931 607.755 454.171 607.755Z"
                fill="#002B51"
              />
              <path
                d="M557.973 569.177C568.009 569.177 576.144 561.044 576.144 551.012C576.144 540.98 568.009 532.848 557.973 532.848C547.938 532.848 539.803 540.98 539.803 551.012C539.803 561.044 547.938 569.177 557.973 569.177Z"
                fill="#002B51"
              />
              <path
                d="M834.339 607.755C841.579 607.755 847.449 601.888 847.449 594.649C847.449 587.411 841.579 581.543 834.339 581.543C827.098 581.543 821.228 587.411 821.228 594.649C821.228 601.888 827.098 607.755 834.339 607.755Z"
                fill="#002B51"
              />
              <path
                d="M888.272 522.252C895.513 522.252 901.383 516.384 901.383 509.145C901.383 501.907 895.513 496.039 888.272 496.039C881.031 496.039 875.161 501.907 875.161 509.145C875.161 516.384 881.031 522.252 888.272 522.252Z"
                fill="#002B51"
              />
              <path
                d="M729.387 569.177C739.423 569.177 747.558 561.044 747.558 551.012C747.558 540.98 739.423 532.848 729.387 532.848C719.352 532.848 711.217 540.98 711.217 551.012C711.217 561.044 719.352 569.177 729.387 569.177Z"
                fill="#002B51"
              />
              <path
                d="M646.083 270.758C598.783 270.758 560.439 309.088 560.439 356.371H731.726C731.726 309.088 693.382 270.758 646.083 270.758Z"
                fill="#002B51"
              />
              <path
                d="M850.793 91.8776C849.685 90.5479 850.06 82.7075 851.897 82.4625C852.551 82.3754 853.111 82.1935 853.795 82.1935C854.261 82.1935 855.479 82.2214 855.833 82.5049C856.752 83.24 856.4 78.2847 856.4 77.7761C856.4 75.3346 856.25 73.0529 857.419 70.8386C859.349 67.181 863.953 65.5505 867.67 64.8072C869.532 64.4346 871.504 64.4492 873.276 65.1187C874.31 65.5091 875.237 65.0759 875.768 66.1381"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M859.966 87.8011C860.211 84.862 862.468 81.2619 864.61 79.3345C865.399 78.6243 866.563 78.2305 867.456 77.6356C868.054 77.2368 868.601 76.8571 869.31 76.7153C871.369 76.3035 873.669 76.5879 875.766 76.5879"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M790.737 184.331C791.468 180.311 795.302 176.897 797.783 173.954C801.655 169.361 805.283 167.155 810.531 164.217C812.335 163.206 814.735 162.616 816.744 162.167C819.481 161.555 822.779 160.117 825.584 160.117C826.889 160.117 828.32 158.857 829.684 158.516C831.603 158.036 834.101 158.304 835.706 157.234"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M772.283 187.788C771.695 183.967 770.629 181.181 771.707 177.41C772.262 175.467 774.998 172.577 777.024 174.399C778.205 175.462 779.344 177.839 780.611 178.563C781.685 179.177 780.874 180.417 782.116 178.243C783.068 176.577 783.318 174.068 784.679 172.798C786.623 170.983 787.194 167.766 789.131 165.751C791.346 163.447 794.224 161.644 796.209 159.218C799.915 154.688 805.959 153.886 811.487 152.043"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M450.276 258.549C450.621 255.102 456.647 250.828 459.472 249.49C463.534 247.566 467.897 246.215 472.345 245.199C478.185 243.864 488.52 242.846 493.189 247.514"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M471.116 212.574C481.574 213.039 499.38 219.512 502.994 230.352"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M504.219 131.652C494.917 149.709 494.107 170.567 499.894 189.857C502.513 198.587 508.565 207.751 513.415 215.025"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M334.514 248.581C336.872 245.987 340.214 240.066 344.393 240.902C348.058 241.635 351.278 246.003 354.92 243.922C355.688 243.483 356.226 242.409 356.947 241.808C357.562 241.296 358.441 240.711 358.889 240.039C359.877 238.557 364.668 238.904 366.18 239.866C367.661 240.809 369.664 240.535 371.012 241.808C371.673 242.433 372.877 243.249 373.73 243.533"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M377.615 224.504C377.489 226.52 368.065 223.504 366.355 223.749C363.363 224.176 361.145 228.754 358.287 229.853C357.239 230.256 355.416 229.997 354.318 229.939C352.833 229.861 350.635 227.719 349.055 229.012C347.452 230.323 344.364 231.598 343.446 233.434"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M441.707 125.078C442.618 125.625 443.101 127.457 443.648 128.335C444.423 129.581 445.159 130.77 445.762 132.11C447.051 134.974 448.621 137.87 449.451 140.911C450.258 143.872 449.86 147.233 449.86 150.316"
                stroke="#F5F5F6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M435.498 130.902C435.743 135.322 439.493 139.164 440.243 143.5C440.863 147.077 441.384 150.626 442.099 154.199"
                stroke="#F5F5F6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M435.498 150.316C436.887 148.392 440.221 148.1 442.25 147.038C444.611 145.801 447.628 145.952 449.648 143.931C451.375 142.204 453.411 142.026 455.3 140.609"
                stroke="#F5F5F6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M456.077 132.066C454.389 132.383 452.904 133.284 451.331 133.922C449.779 134.552 448.064 134.72 446.456 135.173C444.126 135.828 441.772 136.742 439.554 137.718C437.595 138.58 433.851 140.892 433.169 142.938"
                stroke="#F5F5F6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M853.222 350.795C853.222 347.624 851.746 343.692 854.341 341.097C855.679 339.759 858.267 338.913 860.019 339.978C862.226 341.317 863.249 343.638 864.247 345.946C864.578 346.711 864.32 347.528 864.786 348.267C865.01 348.624 865.472 348.886 865.532 349.303C865.768 350.96 866.717 346.17 867.397 344.64C868.036 343.202 869.647 342.962 871.106 342.962C873.275 342.962 874.649 344.422 875.955 345.946C878.957 349.448 880.841 354.2 882.317 358.628"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M672.589 145.645C673.548 150.72 676.146 147.533 679.225 148.274C681.801 148.894 683.35 150.48 685.538 151.698"
                stroke="#00D7C6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M659.433 130.338C659.543 128.548 660.261 127.97 662.064 127.97C664.5 127.97 664.806 128.305 666.717 127.159C667.799 126.51 667.71 124.881 668.495 124.419C669.53 123.81 670.953 124.113 672.1 123.827C673.632 123.444 675.039 122.984 676.534 122.445"
                stroke="#00D7C6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M650.225 121.13C650.409 118.619 652.762 115.742 650.882 113.174C649.37 111.11 651.542 107.781 652.856 106.66"
                stroke="#00D7C6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M626.172 115.867C623.891 118.167 626.121 122.158 628.809 123.433C631.078 124.51 630.391 128.51 630.391 130.337"
                stroke="#00D7C6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M602.154 138C604.745 137.562 606.578 135.272 609.08 135.018C610.902 134.833 611.177 136.102 612.368 136.722C614.555 137.86 614.032 137.909 616.495 137.243C617.37 137.006 618.8 136.775 619.154 136.296"
                stroke="#00D7C6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M614.708 154.016C614.708 156.845 613.286 157.383 610.334 157.962C608.939 158.236 607.619 158.547 607.111 159.831C606.676 160.93 605.499 160.574 605.499 161.908"
                stroke="#00D7C6"
                stroke-width="2.63093"
                stroke-linecap="round"
              />
              <path
                d="M901.477 473.196C901.907 470.828 905.795 467.649 907.397 466.154C909.661 464.04 913.076 462.117 915.738 460.52"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M908.214 475.574C909.271 473.778 912.185 472.322 914.156 471.789C917.238 470.956 920.194 469.608 923.268 468.84"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M912.568 481.516C913.892 479.696 917.922 479.89 919.897 479.734C923.274 479.466 926.657 479.536 930.043 479.536C932.206 479.536 933.514 479.99 935.544 480.328"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M350.986 455.433C351.491 459.233 352.286 461.119 354.127 464.506C354.632 465.435 356.598 467.239 357.539 467.558C358.676 467.942 360.185 468.388 361.386 468.499C362.41 468.594 363.385 468.608 364.361 468.346C365.342 468.084 366.99 467.624 367.797 466.96C368.917 466.038 369.541 463.822 369.547 462.424C369.558 459.983 368.47 457.853 365.75 458.582C363.295 459.24 362.241 462.561 361.928 464.823C361.738 466.198 362.251 467.508 362.602 468.818C363.051 470.494 363.383 471.732 364.944 472.822C368.074 474.511 371.102 475.035 374.147 472.895C375.668 471.826 376.783 470.2 377.959 468.799C379.195 467.327 379.594 465.991 379.807 464.109C380.018 462.238 379.135 460.751 377.088 460.92C375.775 461.027 375.837 462.191 375.555 463.195C375.064 464.945 374.897 466.734 375.375 468.519C375.826 470.201 376.713 471.957 377.908 473.236C379.159 474.573 381.517 475.036 383.239 475.14C385.843 475.296 387.228 474.736 389.027 472.795C390.722 470.967 392.814 469.604 392.96 466.931C393.033 465.592 393.002 464.349 392.656 463.056C392.397 462.092 392.348 461.942 391.451 461.594C390.624 461.272 389.222 461.38 388.438 461.825C387.352 462.443 387.663 465.543 387.631 466.584C387.516 470.241 388.259 473.82 391.559 475.806C395.227 478.013 400.038 476.708 403.892 475.675"
                stroke="#00D7C6"
                stroke-width="3.1377"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
