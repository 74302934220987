import {useTheme} from "styled-components";
import TextField from "../../../components/ds/Textfield";
import Button from "../../../components/ds/Button";
import React, {useRef, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import AuthService from "../../../services/auth.service";
import Snackbar, {ToastTypes} from "../../../components/ds/Snackbar";
import CheckO from "../../../assets/icons/Check-o";
import CloseB from "../../../assets/icons/Close-b";
import CheckB from "../../../assets/icons/Check-b";
import { Formik } from "formik";
import SecuritySvg from "../../../assets/svg-files/security.svg";


export default function ForgetPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const success50Main = useTheme().colors.success50Main;
    const error50Main = useTheme().colors.error50Main;
    const mode = useTheme().colors.mode

    const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
        text: '',
        icon: '',
        btnText: '',
        show: false,
    })

    const inputRef = useRef(null);

    return (
        <div
            className={'flex xl:flex-row flex-col overflow-auto h-screen ' + `bg-[${useTheme().colors.surface50Background}]`}>
            <div
                className={'xl:w-4/12 w-full h-auto mb-[30px] items-center xl:p-28 pt-16 px-28 relative ' + `xl:bg-[${useTheme().colors.surface60}]`}>

                <img src={SecuritySvg} alt=""/>

                <div
                    className={'font-normal text-base hidden xl:block absolute text-center right-0 left-0 bottom-8 ' + `text-[${useTheme().colors.text30}]`}>
                    ©2022 <span className={`text-[${useTheme().colors.text50}]`}>Finomate</span>
                </div>
            </div>
            <div className={'xl:w-8/12 w-full h-full  2xl:px-80 xl:px-56 2xl:pt-40 xl:pt-6 p-4'}>

                <div className={'font-normal xl:text-4xl text-3xl mb-2 ' + `text-[${useTheme().colors.text90}]`}>
                    Forget Password
                </div>

                <div className={'font-normal xl:text-base text-xs mb-8 ' + `text-[${useTheme().colors.text50}]`}>
                    Enter your email to receive the password recovery link.
                </div>

                <Formik
                    initialValues={{email: ''}}
                    validate={values => {
                        const errors: any = {};

                        if (!values.email) {
                            errors.email = 'Required';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = 'Invalid email address';
                        }
                        return errors;
                    }}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        setTimeout(() => {
                            // alert(JSON.stringify(values, null, 2));
                            const params = new URLSearchParams()
                            params.append('email', values.email)

                            AuthService.forgetPassword(params).then((response: any) => {
                                setSnackbarConfig({
                                    text: response.data.message,
                                    icon: <CheckB fill={success50Main}/>,
                                    btnText: 'Ok',
                                    type: ToastTypes.Success,
                                    onClick: () => setSnackbarConfig({
                                        show: false,
                                    }),
                                    show: true,
                                })
                            }).catch((err) => {
                                setSnackbarConfig({
                                    text: err.response.data.message,
                                    icon: <CloseB fill={error50Main}/>,
                                    btnText: 'Ok',
                                    type: ToastTypes.Error,
                                    onClick: () => setSnackbarConfig({
                                        show: false,
                                    }),
                                    show: true,
                                })
                                setTimeout(() => {
                                    if (err.response.status === 401) {
                                        navigate('/')
                                    }
                                    if (err.response.status === 403) {
                                        if (err?.response?.data?.message === 'Your email address is not verified.') {
                                            navigate('/settings/profile')
                                        } else {
                                            navigate('/plans')
                                        }
                                    }
                                    if (err.response.status === 404) {
                                        navigate('*')
                                    }
                                }, 3000);
                            })
                            resetForm()
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({
                          values,
                          isValid,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <TextField size={'Big'} name={'email'}
                                       onChange={handleChange} onBlur={handleBlur}
                                       value={values.email} label={'Email'}
                                       parentClassName={'mb-8'}/>

                            <Button buttonStyleType={'BPrimary'} text={'Send link'}  className={'w-full h-12 mb-8'}/>

                        </form>
                    )}
                </Formik>

                <NavLink to={'/login'}
                         className={'font-normal xl:text-base text-xs block m-auto text-center ' + `text-[${useTheme().colors.primary50Main}]`}>
                    Back to login
                </NavLink>
            </div>

            {snackbarConfig.show ? <Snackbar className={'fixed bottom-[24px] left-0 right-0 m-auto'}
                                             toastType={snackbarConfig.type}
                                             childMode={mode}
                                             btnText={snackbarConfig.btnText}
                                             icon={snackbarConfig.icon}
                                             onClick={snackbarConfig.onClick}
                                             text={snackbarConfig.text}/> : ''}
        </div>
    )
}
