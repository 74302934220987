import {ActionBtn, Inline, Toast} from "./styles";

export enum ToastTypes {
    Success = "Success",
    Error = "Error",
}

export interface Icon_Options {
    icon?: any;
    text?: any;
    btnText?: any;
    toastType?: ToastTypes | any; //Success | Error
    onClick?: any;
    className?: any;
    classNameChild?: any;
    childMode?: any;
}

export default function Snackbar(props: Icon_Options) {
    return (
        <Toast styleType={props.toastType}
               childMode={props.childMode}
               className={props.className}>
            <Inline>
                {props.icon}
                {props.text}
            </Inline>

            <ActionBtn className={props.classNameChild} childMode={props.childMode} onClick={props.onClick}>
                {props.btnText}
            </ActionBtn>
        </Toast>
    )
}