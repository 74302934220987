import { useTheme } from "styled-components";
import Button from "../../components/ds/Button";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import { NavLink } from "react-router-dom";

export default function SessionExpiredPage() {
  return (
    <div className={`bg-[${useTheme().colors.surface50Background}]`}>
      <div
        className={
          "container mx-auto h-screen flex xl:block items-center justify-center"
        }
      >
        <div className={"xl:flex grid xl:flex-row items-center xl:h-full"}>
          <div className={"xl:w-2/6 row-start-2"}>
            <div
              className={
                "font-semibold xl:text-[28px] text-2xl mb-4 text-center " +
                `text-[${useTheme().colors.text90}]`
              }
            >
              Your session has expired :(
            </div>

            <div
              className={
                "font-medium xl:text-lg text-sm mb-6 text-center " +
                `text-[${useTheme().colors.text60}]`
              }
            >
              Please refresh the page.
            </div>

            <NavLink to={"/"}>
              <Button
                text={"Refresh"}
                className={"block mx-auto items-center h-14"}
                buttonStyleType={"BPrimary"}
              />
            </NavLink>
          </div>
          <div className={"xl:w-4/6 row-start-1"}>
            <svg
              width="1186"
              height="748"
              viewBox="0 0 1186 748"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ height: "100%", width: "100%" }}
            >
              <path
                d="M463.837 241.237V213.533L546.193 95.6649H587.246V210.51H609.409V241.237H587.246V277H551.986V241.237H463.837ZM554.253 133.443L502.623 210.51H554.253V133.443ZM622.539 125.887V93.3982H683.236V277H646.969V125.887H622.539ZM753.832 225.37C755.175 232.758 758.197 238.466 762.898 242.496C767.768 246.358 774.232 248.289 782.291 248.289C792.701 248.289 800.257 244.007 804.958 235.444C809.659 226.713 812.01 212.106 812.01 191.621C808.148 196.994 802.691 201.192 795.639 204.214C788.755 207.236 781.284 208.747 773.224 208.747C762.479 208.747 752.74 206.565 744.009 202.199C735.446 197.666 728.646 191.034 723.609 182.303C718.572 173.404 716.053 162.658 716.053 150.065C716.053 131.428 721.594 116.653 732.676 105.739C743.757 94.6575 758.869 89.1167 778.01 89.1167C801.852 89.1167 818.642 96.7563 828.38 112.035C838.287 127.315 843.24 150.317 843.24 181.044C843.24 202.871 841.309 220.753 837.447 234.688C833.753 248.624 827.289 259.202 818.054 266.422C808.988 273.642 796.563 277.252 780.78 277.252C768.355 277.252 757.777 274.901 749.046 270.2C740.315 265.331 733.599 259.034 728.898 251.311C724.365 243.419 721.762 234.772 721.091 225.37H753.832ZM780.528 180.036C789.259 180.036 796.143 177.35 801.18 171.977C806.217 166.604 808.736 159.384 808.736 150.317C808.736 140.411 806.049 132.771 800.676 127.399C795.471 121.858 788.336 119.087 779.269 119.087C770.202 119.087 762.982 121.942 757.609 127.65C752.404 133.191 749.802 140.579 749.802 149.814C749.802 158.712 752.32 166.016 757.358 171.725C762.563 177.266 770.286 180.036 780.528 180.036Z"
                fill="#EAEAEC"
              />
              <path
                d="M53.7518 359.8L43.9167 396.506C43.5807 397.76 42.262 398.521 41.0079 398.185C39.7538 397.849 38.9925 396.53 39.3286 395.276L49.1637 358.571C49.4997 357.317 50.8184 356.556 52.0725 356.892C53.3266 357.228 54.0879 358.546 53.7518 359.8Z"
                fill="#F5F5F6"
              />
              <path
                d="M67.1871 383.071C66.851 384.325 65.5324 385.087 64.2783 384.751L27.5731 374.915C26.319 374.579 25.5577 373.261 25.8937 372.007C26.2298 370.753 27.5484 369.991 28.8025 370.327L65.5077 380.162C66.7618 380.498 67.5231 381.817 67.1871 383.071Z"
                fill="#F5F5F6"
              />
              <path
                d="M1057.55 412.497C1049.42 416.78 1046.29 426.889 1050.58 435.013C1054.86 443.136 1064.97 446.266 1073.09 441.984C1081.22 437.701 1084.35 427.591 1080.06 419.468C1075.78 411.344 1065.67 408.214 1057.55 412.497ZM1074.51 429.123C1074.49 429.585 1074.22 429.971 1073.84 430.173C1073.65 430.274 1073.43 430.331 1073.19 430.307L1067.18 429.989C1065.69 429.909 1064.06 428.863 1063.37 427.551L1060.19 421.506C1059.87 420.901 1060.1 420.136 1060.71 419.817C1061.31 419.499 1062.08 419.736 1062.4 420.34L1065.58 426.385C1065.86 426.916 1066.72 427.464 1067.32 427.488L1073.32 427.806C1074.02 427.836 1074.55 428.421 1074.51 429.123Z"
                fill="#F5F5F6"
              />
              <path
                d="M1161.18 138.611L1160.56 137.99L1166.04 132.51C1166.79 131.761 1166.79 130.519 1166.04 129.77C1165.29 129.021 1164.05 129.021 1163.3 129.77L1157.82 135.25L1151.43 128.856L1156.91 123.376C1157.66 122.627 1157.66 121.385 1156.91 120.636C1156.16 119.887 1154.92 119.887 1154.17 120.636L1148.69 126.116L1148.07 125.495C1146.15 123.577 1143.01 123.577 1141.09 125.495L1131.79 134.793C1127.77 138.812 1127.23 144.84 1131.79 149.407L1133.16 150.777L1122.2 161.737C1121.45 162.486 1121.45 163.728 1122.2 164.477C1122.95 165.226 1124.19 165.226 1124.94 164.477L1135.9 153.517L1137.27 154.887C1141.84 159.454 1147.87 158.906 1151.89 154.887L1161.18 145.589C1163.1 143.671 1163.1 140.529 1161.18 138.611Z"
                fill="#F5F5F6"
              />
              <path
                d="M229.787 684.491L229.091 685.027L224.361 678.887C223.715 678.048 222.483 677.889 221.644 678.535C220.805 679.182 220.645 680.413 221.291 681.252L226.021 687.392L218.859 692.91L214.129 686.771C213.483 685.932 212.251 685.772 211.412 686.418C210.573 687.065 210.413 688.297 211.059 689.136L215.789 695.275L215.094 695.811C212.945 697.467 212.541 700.582 214.196 702.731L222.222 713.147C225.69 717.649 231.598 718.968 236.714 715.026L238.249 713.844L247.709 726.122C248.355 726.961 249.587 727.121 250.426 726.474C251.265 725.828 251.425 724.596 250.778 723.757L241.318 711.479L242.853 710.296C247.969 706.355 248.201 700.306 244.732 695.804L236.707 685.388C235.051 683.239 231.935 682.835 229.787 684.491Z"
                fill="#F5F5F6"
              />
              <circle cx="650" cy="461" r="168" fill="#F5FDFF" />
              <path
                d="M201.239 486.161C204.247 490.35 206.385 492.083 210.717 494.935C211.904 495.717 215.38 496.612 216.699 496.393C218.291 496.129 220.364 495.701 221.859 495.072C223.134 494.535 224.3 493.934 225.292 493.006C226.289 492.072 227.953 490.482 228.49 489.183C229.235 487.378 228.57 484.355 227.692 482.693C226.158 479.789 223.517 477.952 220.752 480.54C218.256 482.877 219.11 487.485 220.173 490.367C220.819 492.119 222.257 493.348 223.504 494.679C225.099 496.383 226.278 497.643 228.821 497.945C233.605 497.967 237.529 496.668 239.786 492.199C240.913 489.968 241.205 487.331 241.713 484.924C242.246 482.395 241.873 480.556 240.932 478.188C239.997 475.835 238.008 474.631 235.685 476.128C234.195 477.088 235.007 478.43 235.309 479.799C235.836 482.187 236.771 484.415 238.47 486.23C240.07 487.94 242.236 489.461 244.464 490.22C246.796 491.015 249.887 490.07 251.995 489.101C255.184 487.636 256.473 486.093 257.377 482.651C258.229 479.408 259.848 476.464 258.327 473.201C257.565 471.566 256.74 470.111 255.51 468.796C254.593 467.816 254.438 467.67 253.153 467.825C251.969 467.967 250.373 468.984 249.726 470.009C248.829 471.43 251.162 474.911 251.784 476.167C253.966 480.578 257.115 484.353 262.29 484.618C268.041 484.913 272.921 480.315 276.839 476.647"
                stroke="#00D7C6"
                stroke-width="3.1377"
                stroke-linecap="round"
              />
              <path
                d="M999.001 598.913C1002.5 598.677 1009.01 596.725 1011.44 600.224C1013.58 603.292 1012.87 608.672 1016.94 609.696C1017.8 609.912 1018.93 609.511 1019.87 609.577C1020.67 609.634 1021.7 609.821 1022.49 609.647C1024.23 609.264 1027.45 612.834 1027.87 614.576C1028.28 616.281 1029.92 617.472 1030.01 619.324C1030.05 620.233 1030.35 621.656 1030.77 622.451"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M1046.76 611.425C1045.27 612.792 1040.57 604.086 1039.16 603.077C1036.71 601.312 1031.94 603.076 1029.12 601.887C1028.08 601.452 1026.95 600.002 1026.2 599.199C1025.18 598.113 1025.08 595.046 1023.04 594.883C1020.98 594.717 1017.87 593.497 1015.94 594.184"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M289.418 210.299C289.418 207.128 287.942 203.196 290.537 200.601C291.875 199.263 294.463 198.417 296.215 199.482C298.422 200.821 299.445 203.141 300.443 205.45C300.774 206.215 300.516 207.032 300.982 207.771C301.206 208.128 301.668 208.39 301.728 208.807C301.964 210.464 302.913 205.674 303.593 204.144C304.232 202.706 305.843 202.466 307.302 202.466C309.471 202.466 310.845 203.926 312.151 205.45C315.153 208.952 317.037 213.704 318.513 218.132"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M949.622 199.315C948.514 197.985 948.889 190.145 950.726 189.9C951.38 189.813 951.94 189.631 952.624 189.631C953.09 189.631 954.308 189.659 954.662 189.942C955.581 190.677 955.229 185.722 955.229 185.214C955.229 182.772 955.079 180.49 956.248 178.276C958.178 174.619 962.782 172.988 966.499 172.245C968.361 171.872 970.333 171.887 972.105 172.556C973.139 172.947 974.066 172.513 974.597 173.576"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M958.795 195.239C959.04 192.299 961.297 188.699 963.439 186.772C964.228 186.062 965.392 185.668 966.285 185.073C966.883 184.674 967.43 184.295 968.139 184.153C970.198 183.741 972.498 184.025 974.595 184.025"
                stroke="#00D7C6"
                stroke-width="3.94639"
                stroke-linecap="round"
              />
              <path
                d="M458.703 52.4911L442.06 39.9061C439.822 38.2121 437.555 37.7431 435.658 38.5818C433.761 39.4205 432.582 41.4132 432.329 44.2084L430.436 64.9882C430.204 67.6355 430.977 69.735 432.628 70.9263C434.28 72.1175 436.525 72.1814 438.957 71.1058L457.493 62.911C459.926 61.8355 461.389 60.1326 461.62 58.1091C461.851 56.0855 460.811 54.0868 458.703 52.4911ZM439.053 49.1988C438.791 48.6051 439.065 47.895 439.659 47.6325C440.253 47.3701 440.963 47.6447 441.225 48.2385L444.427 55.4791C444.689 56.0728 444.414 56.7829 443.821 57.0453C443.227 57.3078 442.517 57.0332 442.254 56.4394L439.053 49.1988ZM446.744 60.8772C446.697 60.9672 446.65 61.0571 446.603 61.147C446.542 61.2434 446.475 61.3252 446.4 61.3926C446.333 61.4745 446.259 61.5418 446.164 61.6011C446.083 61.654 445.988 61.7133 445.901 61.7518C445.814 61.7902 445.707 61.8205 445.599 61.8508C445.506 61.8748 445.406 61.8842 445.3 61.8792C445.2 61.8886 445.094 61.8836 444.982 61.8641C444.884 61.8382 444.786 61.8123 444.688 61.7864C444.305 61.6264 443.979 61.3204 443.813 60.9438C443.647 60.5673 443.639 60.1204 443.778 59.73C443.825 59.6401 443.872 59.5501 443.919 59.4602C443.98 59.3639 444.048 59.282 444.122 59.2146C444.19 59.1328 444.264 59.0654 444.344 59.0125C444.513 58.8858 444.716 58.7962 444.909 58.7628C445.017 58.7325 445.116 58.723 445.222 58.7281C445.322 58.7186 445.428 58.7236 445.541 58.7432C445.639 58.7691 445.737 58.795 445.835 58.8209C446.217 58.9808 446.543 59.2869 446.709 59.6634C446.876 60.0399 446.883 60.4869 446.744 60.8772Z"
                fill="#F5F5F6"
              />
              <rect
                x="501"
                y="343"
                width="307"
                height="227.18"
                rx="24.56"
                fill="#E5EAEC"
              />
              <path
                d="M501 367.56C501 353.996 511.996 343 525.56 343H783.44C797.004 343 808 353.996 808 367.56V387.515H501V367.56Z"
                fill="#002B51"
              />
              <circle cx="533.096" cy="365.883" r="6" fill="#F6F7F7" />
              <circle cx="551.236" cy="365.883" r="6" fill="#F6F7F7" />
              <circle cx="569.376" cy="365.883" r="6" fill="#F6F7F7" />
              <circle cx="617.501" cy="445.637" r="9" fill="#002B51" />
              <circle cx="691.501" cy="445.637" r="9" fill="#002B51" />
              <path
                d="M633.779 501.262C642.222 488.214 666.014 488.215 675.224 501.262"
                stroke="#002B51"
                stroke-width="5"
                stroke-linecap="round"
              />
              <path
                d="M512.6 354.305L498.287 339.122C496.362 337.079 494.205 336.239 492.195 336.749C490.184 337.26 488.69 339.029 487.974 341.743L482.645 361.916C481.975 364.488 482.387 366.687 483.817 368.137C485.248 369.587 487.45 370.024 490.028 369.368L509.67 364.377C512.248 363.722 513.975 362.287 514.539 360.33C515.104 358.373 514.412 356.229 512.6 354.305ZM493.773 347.784C493.613 347.155 494.003 346.5 494.632 346.34C495.261 346.181 495.915 346.57 496.075 347.199L498.025 354.872C498.185 355.501 497.795 356.155 497.166 356.315C496.537 356.475 495.883 356.086 495.723 355.457L493.773 347.784ZM499.41 360.581C499.349 360.661 499.288 360.742 499.227 360.823C499.15 360.908 499.07 360.977 498.986 361.031C498.905 361.101 498.821 361.155 498.717 361.198C498.629 361.236 498.526 361.279 498.434 361.302C498.342 361.326 498.23 361.338 498.119 361.35C498.023 361.358 497.923 361.35 497.819 361.328C497.719 361.321 497.616 361.298 497.508 361.26C497.416 361.218 497.323 361.176 497.231 361.134C496.881 360.913 496.611 360.557 496.509 360.158C496.408 359.759 496.475 359.317 496.677 358.955C496.738 358.874 496.8 358.793 496.861 358.713C496.937 358.628 497.018 358.558 497.102 358.504C497.182 358.435 497.266 358.381 497.355 358.342C497.542 358.245 497.757 358.191 497.953 358.19C498.064 358.178 498.164 358.185 498.268 358.208C498.368 358.215 498.472 358.238 498.579 358.276C498.672 358.318 498.764 358.36 498.856 358.401C499.207 358.623 499.477 358.979 499.578 359.378C499.68 359.777 499.612 360.219 499.41 360.581Z"
                fill="#F96680"
              />
              <path
                d="M791.905 575.273L786 566.092L780.765 564.69L771.061 569.688C768.35 571.074 766.822 573.915 767.15 576.943C767.482 579.952 769.6 582.403 772.517 583.185L783.896 586.234C786.832 587.021 789.872 585.951 791.665 583.512C793.462 581.053 793.56 577.829 791.905 575.273ZM783.73 578.197L776.698 576.313C775.964 576.116 775.545 575.362 775.736 574.647C775.928 573.933 776.687 573.494 777.402 573.686L784.434 575.57C785.168 575.767 785.587 576.521 785.396 577.236C785.204 577.95 784.445 578.389 783.73 578.197Z"
                fill="#F96680"
              />
              <path
                d="M799.635 553.843C799.302 550.834 797.185 548.382 794.268 547.601L782.87 544.547C779.953 543.765 776.893 544.829 775.101 547.269C773.322 549.732 773.224 552.957 774.879 555.512L780.784 564.693L786.02 566.096L795.724 561.098C798.416 559.707 799.943 556.865 799.635 553.843ZM789.377 557.119L782.346 555.235C781.611 555.038 781.192 554.284 781.384 553.569C781.575 552.855 782.335 552.416 783.05 552.608L790.081 554.492C790.816 554.689 791.235 555.443 791.043 556.158C790.852 556.872 790.092 557.311 789.377 557.119Z"
                fill="#F96680"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
