import PageTitles from "../../components/ds/PageTitle";
import { useTheme } from "styled-components";
import React, { useContext, useEffect, useState } from "react";
import DashboardService from "../../services/dashboard.service";
import moment from "moment/moment";
import CloseB from "../../assets/icons/Close-b";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import SearchT from "../../assets/icons/icon-128/Search-t";
import Search64T from "../../assets/icons/icon-64/Search-t";
import SearchResult from "../../components/ds/ResultPage/searchResult";
import { Context } from "../../context";
import { useNavigate } from "react-router-dom";
import useAuth from "../../useAuth";

export default function Inbox() {
  const [result, setResult] = useState<any>([]);
  const [type, setType] = useState<any>("all");
  const text40 = useTheme().colors.text40;
  const text50 = useTheme().colors.text50;
  const text90 = useTheme().colors.text90;
  const text80Body = useTheme().colors.text80Body;
  const surface80Divider = useTheme().colors.surface80Divider;
  const primary50Main = useTheme().colors.primary50Main;
  const error50Main = useTheme().colors.error50Main;
  const mode = useTheme().colors.mode;
  const { hasUnRead, setHasUnRead } = useContext<any>(Context);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { token } = useAuth();

  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    onClick: "",
    show: false,
  });
  useEffect(() => {
    GetInbox(type);
  }, [type]);

  function GetInbox(data: any) {
    DashboardService.getInboxes(data, token)
      .then((response: any) => {
        setResult(response.data.data);
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }

  function ReadMessage(data: any) {
    setLoading(true);
    if (!data.read_at) {
      console.log(data, token);

      DashboardService.readInbox(data.id, token)
        .then((response) => {
          if (response) {
            setLoading(false);
            let index = result.findIndex((item: any) => item.id == data.id);
            let newResult = result;
            result[index].read_at = "1";
            setResult(newResult)
            // DashboardService.getInbox(type)
            //   .then((response: any) => {
            //     setResult(response.data.data);
            //     let hasUnRead = false;
            //     for (let item of response.data.data) {
            //       if (!item.read_at) {
            //         hasUnRead = true;
            //         setHasUnRead(true);
            //       }
            //     }
            //     setHasUnRead(hasUnRead);
            //   })
            //   .catch((err) => {
            //     setSnackbarConfig({
            //       text: err.response.data.message,
            //       icon: <CloseB fill={error50Main} />,
            //       btnText: "Ok",
            //       type: ToastTypes.Error,
            //       onClick: () =>
            //         setSnackbarConfig({
            //           show: false,
            //         }),
            //       show: true,
            //     });

            //     setTimeout(() => {
            //       if (err.response.status === 401) {
            //         navigate("/");
            //       }
            //       if (err.response.status === 403) {
            //         if (
            //           err?.response?.data?.message ===
            //           "Your email address is not verified."
            //         ) {
            //           navigate("/settings/profile");
            //         } else {
            //           navigate("/plans");
            //         }
            //       }
            //       if (err.response.status === 404) {
            //         navigate("*");
            //       }
            //     }, 3000);
            //   });
          }
        })
        .catch((err) => {
          setSnackbarConfig({
            text: err.response.data.message,
            icon: <CloseB fill={error50Main} />,
            btnText: "Ok",
            type: ToastTypes.Error,
            onClick: () =>
              setSnackbarConfig({
                show: false,
              }),
            show: true,
          });

          setTimeout(() => {
            if (err.response.status === 401) {
              navigate("/");
            }
            if (err.response.status === 403) {
              if (
                err?.response?.data?.message ===
                "Your email address is not verified."
              ) {
                navigate("/settings/profile");
              } else {
                navigate("/plans");
              }
            }
            if (err.response.status === 404) {
              navigate("*");
            }
          }, 3000);
        });
    }
  }

  return (
    <>
      <div className={"xl:w-1/2"}>
        <div className={"mb-4 xl:mb-6"}>
          <PageTitles text={"Inbox"} />
        </div>

        <div className={"flex gap-1 xl:gap-3"}>
          <div
            className={
              "hover:cursor-pointer border border-solid rounded-3xl px-4 xl:px-5 h-9 xl:h-12 flex items-center justify-center text-xs xl:text-base font-normal " +
              `${type === "all"
                ? ` text-[${primary50Main}]`
                : ` text-[${text40}]`
              } ` +
              `${type === "all"
                ? ` border-[${primary50Main}]`
                : ` border-[${surface80Divider}]`
              }`
            }
            onClick={() => {
              setType("all");
            }}
          >
            All
          </div>
          <div
            className={
              "hover:cursor-pointer border border-solid rounded-3xl px-4 xl:px-5 h-9 xl:h-12 flex items-center justify-center text-xs xl:text-base font-normal " +
              `${type === "1" ? ` text-[${primary50Main}]` : ` text-[${text40}]`
              } ` +
              `${type === "1"
                ? ` border-[${primary50Main}]`
                : ` border-[${surface80Divider}]`
              }`
            }
            onClick={() => {
              setType("1");
            }}
          >
            Activities
          </div>
          <div
            className={
              "hover:cursor-pointer border border-solid rounded-3xl px-4 xl:px-5 h-9 xl:h-12 flex items-center justify-center text-xs xl:text-base font-normal " +
              `${type === "0" ? ` text-[${primary50Main}]` : ` text-[${text40}]`
              } ` +
              `${type === "0"
                ? ` border-[${primary50Main}]`
                : ` border-[${surface80Divider}]`
              }`
            }
            onClick={() => {
              setType("0");
            }}
          >
            Announcement
          </div>
          <div
            className={
              "hover:cursor-pointer border border-solid rounded-3xl px-4 xl:px-5 h-9 xl:h-12 flex items-center justify-center text-xs xl:text-base font-normal " +
              `${type === "2" ? ` text-[${primary50Main}]` : ` text-[${text40}]`
              } ` +
              `${type === "3"
                ? ` border-[${primary50Main}]`
                : ` border-[${surface80Divider}]`
              }`
            }
            onClick={() => {
              setType("2");
            }}
          >
            Updates
          </div>
        </div>

        <div
          className={
            "xl:my-6 my-3 w-full h-px " +
            `bg-[${useTheme().colors.surface80Divider}]`
          }
        ></div>

        {result.length ? (
          <>
            {result?.map((item: any) => {
              return (
                <>
                  <div
                    className={
                      "hover:cursor-pointer flex justify-between " +
                      `${!loading ? `hover:cursor-pointer` : `hover:cursor-wait`
                      }` +
                      ` ${loading ? ` opacity-50` : ""}`
                    }
                    onClick={() => ReadMessage(item)}
                  >
                    <div className={"w-9/12"}>
                      <div className={"flex items-baseline"}>
                        {item.read_at ? (
                          ""
                        ) : (
                          <div
                            className={
                              "w-2 h-2 rounded-full mr-2 " +
                              `bg-[${primary50Main}]`
                            }
                          ></div>
                        )}
                        <div
                          className={
                            "xl:text-lg text-sm font-medium mb-2 " +
                            `text-[${text90}]`
                          }
                        >
                          {item.data.title}
                        </div>
                      </div>
                      <div
                        className={
                          "xl:font-base text-xs font-normal " +
                          `text-[${text80Body}]`
                        }
                        dangerouslySetInnerHTML={{ __html: item.data.text }}
                      />
                    </div>
                    <div
                      className={
                        "xl:text-sm text-[10px] font-medium " +
                        `text-[${text50}]`
                      }
                    >
                      {moment(item.created_at).format("MMM Do YYYY hh:mm")}
                    </div>
                  </div>
                  <div
                    className={
                      "xl:my-6 my-3 w-full h-px " + `bg-[${surface80Divider}]`
                    }
                  ></div>
                </>
              );
            })}
          </>
        ) : (
          <SearchResult title={"No results"} />
        )}
      </div>
      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}
    </>
  );
}
