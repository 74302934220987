import {Btn} from "./styles";

export enum BtnTypes{
    BPrimary= "BPrimary",
    MPrimary = "MPrimary" ,
    MSecondary = "MSecondary",
    MWarning = "MWarning"
}
export interface Btn_Options {
    text?: any;
    type?: any | 'button';
    status?: boolean | false;
    icon?: any;
    buttonStyleType?: BtnTypes | any; //BPrimary | MPrimary | MSecondary | MWarning
    onClick?: any;
    className?: any;
    loading?: any;
}

export default function Button(props: Btn_Options) {
    return (
        <Btn styleType={props.buttonStyleType}
             onClick={props.onClick}
             type={props.type}
             disabled={props.status} className={props.className}>
            {props.icon}
            {props.text}
            {props.loading&&<div className={'loading-icon'}> {props.loading}</div>}
        </Btn>
    )
}