import PageTitles from "../../components/ds/PageTitle";
import { useTheme } from "styled-components";
import Cart from "./cart";
import Button from "../../components/ds/Button";
import BusinessT from "../../assets/icons/Business-t";
import * as animationData from "./animation_arrow.json";
import Lottie from "react-lottie";
import React, { useEffect, useState } from "react";
import DashboardService from "../../services/dashboard.service";
import AccountService from "../../services/account.service";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import CheckB from "../../assets/icons/Check-b";
import CloseB from "../../assets/icons/Close-b";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import TextField from "../../components/ds/Textfield";
import { useNavigate } from "react-router-dom";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import walletMock from "../../assets/images/mock/wallet.png";
import useAuth from "../../useAuth";

export default function Plan() {
  const navigate = useNavigate();
  const [plans, setPlan] = useState([]);
  const [loading, setLoading] = useState(false);
  const success50Main = useTheme().colors.success50Main;
  const error50Main = useTheme().colors.error50Main;
  const text90 = useTheme().colors.text90;
  const text60 = useTheme().colors.text60;
  const text50 = useTheme().colors.text50;
  const mode = useTheme().colors.mode;
  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    show: false,
  });
  const customStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "25%",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  const customMobileStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [discount_code, setDiscount_code] = React.useState("");
  const [plan_id, setPlan_id] = React.useState("");
  const [plan_title, setPlan_title] = React.useState("");
  const [plan_priceSymbol, setPlan_priceSymbol] = React.useState("");
  const [plan_price, setPlan_price] = React.useState("");
  const [plan_duration, setPlan_duration] = React.useState("");
  const { token } = useAuth();

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    DashboardService.getPlans(token)
      .then((response: any) => {
        setPlan(response.data);
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function upgrade(event: any) {
    setIsOpen(true);
    setPlan_id(event.id);
    setPlan_title(event.name);
    setPlan_priceSymbol(event.priceSymbol);
    setPlan_price(event.price);
    setPlan_duration(event.days);
  }
  function renderModal() {
    if (isMobile || isTablet) {
      return (
        <>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customMobileStyles}
            contentLabel="Example Modal"
          >
            <div className={"flex justify-between items-center mb-6"}>
              <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
                Upgrade Plan
              </div>

              <button onClick={closeModal}>
                <CloseO fill={text60} />
              </button>
            </div>

            <div className={"mb-5"}>
              <div className={"text-xl font-semibold " + `text-[${text90}]`}>
                YOU BUY:
              </div>
              <div className={"text-base font-semibold " + `text-[${text90}]`}>
                {plan_title}
              </div>

              <div className={"flex items-center"}>
                <div
                  className={"font-semibold text-normal " + `text-[${text50}]`}
                >
                  $
                </div>
                <div
                  className={"font-semibold text-normal " + `text-[${text50}]`}
                >
                  {plan_priceSymbol}
                </div>
                <div
                  className={"font-semibold text-normal " + `text-[${text90}]`}
                >
                  {plan_price}
                </div>
                <div
                  className={"font-semibold text-normal " + `text-[${text50}]`}
                >
                  /
                </div>
                <div
                  className={"font-semibold text-base " + `text-[${text50}]`}
                >
                  {plan_duration} days
                </div>
              </div>
            </div>
            <TextField
              value={discount_code}
              onChange={(event: any) => setDiscount_code(event.target.value)}
              size={"Big"}
              label={"Enter your discount code"}
            />

            <div className={"flex justify-end mt-6 gap-2"}>
              <Button
                text={"Upgrade"}
                onClick={() => {
                  setLoading(true);
                  let params = new URLSearchParams();
                  params.append("plan_id", plan_id);
                  if (discount_code) {
                    params.append("discount_code", discount_code);
                  }
                  AccountService.upgradePlan(params, token)
                    .then((response) => {
                      setLoading(false);
                      setSnackbarConfig({
                        text: response.data.message,
                        icon: <CheckB fill={success50Main} />,
                        btnText: "Ok",
                        type: ToastTypes.Success,
                        onClick: () =>
                          setSnackbarConfig({
                            show: false,
                          }),
                        show: true,
                      });
                      setIsOpen(false);
                      navigate("/");
                      window.location.reload();
                    })
                    .catch((err) => {
                      setLoading(false);
                      setSnackbarConfig({
                        text: err.response.data.message,
                        icon: <CloseB fill={error50Main} />,
                        btnText: "Ok",
                        type: ToastTypes.Error,
                        onClick: () =>
                          setSnackbarConfig({
                            show: false,
                          }),
                        show: true,
                      });
                      setIsOpen(false);
                    });
                }}
                buttonStyleType={"BPrimary"}
                status={loading}
                className={"font-medium  h-12"}
              />
            </div>
          </Modal>
        </>
      );
    } else if (isDesktop) {
      return (
        <>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className={"flex justify-between items-center mb-6"}>
              <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
                Upgrade Plan
              </div>

              <button onClick={closeModal}>
                <CloseO fill={text60} />
              </button>
            </div>

            <div className={"mb-5"}>
              <div className={"text-xl font-semibold " + `text-[${text90}]`}>
                YOU BUY:
              </div>
              <div className={"text-base font-semibold " + `text-[${text90}]`}>
                {plan_title}
              </div>

              <div className={"flex items-center"}>
                <div
                  className={"font-semibold text-normal " + `text-[${text50}]`}
                >
                  $
                </div>
                <div
                  className={"font-semibold text-normal " + `text-[${text50}]`}
                >
                  {plan_priceSymbol}
                </div>
                <div
                  className={"font-semibold text-normal " + `text-[${text90}]`}
                >
                  {plan_price}
                </div>
                <div
                  className={"font-semibold text-normal " + `text-[${text50}]`}
                >
                  /
                </div>
                <div
                  className={"font-semibold text-base " + `text-[${text50}]`}
                >
                  {plan_duration} days
                </div>
              </div>
            </div>
            <TextField
              value={discount_code}
              onChange={(event: any) => setDiscount_code(event.target.value)}
              size={"Big"}
              label={"Enter your discount code"}
            />

            <div className={"flex justify-end mt-6 gap-2"}>
              <Button
                text={"Upgrade"}
                onClick={() => {
                  setLoading(true);
                  let params = new URLSearchParams();
                  params.append("plan_id", plan_id);
                  if (discount_code) {
                    params.append("discount_code", discount_code);
                  }
                  AccountService.upgradePlan(params, token)
                    .then((response) => {
                      setLoading(false);
                      setSnackbarConfig({
                        text: response.data.message,
                        icon: <CheckB fill={success50Main} />,
                        btnText: "Ok",
                        type: ToastTypes.Success,
                        onClick: () =>
                          setSnackbarConfig({
                            show: false,
                          }),
                        show: true,
                      });
                      setIsOpen(false);
                      navigate("/");
                      window.location.reload();
                    })
                    .catch((err) => {
                      setLoading(false);
                      setSnackbarConfig({
                        text: err.response.data.message,
                        icon: <CloseB fill={error50Main} />,
                        btnText: "Ok",
                        type: ToastTypes.Error,
                        onClick: () =>
                          setSnackbarConfig({
                            show: false,
                          }),
                        show: true,
                      });
                      setIsOpen(false);
                    });
                }}
                buttonStyleType={"BPrimary"}
                status={loading}
                className={"font-medium  h-12"}
              />
            </div>
          </Modal>
        </>
      );
    }
  }

  return (
    <div className={"pb-16"}>
      <PageTitles text={"Go Premium"} />

      <div
        className={
          "my-6 w-full h-px " + `bg-[${useTheme().colors.surface80Divider}]`
        }
      ></div>

      <div
        className={"text-3xl font-semibold text-center pb-3 gradient-title "}
      >
        Pick your premium
      </div>

      <div
        className={
          "font-normal text-base text-center mb-8 " +
          `text-[${useTheme().colors.text80Body}]`
        }
      >
        Get Finomate’s unlimited assistance and get the most profit in crypto
        market
      </div>

      <div className={"lg:flex gap-6"}>
        {plans.map((item: any) => {
          return (
            <div className={"lg:w-1/4 mb-3 lg:mb-0 "} key={item?.id}>
              <Cart
                title={item?.name}
                priceSymbol={item?.priceSymbol}
                planIcon={item?.planIcon}
                price={item?.price}
                duration={item?.days}
                features={item?.features}
                onClick={(e: any) => upgrade(item)}
                actionTitle={item?.actionBtn}
              />
            </div>
          );
        })}

        <div className={"lg:w-1/4"}>
          <div className={"border border-solid p-6 rounded-[40px]"}>
            <div className={"flex items-center"}>
              <div className={"mr-4"}>
                <BusinessT />
              </div>
              <div
                className={
                  "text-xl 2xl:text-3xl font-semibold " +
                  `text-[${useTheme().colors.text90}]`
                }
              >
                Buisiness
              </div>
            </div>

            <div className={"flex items-center"}>
              <div
                className={
                  "font-normal text-xs 2xl:text-base " +
                  `text-[${useTheme().colors.text80Body}]`
                }
              >
                For all of the organizations that need additional features,
                controls and support.
              </div>
            </div>

            <div
              className={
                "my-6 w-full h-px " +
                `bg-[${useTheme().colors.surface80Divider}]`
              }
            ></div>

            <div>
              <div
                className={
                  "text-[28px] font-semibold mb-7 text-center  " +
                  `text-[${useTheme().colors.text90}]`
                }
              >
                Let’s talk!
              </div>

              <Lottie options={defaultOptions} height={192} width={99} />
            </div>
            <Button
              text={"Contact Us"}
              buttonStyleType={"BPrimary"}
              className={"w-full h-12 mt-1 2xl:mt-[100px]"}
            />
          </div>
        </div>
      </div>

      {renderModal()}
      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}
    </div>
  );
}
