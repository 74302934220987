import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
import AccountService from "../../../services/account.service";
import Snackbar, { ToastTypes } from "../../../components/ds/Snackbar";
import CloseB from "../../../assets/icons/Close-b";
import { useTheme } from "styled-components";
import useAuth from "../../../useAuth";

export default function GoogleAuth() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const error50Main = useTheme().colors.error50Main;
  const mode = useTheme().colors.mode;
  const { token } = useAuth();

  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    onClick: "",
    show: false,
  });

  useEffect(() => {
    if (searchParams.get("token")) {
      localStorage.setItem("token", searchParams.get("token") || "");
      navigate("/");
      window.location.reload();
      AccountService.profile(token)
        .then((response: any) => {})
        .catch((err) => {
          setSnackbarConfig({
            text: err.response.data.message,
            icon: <CloseB fill={error50Main} />,
            btnText: "Ok",
            type: ToastTypes.Error,
            onClick: () =>
              setSnackbarConfig({
                show: false,
              }),
            show: true,
          });
          setTimeout(() => {
            if (err.response.status === 401) {
              navigate("/");
            }
            if (err.response.status === 404) {
              navigate("*");
            }
          }, 3000);
        });
    }
  }, []);

  return (
    <div>
      Google Auth
      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}
    </div>
  );
}
