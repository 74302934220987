import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppThemeProvider } from "./theme";
import { ContextProvider } from "./context";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./useAuth";
import WorkerProvider from "./workerProvider"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLDivElement
);

root.render(
    <WorkerProvider>
        <ContextProvider>
            <React.StrictMode>
                <AppThemeProvider>
                    <Router>
                        <AuthProvider>
                            <App />
                        </AuthProvider>
                    </Router>
                </AppThemeProvider>
            </React.StrictMode>
        </ContextProvider>
    </WorkerProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
