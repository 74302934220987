import PageTitles from "../../components/ds/PageTitle";
import QuestionO from "../../assets/icons/Question-o";
import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import TextField from "../../components/ds/Textfield";
import SearchO from "../../assets/icons/Search-o";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../components/ds/Button";
import AddOutline from "../../assets/icons/Add-outline";
import DataList from "./dataList";
import Empty from "../../components/ds/ResultPage/empty";
import NotificationT from "../../assets/icons/icon-128/Notification-t";
import AlarmService from "../../services/alarm.service";
import SearchResult from "../../components/ds/ResultPage/searchResult";
import SearchT from "../../assets/icons/icon-128/Search-t";
import Search64T from "../../assets/icons/icon-64/Search-t";
import AccountService from "../../services/account.service";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import CloseB from "../../assets/icons/Close-b";
import Pagination from "../../components/ds/Pagination";
import Loading from "./Loading";
import { useDebouncedCallback } from "../../components/ds/Hooks/useDebounce";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import alarmMock from "../../assets/images/mock/alarm.png";
import ListT from "../../assets/icons/icon-128/List-t";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import walletMock from "../../assets/images/mock/wallet.png";
import useAuth from "../../useAuth";

export default function Alarms() {
  const [result, setResult] = useState<any>([]);
  const [profile, setProfile] = useState<any>("");
  const [filterParam, setFilterParam] = useState<any>("");
  const [search, setSearch] = useState<any>("");
  const [page, setPage] = useState<any>(1);

  const [loading, setLoading] = useState(true);
  const [goPlan, setGoPlan] = React.useState(false);

  const text20 = useTheme().colors.text20;
  const text50 = useTheme().colors.text50;
  const text60 = useTheme().colors.text60;
  const text90 = useTheme().colors.text90;
  const text80Body = useTheme().colors.text80Body;
  const primary50Main = useTheme().colors.primary50Main;
  const error50Main = useTheme().colors.error50Main;
  const mode = useTheme().colors.mode;

  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(false);
  const { token } = useAuth();

  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    show: false,
  });

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "50%",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  const customMobileStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function BuyPlan() {
    navigate("/plans");
  }

  useEffect(() => {
    getAlarms(1);
    AccountService.profile(token)
      .then((response: any) => {
        setProfile(response.data.data);
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }, []);
  const makeRequest = useDebouncedCallback((event: string) => {
    getAlarms(1, event);
  }, 1500);

  function getAlarms(pageEnter?: number, param?: string) {
    setFilterParam(param);
    setPage(pageEnter);
    AlarmService.getAll(pageEnter ? pageEnter : page, param, token)
      .then((response: any) => {
        if (
          response.data.message ===
          "The user does not have an active subscription"
        ) {
          setSnackbarConfig({
            text: response.data.message,
            icon: <CloseB fill={error50Main} />,
            btnText: "Upgrade",
            type: ToastTypes.Error,
            onClick: () => BuyPlan(),
            show: true,
          });
        }

        if (page === 1) setResult(response?.data?.data);
        else setResult([...result, ...response?.data?.data]);

        setLoading(false);
        if (response.data.data.length && response.data.total > 15) {
          setHasMore(true);
        } else if (response.data.data.length && response.data.total <= 15) {
          setHasMore(false);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response.data.message ===
          "The user does not have an active subscription"
        ) {
          setGoPlan(true);
        }
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: goPlan
            ? () => BuyPlan()
            : () =>
                setSnackbarConfig({
                  show: false,
                }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }
  // useEffect(() => {
  //     if(filterParam.length>2){
  //         setPage(1)
  //     }
  // }, [filterParam])

  console.log(result);
  
  function renderContent() {
    if (result.length === 0) {
      if (filterParam) {
        //render not found
        return (
          <>
            <div className="flex justify-between pb-6">
              <div className={"basis-full lg:basis-1/4"}>
                <TextField
                  size={"Big"}
                  prefixIcon={<SearchO fill={text50} size={"Big"} />}
                  onChange={(event: any) => {
                    if (event.target.value.length > 2) {
                      makeRequest(event.target.value);
                      setSearch(event.target.value);
                    } else if (event.target.value.length === 0)
                      makeRequest(event.target.value);
                    setSearch(event.target.value);
                  }}
                  placeholder={"Search in alarms"}
                />
              </div>
              <NavLink to={"/alarms/add"} className={"hidden lg:block"}>
                <Button
                  className={
                    "flex items-center text-base tracking-tight lg:rounded-3xl h-12 font-medium xl:px-6 sm:px-4"
                  }
                  text={"Add new alarm"}
                  buttonStyleType={"MPrimary"}
                  icon={<AddOutline fill={text20} />}
                />
              </NavLink>
            </div>

            <SearchResult
              icon={<SearchT />}
              mobileIcon={<Search64T />}
              title={"No results with " + '"' + search + '"'}
            />
          </>
        );
      } else {
        //render no data
        return (
          <Empty
            icon={<NotificationT />}
            title={"Always have your ear to the ground"}
            des={
              "Alarms help you to be aware of latest changes you are curious about them by sending messages, emails, etc."
            }
            linkText={"Create first alarm"}
            to={"/alarms/add"}
            linkIcon={<AddOutline fill={text20} />}
          />
        );
      }
    } else {
      //render data
      return (
        <>
          <div className="flex justify-between pb-6">
            <div className={"basis-full lg:basis-1/4"}>
              <TextField
                size={"Big"}
                prefixIcon={<SearchO fill={text50} size={"Big"} />}
                onChange={(event: any) => {
                  if (event.target.value.length > 2) {
                    makeRequest(event.target.value);
                    setSearch(event.target.value);
                  } else if (event.target.value.length === 0)
                    makeRequest(event.target.value);
                  setSearch(event.target.value);
                }}
                placeholder={"Search in alarms"}
              />
            </div>
            <NavLink to={"/alarms/add"} className={"hidden lg:block"}>
              <Button
                className={
                  "flex items-center text-base tracking-tight lg:rounded-3xl h-12 font-medium xl:px-6 sm:px-4"
                }
                text={"Add new alarm"}
                buttonStyleType={"MPrimary"}
                icon={<AddOutline fill={text20} />}
              />
            </NavLink>
          </div>

          <DataList
            data={result}
            hasMore={hasMore}
            onDelete={(id: any) => {
              setResult([...result?.filter((i: any) => i.id !== id)]);
            }}
            editData={(data: any) => setResult(data)}
          />
        </>
      );
    }
  }

  function renderModal() {
    if (isMobile || isTablet) {
      return (
        <>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customMobileStyles}
          >
            <div className={"flex justify-between items-center mb-6"}>
              <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
                How to use alarms?
              </div>

              <button onClick={closeModal}>
                <CloseO fill={text60} />
              </button>
            </div>

            <div className={"w-full h-1/2"}>
              <img src={alarmMock} />
            </div>

            <div className={"text-base " + `text-[${text80Body}]`}>
              click <a className={`text-[${primary50Main}]`}>here </a> to see
              more
            </div>
          </Modal>
        </>
      );
    } else if (isDesktop) {
      return (
        <>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div className={"flex justify-between items-center mb-6"}>
              <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
                How to use alarms?
              </div>

              <button onClick={closeModal}>
                <CloseO fill={text60} />
              </button>
            </div>

            <div className={"w-full h-1/2"}>
              <img src={alarmMock} />
            </div>

            <div className={"text-base " + `text-[${text80Body}]`}>
              click <a className={`text-[${primary50Main}]`}>here </a> to see
              more
            </div>
          </Modal>
        </>
      );
    }
  }

  return (
    <>
      <div className={"flex  justify-between items-center xl:mb-6 mb-4"}>
        <PageTitles
          text={"Alarms"}
          from={result.length}
          to={"10"}
          activePlan={profile?.active_plan?.is_active === 1}
          onClickIcon={() => setIsOpen(true)}
          icon={<QuestionO fill={primary50Main} />}
        />

        <NavLink to={"/alarms/add"} className={"lg:hidden"}>
          <AddOutline fill={primary50Main} />
        </NavLink>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          {renderContent()}
          {snackbarConfig.show ? (
            <Snackbar
              className={"fixed bottom-[24px] left-0 right-0 m-auto"}
              toastType={snackbarConfig.type}
              childMode={mode}
              btnText={snackbarConfig.btnText}
              icon={snackbarConfig.icon}
              onClick={snackbarConfig.onClick}
              text={snackbarConfig.text}
            />
          ) : (
            ""
          )}
        </>
      )}

      {renderModal()}
    </>
  );
}
