import http from './http-common'

let token = localStorage.getItem('token');

class TicketService {
    create(data: any, customToken?: string) {
        return http.post<any>('tickets', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-type': 'multipart/form-data',
            },
        })
    }

    getAll(page?: any, filterParam = '', customToken?: string) {
        let url = `tickets?page=${page}`;
        if (filterParam.length > 2) {
            url += `&subject=${filterParam}`
        }
        return http.get<any>(url, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    getOne(id: any, customToken?: string) {
        return http.get<any>('tickets/' + id, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    addMessage(id: any, data: any, customToken?: string) {
        return http.post<any>('tickets/' + id + '/add-message?_method=put', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-type': 'multipart/form-data',
            },
        })
    }

    getAllMessage(id: any, customToken?: string) {
        return http.get<any>('tickets/' + id + '/messages-list', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    edit(id: any, customToken?: string) {
        return http.patch<any>('tickets/' + id, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    delete(id: any, customToken?: string) {
        return http.delete<any>('tickets/' + id, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    close(id: any, customToken?: string) {
        return http.patch<any>('tickets/' + id + '/close', '', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }
}

export default new TicketService()
