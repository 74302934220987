import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import { useTheme } from 'styled-components'


export default function Loading() {
    return (
       <>
           <div className="hidden xl:grid grid-cols-3 gap-3">
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>
                       <div className={'mt-5 flex relative'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
           </div>

           <div className="xl:hidden">
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'mb-4'}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/4'}>
                           <Skeleton className={'mb-3'}/>
                       </div>
                       <Skeleton count={3}/>

                       <div className={'mt-5 flex relative'}>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (1 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (2 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (3 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                           <div className={'w-8 h-8 rounded-full relative'} style={{left: (4 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>
                       </div>
                   </SkeletonTheme>
               </div>
           </div>
       </>
    )
}
