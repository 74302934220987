import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}


const UploadO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8201 6.38C14.9701 6.53 15.1601 6.6 15.3501 6.6C15.5401 6.6 15.7401 6.53 15.8801 6.38C16.1701 6.09 16.1701 5.61 15.8801 5.32L12.5301 1.97C12.2401 1.68 11.7601 1.68 11.4701 1.97L8.12009 5.32C7.83009 5.61 7.83009 6.09 8.12009 6.38C8.41009 6.67 8.89009 6.67 9.18009 6.38L11.25 4.31009V15C11.25 15.41 11.59 15.75 12 15.75C12.41 15.75 12.75 15.41 12.75 15V4.30991L14.8201 6.38Z" fill={props.fill}/>
            <path d="M15.26 22.25H8.73998C3.82998 22.25 1.72998 20.15 1.72998 15.24V15.11C1.72998 10.67 3.47998 8.53003 7.39998 8.16003C7.79998 8.13003 8.17998 8.43003 8.21998 8.84003C8.25998 9.25003 7.95998 9.62003 7.53998 9.66003C4.39998 9.95003 3.22998 11.43 3.22998 15.12V15.25C3.22998 19.32 4.66998 20.76 8.73998 20.76H15.26C19.33 20.76 20.77 19.32 20.77 15.25V15.12C20.77 11.41 19.58 9.93003 16.38 9.66003C15.97 9.62003 15.66 9.26003 15.7 8.85003C15.74 8.44003 16.09 8.13003 16.51 8.17003C20.49 8.51003 22.27 10.66 22.27 15.13V15.26C22.27 20.15 20.17 22.25 15.26 22.25Z" fill={props.fill}/>
        </svg>
    );
};

export default UploadO;
