import http from "./http-common";
import useAuth from "../useAuth";

let token = localStorage.getItem("token");

class DashboardService {
  getSignals(
    page: any,
    query: any,
    strategy_id: any,
    time_frames: any,
    position_side: any,
    customToken?: string
  ) {
    return http.get<any>(
      `signals?page=${page}${query}&strategy_ids=${strategy_id}&time_frames=${time_frames}&position_side=${position_side}`,
      {
        headers: {
          Authorization: "Bearer " + customToken || token,
        },
      }
    );
  }

  getSignalsByFilter(page: any, searchParams: string, customToken?: string) {
    return http.get<any>(`signals?page=${page}${searchParams}`, {
      headers: {
        Authorization: "Bearer " + customToken || token,
      },
    });
  }

  getPlans(customToken?: string) {
    return http.get<any>("plans", {
      headers: {
        Authorization: "Bearer " + customToken || token,
      },
    });
  }

  getInbox(data: any, customToken?: string) {
    return http.get("inbox?read=" + data, {
      headers: {
        Authorization: "Bearer " + customToken || token,
      },
    });
  }

  getInboxes(type?: any, customToken?: string) {
    return http.get("inbox?type=" + type, {
      headers: {
        Authorization: "Bearer " + customToken || token,
      },
    });
  }

  getSubInboxes(readStatus?: any, customToken?: string) {
    return http.get("inbox?read=" + readStatus, {
      headers: {
        Authorization: "Bearer " + customToken || token,
      },
    });
  }

  getNewMessage(customToken?: string) {
    return http.get("inbox?read=0", {
      headers: {
        Authorization: "Bearer " + customToken || token,
      },
    });
  }

  readInbox(data: any, customToken?: string) {
    return http.post<any>("inbox/" + data + "/read", data, {
      headers: {
        Authorization: "Bearer " + customToken || token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
}

export default new DashboardService();
