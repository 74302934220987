import React, { useEffect } from "react";

const WorkerProvider = ({ children }: React.PropsWithChildren) => {
    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("/service-worker-v1.0.1.js")
                .then((registration) => console.log(registration));
        }
    }, []);

    return <>{children}</>;
};

export default WorkerProvider;