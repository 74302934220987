import styled, { css } from "styled-components";

export const LayoutWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.surface50Background};
  min-height: 100vh;
  height: 100%;
`;

export const ContextWrapper = styled.div`
  display: flex;
  overflow-y: auto;
  height: calc(100vh - 97px);
  @media screen and (max-width: 640px) {
    height: calc(100vh - 77px);
  }
`;

export const SideWrapper = styled.div<{ width: string }>`
  height: 100%;
  transition: width 3s;
  @media screen and (max-width: 768px) {
    width: 0;
  }
  ${({ width }) => {
    if (width) {
      return css`
        width: ${width};
      `;
    }
  }}
`;
export const Context = styled.div<{ width: string }>`
  width: 100% !important;
  padding: 56px 34px;
  height: calc(100vh - 100px);
  overflow: auto;
  position: relative;
  wid @media screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
    height: 100%;
  }
  @media screen and (min-width: 768px) {
    padding: 16px 16px 0;
    width: 100%;
  }
  @media screen and (min-width: 1536px) {
    padding: 56px 64px 0;
    width: 100%;
  }
  ${({ width }) => {
    if (width) {
      return css`
        width: calc(100% - ${width});
      `;
    }
  }}
`;
