import {useTheme} from "styled-components";
import Button from "../../../components/ds/Button";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ReactCodeInput from "react-verification-code-input";
import CheckB from "../../../assets/icons/Check-b";
import Snackbar, {ToastTypes} from "../../../components/ds/Snackbar";
import CloseB from "../../../assets/icons/Close-b";
import AuthService from "../../../services/auth.service";

export default function Google2Fa() {
    const navigate = useNavigate();
    const text90 = useTheme().colors.text90;
    const text50 = useTheme().colors.text50;
    const primary50Main = useTheme().colors.primary50Main;
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState<any>("");
    const [otp, setOTP] = useState<any>("");
    const error50Main = useTheme().colors.error50Main;
    const success50Main = useTheme().colors.success50Main;
    const mode = useTheme().colors.mode;
    const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
        text: "",
        icon: "",
        btnText: "",
        show: false,
    });

    useEffect(() => {
        if (searchParams.get("token")) {
            setToken(searchParams.get("token"));
        }
    }, []);

    function handleChange(vals: any) {
        if (vals.length >= 6) {
            setOTP(vals);
        } else if (vals.length === 0) {
        }
    }

    return (
        <div
            className={
                "flex xl:flex-row flex-col xl:h-screen " +
                `bg-[${useTheme().colors.surface50Background}]`
            }
        >
            <div
                className={
                    "xl:w-4/12 w-full h-full items-center xl:p-28 pt-16 px-28 relative " +
                    `xl:bg-[${useTheme().colors.surface60}]`
                }
            >
                <svg
                    id={'svg-Google2Fa'}
                    width="480"
                    height="480"
                    viewBox="0 0 480 480"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{height: "100%", width: "100%"}}
                >
                    <path
                        d="M210.711 455.956C306.121 455.956 383.467 440.634 383.467 421.734C383.467 402.834 306.121 387.512 210.711 387.512C115.301 387.512 37.9556 402.834 37.9556 421.734C37.9556 440.634 115.301 455.956 210.711 455.956Z"
                        fill="#D3D8DA"
                    />
                    <path
                        d="M231.867 35.9546C231.733 44.0435 225.778 50.6657 218 51.8657L217.333 98.0879C217.333 99.5546 216.089 100.755 214.578 100.755C213.067 100.755 211.911 99.5102 211.911 97.999L212.578 51.7768C204.889 50.3546 199.111 43.5546 199.244 35.4657C199.378 26.4435 206.8 19.2435 215.822 19.3768C224.8 19.5102 232 26.9324 231.867 35.9546Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M223.2 357.199C223.2 361.287 219.911 364.576 215.822 364.576C211.733 364.576 208.444 361.287 208.444 357.199C208.444 354.087 210.356 351.421 213.111 350.354V304.754C213.111 303.243 214.311 302.043 215.822 302.043C217.289 302.043 218.533 303.243 218.533 304.754V350.399C221.289 351.465 223.2 354.132 223.2 357.199Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M365.556 167.867C350.356 167.867 338 155.511 338 140.311V75.5556C338 60.3556 350.356 48 365.556 48H418.622C433.822 48 446.178 60.3556 446.178 75.5556V140.311C446.178 155.511 433.822 167.867 418.622 167.867H365.556Z"
                        fill="#1BC8F8"
                    />
                    <path
                        d="M418.622 52.4006C431.378 52.4006 441.733 62.7562 441.733 75.5117V140.267C441.733 153.023 431.378 163.378 418.622 163.378H365.556C352.8 163.378 342.444 153.023 342.444 140.267V75.5562C342.444 62.8006 352.8 52.4451 365.556 52.4451H418.622M418.622 43.5117H365.556C347.911 43.5117 333.556 57.8673 333.556 75.5117V140.267C333.556 157.912 347.911 172.267 365.556 172.267H418.622C436.267 172.267 450.622 157.912 450.622 140.267V75.5562C450.667 57.8673 436.267 43.5117 418.622 43.5117Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M393.378 54.1339C393.378 61.1562 387.689 66.845 380.667 66.845H367.778V79.7339C367.778 86.7562 362.089 92.4451 355.067 92.4451C348.044 92.4451 342.356 86.7562 342.356 79.7339V66.845H334.711C338.489 53.3784 350.889 43.5117 365.556 43.5117H387.644C391.111 45.7784 393.378 49.6895 393.378 54.1339Z"
                        fill="#1BC8F8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M320.522 54.1336C320.522 49.1691 324.547 45.1445 329.511 45.1445H380.667C385.631 45.1445 389.656 49.1691 389.656 54.1336C389.656 59.0982 385.631 63.1227 380.667 63.1227H329.511C324.547 63.1227 320.522 59.0982 320.522 54.1336Z"
                        fill="#034A5E"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M355.111 19.5898C360.076 19.5898 364.1 23.6144 364.1 28.5789V79.7345C364.1 84.699 360.076 88.7236 355.111 88.7236C350.147 88.7236 346.122 84.699 346.122 79.7345V28.5789C346.122 23.6144 350.147 19.5898 355.111 19.5898Z"
                        fill="#034A5E"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M379.107 84.1363C379.107 81.6793 381.099 79.6875 383.556 79.6875H427.689C430.146 79.6875 432.138 81.6793 432.138 84.1363C432.138 86.5933 430.146 88.5851 427.689 88.5851H383.556C381.099 88.5851 379.107 86.5933 379.107 84.1363Z"
                        fill="#034A5E"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M353.996 111.023C353.996 108.566 355.987 106.574 358.444 106.574H427.511C429.968 106.574 431.96 108.566 431.96 111.023C431.96 113.48 429.968 115.472 427.511 115.472H358.444C355.987 115.472 353.996 113.48 353.996 111.023Z"
                        fill="#034A5E"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M353.996 137.734C353.996 135.277 355.987 133.285 358.444 133.285H427.511C429.968 133.285 431.96 135.277 431.96 137.734C431.96 140.191 429.968 142.183 427.511 142.183H358.444C355.987 142.183 353.996 140.191 353.996 137.734Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M320.222 409.422C320.222 415.155 324.889 419.822 330.622 419.822C336.356 419.822 341.022 415.155 341.022 409.422C341.022 405.555 338.933 402.178 335.778 400.4L343.689 316.311C343.911 313.955 342.178 311.866 339.822 311.644C337.467 311.422 335.378 313.155 335.156 315.511L327.244 399.6C323.156 401.022 320.222 404.889 320.222 409.422Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M294.311 289.066L325.2 311.066C324.667 312.577 324.4 314.221 324.4 315.955C324.4 324.266 331.156 331.021 339.467 331.021C347.778 331.021 354.533 324.266 354.533 315.955C354.533 307.644 347.778 300.888 339.467 300.888C335.956 300.888 332.756 302.088 330.222 304.088L299.333 282.088C297.422 280.71 294.711 281.155 293.333 283.11C291.911 285.021 292.356 287.688 294.311 289.066Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M113.6 409.422C113.6 415.155 108.933 419.822 103.2 419.822C97.4667 419.822 92.8 415.155 92.8 409.422C92.8 405.555 94.8889 402.178 98.0445 400.4L90.1333 316.311C89.9111 313.955 91.6445 311.866 94 311.644C96.3556 311.422 98.4445 313.155 98.6667 315.511L106.578 399.6C110.667 401.022 113.6 404.889 113.6 409.422Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M139.556 289.066L108.667 311.066C109.2 312.577 109.467 314.221 109.467 315.955C109.467 324.266 102.711 331.021 94.4 331.021C86.0889 331.021 79.3333 324.266 79.3333 315.955C79.3333 307.644 86.0889 300.888 94.4 300.888C97.9111 300.888 101.111 302.088 103.644 304.088L134.533 282.088C136.444 280.71 139.156 281.155 140.533 283.11C141.911 285.021 141.467 287.688 139.556 289.066Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M215.822 336.577C286.146 336.577 343.156 279.568 343.156 209.243C343.156 138.919 286.146 81.9102 215.822 81.9102C145.498 81.9102 88.4889 138.919 88.4889 209.243C88.4889 279.568 145.498 336.577 215.822 336.577Z"
                        fill="#1BC8F8"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M91.3587 182.236C106.551 236.536 148.777 278.886 201.244 290.488C249.002 301.04 300.004 284.98 336.731 249.29C340.899 236.698 343.156 223.234 343.156 209.243C343.156 138.919 286.146 81.9102 215.822 81.9102C154.765 81.9102 103.745 124.885 91.3587 182.236Z"
                        fill="#1BC8F8"
                    />
                    <path
                        d="M184.222 309.467L152.089 345.423C154.044 348.134 155.2 351.467 155.2 355.112C155.2 364.267 147.778 371.689 138.622 371.689C129.467 371.689 122.044 364.267 122.044 355.112C122.044 345.956 129.467 338.534 138.622 338.534C141.022 338.534 143.333 339.067 145.422 340L177.778 303.778C179.333 302 182.089 301.867 183.822 303.423C185.644 304.978 185.778 307.689 184.222 309.467Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M169.378 433.688C169.378 440.088 164.178 445.288 157.778 445.288C151.378 445.288 146.178 440.088 146.178 433.688C146.178 429.866 148.044 426.444 150.933 424.355L134.489 355.022C133.956 352.71 135.378 350.399 137.689 349.866C140 349.333 142.311 350.755 142.844 353.022L159.289 422.177C164.933 422.888 169.378 427.777 169.378 433.688Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M320.444 259.734L367.911 251.201C369.2 254.312 371.422 257.023 374.533 258.934C382.311 263.734 392.533 261.29 397.333 253.512C402.133 245.734 399.689 235.512 391.867 230.756C384.044 226.001 373.867 228.401 369.111 236.179C367.822 238.223 367.067 240.49 366.8 242.712L318.978 251.334C316.667 251.734 315.111 254.001 315.511 256.312C315.911 258.579 318.133 260.134 320.444 259.734Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M409.6 168.98C409.556 162.58 404.311 157.424 397.911 157.513C391.511 157.557 386.356 162.802 386.444 169.202C386.489 173.024 388.4 176.402 391.289 178.491L375.467 248.002C374.933 250.313 376.4 252.624 378.711 253.113C381.022 253.646 383.333 252.18 383.822 249.868L399.556 180.58C405.289 179.824 409.644 174.891 409.6 168.98Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M217.2 259.066C174.222 259.066 139.244 224.088 139.244 181.11C139.244 138.133 174.222 103.199 217.2 103.199C260.178 103.199 295.156 138.177 295.156 181.155C295.156 224.133 260.178 259.066 217.2 259.066ZM217.2 121.955C184.578 121.955 158.044 148.488 158.044 181.11C158.044 213.733 184.578 240.266 217.2 240.266C249.822 240.266 276.4 213.777 276.4 181.155C276.4 148.533 249.822 121.955 217.2 121.955Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M217.2 232.755C245.698 232.755 268.8 209.653 268.8 181.155C268.8 152.657 245.698 129.555 217.2 129.555C188.702 129.555 165.6 152.657 165.6 181.155C165.6 209.653 188.702 232.755 217.2 232.755Z"
                        fill="#034A5E"
                    />
                    <path
                        d="M243.689 193.599C257.214 193.599 268.178 182.635 268.178 169.11C268.178 155.585 257.214 144.621 243.689 144.621C230.164 144.621 219.2 155.585 219.2 169.11C219.2 182.635 230.164 193.599 243.689 193.599Z"
                        fill="#1BC8F8"
                    />
                </svg>

                <div
                    className={
                        "font-normal text-base hidden xl:block absolute text-center right-0 left-0 bottom-8 " +
                        `text-[${useTheme().colors.text30}]`
                    }
                >
                    ©2022{" "}
                    <span className={`text-[${useTheme().colors.text50}]`}>Finomate</span>
                </div>
            </div>
            <div
                className={
                    "xl:w-8/12 w-full h-full  2xl:px-80 xl:px-56 flex flex-col justify-center p-4"
                }
            >
                <>
                    <div
                        className={
                            "font-normal xl:text-4xl text-3xl mb-2 " + `text-[${text90}]`
                        }
                    >
                        Two-Factor Authentication
                    </div>

                    <div
                        className={
                            "font-normal xl:text-base text-xs mb-8 " + `text-[${text50}]`
                        }
                    >
                        To login to your account, enter the validation code in the Google
                        Authenticator.
                    </div>

                    <div className={"my-8"}>
                        <ReactCodeInput
                            onChange={handleChange}
                            onComplete={(val) => {
                                setOTP(val);
                            }}
                            fields={6}
                        />
                    </div>

                    <Button
                        buttonStyleType={"BPrimary"}
                        text={"Sign in"}
                        onClick={() => {
                            let params = new URLSearchParams();
                            params.append("2fa-token", token);
                            params.append("otp_code", otp);

                            AuthService.login2fa(params)
                                .then((response: any) => {
                                    if (response) {
                                        localStorage.setItem("token", response.data.token);
                                        localStorage.setItem("userEmail", response.data.user.email);
                                        localStorage.setItem(
                                            "userFirst_name",
                                            response.data.user.first_name
                                        );
                                        localStorage.setItem(
                                            "userLast_name",
                                            response.data.user.last_name
                                        );
                                        localStorage.setItem("userRole", response.data.user.role);
                                        navigate("/");
                                        window.location.reload();
                                    }
                                    setSnackbarConfig({
                                        text: response.data.message,
                                        icon: <CheckB fill={success50Main}/>,
                                        btnText: "Ok",
                                        type: ToastTypes.Success,
                                        onClick: () =>
                                            setSnackbarConfig({
                                                show: false,
                                            }),
                                        show: true,
                                    });
                                })
                                .catch((err) => {
                                    setSnackbarConfig({
                                        text: err?.response?.data?.message,
                                        icon: <CloseB fill={error50Main}/>,
                                        btnText: "Ok",
                                        type: ToastTypes.Error,
                                        onClick: () =>
                                            setSnackbarConfig({
                                                show: false,
                                            }),
                                        show: true,
                                    });

                                    setTimeout(() => {
                                        if (err.response.status === 401) {
                                            navigate("/");
                                        }
                                        if (err.response.status === 404) {
                                            navigate("*");
                                        }
                                    }, 3000);
                                });
                        }}
                        className={"w-full h-12 mt-8"}
                    />

                    <div className={" mt-8 text-center "}>
                        <NavLink
                            to={"/login"}
                            className={
                                "font-normal xl:text-base text-xs pl-1.5 " +
                                `text-[${primary50Main}]`
                            }
                        >
                            Back to login
                        </NavLink>
                    </div>
                </>
            </div>

            {snackbarConfig.show ? (
                <Snackbar
                    className={"fixed bottom-[24px] left-0 right-0 m-auto"}
                    toastType={snackbarConfig.type}
                    childMode={mode}
                    btnText={snackbarConfig.btnText}
                    icon={snackbarConfig.icon}
                    onClick={snackbarConfig.onClick}
                    text={snackbarConfig.text}
                />
            ) : (
                ""
            )}
        </div>
    );
}
