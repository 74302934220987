import { useTheme } from "styled-components";
import Button from "../../components/ds/Button";
import CheckO from "../../assets/icons/Check-o";
import VerifyT from "../../assets/icons/plan/Verify-t";
import PremiumT from "../../assets/icons/plan/Premium-t";

export interface Plan_Options {
  title?: any;
  planIcon?: any;
  price?: any;
  priceSymbol?: any;
  duration?: any;
  features?: any[];
  actionTitle?: any;
  onClick?: any;
}

export default function Cart(props: Plan_Options) {
  const success50Main = useTheme().colors.success50Main;
  const text80Body = useTheme().colors.text80Body;
  const plans = [
    { id: 1, title: "All markets" },
    { id: 2, title: "All Strategies" },
    { id: 3, title: "More than 80 coins" },
    { id: 4, title: "Unlimited pairs" },
    { id: 5, title: "+12 timeframes" },
    { id: 6, title: "24/7 support" },
  ];

  function Icons(data: any) {
    if (data === "Freemium") {
    } else if (data === "Premium") {
      return (
        <div className={"mr-4"}>
          <VerifyT />
        </div>
      );
    } else if (data === "EXTENDED") {
      return (
        <div className={"mr-4"}>
          <PremiumT />
        </div>
      );
    }
  }

  function ActionButton(data: any) {
    if (data.indexOf("Freemium") > -1) {
      return (
        <Button
          text={"Current plan"}
          onClick={props.onClick}
          buttonStyleType={"MSecondary"}
          className={"w-full h-12 mt-10 2xl:mt-[150px]"}
        />
      );
    } else if (data === "Premium") {
      return (
        <Button
          text={"Get started"}
          onClick={props.onClick}
          buttonStyleType={"BPrimary"}
          className={"w-full h-12  mt-10 2xl:mt-[150px]"}
        />
      );
    } else if (data === "EXTENDED") {
      return (
        <Button
          text={"Get started"}
          onClick={props.onClick}
          buttonStyleType={"BPrimary"}
          className={"w-full h-12  mt-10 2xl:mt-[150px]"}
        />
      );
    }
  }

  return (
    <div className={"border border-solid p-6 rounded-[40px]"}>
      <div className={"flex items-center"}>
        {Icons(props.title)}
        <div
          className={
            "text-xl 2xl:text-3xl font-semibold " +
            `text-[${useTheme().colors.text90}]`
          }
        >
          {props.title}
        </div>
      </div>

      <div className={"flex items-center"}>
        <div
          className={
            "font-semibold text-[24px] 2xl:text-[28px] " +
            `text-[${useTheme().colors.text50}]`
          }
        >
          $
        </div>
        {/*<div className={'font-semibold text-[28px] ' + `text-[${useTheme().colors.text50}]`}>{props.priceSymbol}</div>*/}
        <div
          className={
            "font-semibold text-xl 2xl:text-[44px] " +
            `text-[${useTheme().colors.text90}]`
          }
        >
          {props.price}
        </div>
        <div
          className={
            "font-semibold text-xl 2xl:text-[44px] " +
            `text-[${useTheme().colors.text50}]`
          }
        >
          /
        </div>
        <div
          className={
            "font-semibold text-sm 2xl:text-base " +
            `text-[${useTheme().colors.text50}]`
          }
        >
          {props.duration} days
        </div>
      </div>

      <div
        className={
          "my-6 w-full h-px " + `bg-[${useTheme().colors.surface80Divider}]`
        }
      ></div>

      <div>
        {plans?.map((item) => {
          return (
            <div className={"flex  items-center mb-4"}>
              <CheckO fill={success50Main} />
              <div
                className={
                  "text-base font-normal pl-2 " + `text-[${text80Body}]`
                }
              >
                {item.title}
              </div>
            </div>
          );
        })}
      </div>

      {ActionButton(props.title)}
    </div>
  );
}
