import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const WalletO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 12.75H14C14.41 12.75 14.75 12.41 14.75 12C14.75 11.59 14.41 11.25 14 11.25H7C6.59 11.25 6.25 11.59 6.25 12C6.25 12.41 6.59 12.75 7 12.75Z" fill={props.fill}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6 7H17.74C20.23 7 22.25 9.02001 22.25 11.51V12.3581C22.8765 12.637 23.3 13.2533 23.3 13.9699V16.03C23.3 16.7514 22.8708 17.3712 22.25 17.6474V18.23C22.25 20.73 20.23 22.75 17.74 22.75H6.26C3.77 22.75 1.75 20.73 1.75 18.24V7.84006C1.75 6.35006 2.69 5.00001 4.08 4.47001L12.02 1.47001C12.84 1.16001 13.75 1.27005 14.46 1.77005C15.18 2.27005 15.6 3.08005 15.6 3.95005V7ZM3.24 8.16094C4.0396 7.4394 5.09868 7 6.26 7H14.1V3.95005C14.1 3.57005 13.92 3.22003 13.6 3.00003C13.28 2.78003 12.9 2.73003 12.54 2.87003L4.6 5.87003C3.79 6.18003 3.24 6.97006 3.24 7.84006V8.16094ZM18.57 14.08L18.5529 14.0968C18.4535 14.1982 18.373 14.3119 18.3129 14.4332C18.2133 14.6379 18.1716 14.867 18.19 15.1C18.25 15.76 18.88 16.29 19.6 16.29H21.5C21.5332 16.29 21.5659 16.2922 21.598 16.2965C21.7125 16.276 21.81 16.1652 21.81 16.03V13.9699C21.81 13.8199 21.69 13.71 21.53 13.7H21.5095L21.5 13.7H19.48C19.13 13.7 18.81 13.83 18.58 14.07L18.57 14.08ZM20.75 11.51V12.2H19.47C18.72 12.2 18.02 12.4899 17.51 13.0099C16.9671 13.5438 16.6698 14.2578 16.6774 15.0037C16.678 15.0742 16.6815 15.145 16.6878 15.2158L16.69 15.2399C16.7008 15.3693 16.7209 15.4962 16.7498 15.6198C16.8012 15.8402 16.8802 16.0505 16.983 16.2475C17.4644 17.17 18.4675 17.8 19.6 17.8H20.75V18.24C20.75 19.9 19.4 21.25 17.74 21.25H6.26C4.6 21.25 3.25 19.9 3.25 18.24V11.51C3.25 9.85001 4.6 8.5 6.26 8.5H14.8429L14.85 8.50003L14.8571 8.5H17.74C19.4 8.5 20.75 9.85001 20.75 11.51Z" fill={props.fill}/>
        </svg>
    );
};

export default WalletO;
