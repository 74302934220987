import http from './http-common'

let token = localStorage.getItem('token');

class ListService {
    create(data: any,customToken?:string){
        return http.post<any>('lists',data,{
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
    }

    getAll(page?:any,filterParam='',customToken?:string){
        let url = `lists?page=${page}`;
        if(filterParam.length>2){
            url+=`&title=${filterParam}`
        }

        return http.get<any>(url,{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }


    getOne(id: any,customToken?:string){
        return http.get<any>('lists/' + id,{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    edit(id: any,data:any, customToken?:string){
        return http.patch<any>('lists/' + id,data,{
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
    }

    delete(id: any,customToken?:string){
        return http.delete<any>('lists/' + id,{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }
}

export default new ListService()
