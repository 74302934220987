import {useTheme} from "styled-components";
import TextField from "../../../components/ds/Textfield";
import EyeSlashO from "../../../assets/icons/Eye-slash-o";
import EyeO from "../../../assets/icons/Eye-o";
import Button from "../../../components/ds/Button";
import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams, useSearchParams} from "react-router-dom";
import AuthService from "../../../services/auth.service";
import CheckO from "../../../assets/icons/Check-o";
import Snackbar, {ToastTypes} from "../../../components/ds/Snackbar";
import CloseB from "../../../assets/icons/Close-b";
import SecuritySvg from "../../../assets/svg-files/security.svg";


export default function NewPassword() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    let { id } = useParams();
    const [email, setEmail] = useState<any>('')
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [token, setToken] = useState('');

    const [showPass, setShowPass] = React.useState(false)
    const [confirmShowPass, setConfirmShowPass] = React.useState(false)

    const mode = useTheme().colors.mode
    const success50Main = useTheme().colors.success50Main;
    const error50Main = useTheme().colors.error50Main;
    const text50 = useTheme().colors.text50;

    const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
        text: '',
        icon: '',
        btnText: '',
        show: false,
    })
    useEffect(() => {
        if(searchParams.get("email")){
            setEmail(searchParams.get("email"))
        }
        if(id){
            setToken(id)
        }
    }, [])

    return (
        <div className={'flex xl:flex-row flex-col overflow-auto h-screen ' + `bg-[${useTheme().colors.surface50Background}]`}>
            <div
                className={'xl:w-4/12 w-full mb-[30px] md:mb-[0px] items-center xl:p-28 pt-16 px-28 relative ' + `xl:bg-[${useTheme().colors.surface60}]`}>

                <img src={SecuritySvg} alt=""/>

                <div className={'font-normal text-base hidden xl:block absolute text-center right-0 left-0 bottom-8 ' + `text-[${useTheme().colors.text30}]`}>
                    ©2022 <span className={`text-[${useTheme().colors.text50}]`}>Finomate</span>
                </div>
            </div>
            <div className={'xl:w-8/12 w-full h-full  2xl:px-80 xl:px-56 2xl:pt-40 xl:pt-6 p-4'}>

                <div className={'font-normal xl:text-4xl text-3xl mb-2 ' + `text-[${useTheme().colors.text90}]`}>
                    New Password
                </div>

                <div className={'font-normal xl:text-base text-xs mb-8 ' + `text-[${useTheme().colors.text50}]`}>
                    Please enter and confirm your new password
                </div>


                <TextField size={'Big'} label={'Password'} hint={'Min 6 characters'} parentClassName={'mb-6'}
                           onChange={(event:any)=>setPassword(event.target.value)}
                           suffixOnClick={() => setShowPass(!showPass)} type={showPass ? 'text' : 'password'}
                           suffixIcon={showPass ? <EyeSlashO fill={text50}/> : <EyeO fill={text50}/>}/>
                <TextField size={'Big'} label={'Confirm password'}
                           onChange={(event:any)=>setPassword_confirmation(event.target.value)}
                           suffixOnClick={() => setConfirmShowPass(!confirmShowPass)}
                           type={confirmShowPass ? 'text' : 'password'}
                           suffixIcon={confirmShowPass ? <EyeSlashO fill={text50}/> : <EyeO fill={text50}/>}/>


                <Button buttonStyleType={'BPrimary'}
                        text={'Change password'}
                        className={'w-full h-12 mt-8 mb-8'}
                        onClick={()=>{
                            AuthService.resetPassword({email,token,password,password_confirmation}).then((response:any)=>{
                                setSnackbarConfig({
                                    text: response.data.message,
                                    icon: <CheckO fill={success50Main}/>,
                                    btnText: 'Ok',
                                    type: ToastTypes.Error,
                                    onClick: () => navigate('/'),
                                    show: true,
                                })

                                setTimeout(() => {
                                  navigate('/')
                                }, 3000);
                            }).catch((err)=>{
                                setSnackbarConfig({
                                    text: err.response.data.message,
                                    icon: <CloseB fill={error50Main}/>,
                                    btnText: 'Ok',
                                    type: ToastTypes.Error,
                                    onClick: () =>setSnackbarConfig({
                                        show: false,
                                    }),
                                    show: true,
                                })
                                setTimeout(() => {
                                    if (err.response.status === 401) {
                                        navigate('/')
                                    }
                                    if (err.response.status === 403) {
                                        if (err?.response?.data?.message === 'Your email address is not verified.') {
                                            navigate('/settings/profile')
                                        } else {
                                            navigate('/plans')
                                        }
                                    }
                                    if (err.response.status === 404) {
                                        navigate('*')
                                    }
                                }, 3000);
                            })

                        }}/>


                <NavLink to={'/login'} className={'font-normal xl:text-base text-xs block m-auto text-center ' + `text-[${useTheme().colors.primary50Main}]`}>
                    Back to login
                </NavLink>

                {snackbarConfig.show ? <Snackbar className={'fixed bottom-[24px] left-0 right-0 m-auto'}
                                                 toastType={snackbarConfig.type}
                                                 childMode={mode}
                                                 btnText={snackbarConfig.btnText}
                                                 icon={snackbarConfig.icon}
                                                 onClick={snackbarConfig.onClick}
                                                 text={snackbarConfig.text}/> : ''}
            </div>


        </div>
    )
}
