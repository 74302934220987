import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}
const CheckO = (props: any) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5305 7.46983C18.8233 7.76281 18.8232 8.23768 18.5302 8.53049L10.5255 16.5305C10.3847 16.6711 10.1939 16.7501 9.99496 16.75C9.79601 16.7499 9.60525 16.6708 9.46465 16.53L5.46936 12.53C5.17664 12.237 5.17692 11.7621 5.46998 11.4694C5.76305 11.1766 6.23792 11.1769 6.53064 11.47L9.99576 14.9392L17.4698 7.46951C17.7628 7.17671 18.2377 7.17685 18.5305 7.46983Z" fill={props.fill}/>
        </svg>
    );
};

export default CheckO;
