import http from './http-common'

let token = localStorage.getItem('token');

class InviteService {
    levels(customToken?: string) {
        return http.get<any>('invite/levels?state=2', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }
    getMyReferralCode(customToken?: string) {
        return http.get<any>('invite/my-referral-code', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }
    getMyFriends(customToken?: string) {
        return http.get<any>('invite/friends', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }
    share(email: any, nameInvite: any, customToken?: string) {
        return http.post<any>('invite/share/send-mail', {
            mail: email,
            guest_name: nameInvite
        }, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    changeReward(mode: any, customToken?: string) {
        return http.patch<any>('invite/levels/split-reward', mode, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })

    }

    getCurrentReward(customToken?: string) {
        return http.get<any>('invite/levels/split-reward/status', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    profit(customToken?: string) {
        return http.get<any>('/invite/profit', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }
}

export default new InviteService()
