import styled, {css} from "styled-components";


export const PageTitle = styled.div`
 
`
export const SubTitle = styled.div`
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: ${({theme}) => theme.colors.text50};
`
export const Count = styled.div`
  color: ${({theme}) => theme.colors.text50};
`