import { NavLink, useNavigate } from "react-router-dom";
import PageTitles from "../../components/ds/PageTitle";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import { useTheme } from "styled-components";
import TextField from "../../components/ds/Textfield";
import Button from "../../components/ds/Button";
import React, { useEffect, useState } from "react";
import BaseService from "../../services/base.service";
import TextAreaField from "../../components/ds/TextAreaField";
import { ErrorMessage, Formik } from "formik";
import TicketService from "../../services/ticket.service";
import Loading from "../../assets/icons/Loading";
import FileUpload from "../../components/ds/UploadFile";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import CloseB from "../../assets/icons/Close-b";
import CheckO from "../../assets/icons/Check-o";
import useAuth from "../../useAuth";

export default function AddTicket() {
  const navigate = useNavigate();
  const surface80Divider = useTheme().colors.surface80Divider;
  const success50Main = useTheme().colors.success50Main;
  const error50Main = useTheme().colors.error50Main;
  const text50 = useTheme().colors.text50;
  const text30 = useTheme().colors.text30;
  const [resultDepartments, setResultDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const mode = useTheme().colors.mode;
  const { token } = useAuth();
  const [selectDepartments, setSelectDepartments] = useState<any>(0)
  const primary50Main = useTheme().colors.primary50Main;
  const primary20 = useTheme().colors.primary20;

  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    type: "",
    btnText: "",
    show: false,
  });
  useEffect(() => {
    BaseService.getDepartments(token)
      .then((response: any) => {
        setResultDepartments(response.data);        
        setSelectDepartments(response.data[response.data.length - 1].id)
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }, []);

  return (
    <>
      <NavLink to={"/tickets"}>
        <PageTitles
          text={"New Ticket"}
          arrow={<ArrowLeft2Outline fill={useTheme().colors.text50} />}
        />
      </NavLink>

      <div className={"w-full lg:w-4/5 2xl:w-3/5 mt-4"}>
        <Formik
          initialValues={{ subject: "", message: "" }}

          validate={(values) => {
            const errors: any = {};

            if (!values.subject) {
              errors.subject = "Required";
            }
            // if (!values.department_id) {
            //   errors.department_id = "Required";
            // }
            // department_id: ""
            if (!values.message) {
              errors.message = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            let form = new FormData();
            form.append("subject", values.subject);
            form.append("department_id", selectDepartments.toString());
            form.append("message", values.message);
            for (let item of files) {
              form.append("files[]", item);
            }
            setLoading(true);
            TicketService.create(form, token)
              .then((response: any) => {
                if (response) {
                  setLoading(false);
                  setSnackbarConfig({
                    text: response.data.message,
                    icon: <CheckO fill={success50Main} />,
                    btnText: "Ok",
                    type: ToastTypes.Error,
                    onClick: () =>
                      setSnackbarConfig({
                        show: false,
                      }),
                    show: true,
                  });
                  navigate("/tickets");
                }
              })
              .catch((err) => {
                setSnackbarConfig({
                  text: err.response.data.message,
                  icon: <CloseB fill={error50Main} />,
                  btnText: "Ok",
                  type: ToastTypes.Error,
                  onClick: () =>
                    setSnackbarConfig({
                      show: false,
                    }),
                  show: true,
                });

                setTimeout(() => {
                  if (err.response.status === 401) {
                    navigate("/");
                  }
                  if (err.response.status === 403) {
                    if (
                      err?.response?.data?.message ===
                      "Your email address is not verified."
                    ) {
                      navigate("/settings/profile");
                    } else {
                      navigate("/plans");
                    }
                  }
                  if (err.response.status === 404) {
                    navigate("*");
                  }
                }, 3000);
              });
            setSubmitting(false);
          }}
        >
          {({
            values,
            isValid,
            handleChange,
            handleSubmit,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={"mb-6"}>
                <TextField
                  size={"Big"}
                  name={"subject"}
                  value={values.subject}
                  onChange={handleChange}
                  label={"Title"}
                  placeholder={"Ticket Title"}
                  required={true}
                />
                <ErrorMessage
                  name="subject"
                  component="div"
                  className={`text-[${error50Main}]`}
                />

                {/* <div className={"mt-2 mb-3"}>
                  <label className={`text-sm font-medium text-[${text50}]`}>
                    Department
                    <span className={`text-[${error50Main}]`}> *</span>
                  </label>
                  <select
                    className={`border px-5 mt-3  text-base w-full h-12 bg-transparent  border-solid rounded-[24px] text-[${text30}] border-[${surface80Divider}]`}
                    name={"department_id"}
                    value={values.department_id}
                    onChange={handleChange}
                  >
                    {resultDepartments.map((item: any) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                  <ErrorMessage
                    name="department_id"
                    component="div"
                    className={`text-[${error50Main}]`}
                  />
                </div> */}

                <div className={"mt-2 mb-3"}>
                  <label className={`text-sm font-medium text-[${text50}]`}>
                    Department
                    <span className={`text-[${error50Main}]`}> *</span>
                  </label>
                  <div className={"mt-3 w-full xl:w-1/4 flex items-center gap-2 flex-wrap md:flex-nowrap"}>
                    {resultDepartments.map((item: any) => {
                      return (
                        <div
                          onClick={() => setSelectDepartments(item.id)}
                          className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] 
                          ${selectDepartments !== item.id ? `text-[${text30}]` : `text-[${primary50Main}]`} 
                          ${selectDepartments !== item.id ? `bg-transparent` : `bg-[${primary20}]`} 
                          ${selectDepartments !== item.id ? `border-[${surface80Divider}]` : `border-[${primary50Main}]`
                            }`}                 >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <TextAreaField
                  size={"Big"}
                  name={"message"}
                  value={values.message}
                  onChange={handleChange}
                  label={"Message"}
                  placeholder={"Ticket Message"}
                  required={true}
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className={`text-[${error50Main}]`}
                />

                <FileUpload
                  selectFile={(event: any) => {
                    setFiles([...files, event]);
                  }}
                  setFile={(file: any) => setFiles(file)}
                />
              </div>

              <div
                className={
                  "flex lg:w-2/4 items-center lg:justify-between justify-end  mr-0 pl-auto py-8 gap-3 "
                }
              >
                <NavLink className={"lg:w-2/4"} to={"/tickets"}>
                  <Button
                    text={"Cancel"}
                    className={`px-6 rounded-3xl border border-solid font-medium text-base h-12 w-full text-[${text50}]`}
                  />
                </NavLink>
                <Button
                  text={"Add ticket"}
                  status={!isValid || loading}
                  loading={loading ? <Loading /> : ""}
                  buttonStyleType={"BPrimary"}
                  className={"lg:w-2/4 h-12"}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>

      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}
    </>
  );
}
