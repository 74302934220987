import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const ArrowLeft2Outline = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="{{none}}" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.03994 18.6C9.18994 18.75 9.37994 18.82 9.56994 18.82C9.75994 18.82 9.95994 18.75 10.0999 18.6C10.3899 18.31 10.3899 17.83 10.0999 17.54L5.30994 12.75H20.4999C20.9099 12.75 21.2499 12.41 21.2499 12C21.2499 11.59 20.9099 11.25 20.4999 11.25H5.30994L10.0999 6.46C10.3899 6.16999 10.3899 5.69 10.0999 5.4C9.80994 5.11 9.32994 5.11 9.03994 5.4L2.96994 11.47C2.67994 11.76 2.67994 12.24 2.96994 12.53L9.03994 18.6Z" fill={props.fill}/>
        </svg>
    );
};

export default ArrowLeft2Outline;
