import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "styled-components";
export default function Loading() {
  return (
    <>
      <div className="block w-full">
        <div
          className={`flex justify-between rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 bg-[${
            useTheme().colors.surface60
          }]`}
        >
          <SkeletonTheme
            baseColor={`${useTheme().colors.surface70}`}
            highlightColor={`${useTheme().colors.surface50Background}`}
          >
            <div className={"w-1/12 "}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
          </SkeletonTheme>
        </div>
        <div
          className={`flex justify-between rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 bg-[${
            useTheme().colors.surface60
          }]`}
        >
          <SkeletonTheme
            baseColor={`${useTheme().colors.surface70}`}
            highlightColor={`${useTheme().colors.surface50Background}`}
          >
            <div className={"w-1/12 "}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
          </SkeletonTheme>
        </div>
        <div
          className={`flex justify-between rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 bg-[${
            useTheme().colors.surface60
          }]`}
        >
          <SkeletonTheme
            baseColor={`${useTheme().colors.surface70}`}
            highlightColor={`${useTheme().colors.surface50Background}`}
          >
            <div className={"w-1/12 "}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
          </SkeletonTheme>
        </div>
        <div
          className={`flex justify-between rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 bg-[${
            useTheme().colors.surface60
          }]`}
        >
          <SkeletonTheme
            baseColor={`${useTheme().colors.surface70}`}
            highlightColor={`${useTheme().colors.surface50Background}`}
          >
            <div className={"w-1/12 "}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
            <div className={"w-1/6"}>
              <Skeleton />
            </div>
          </SkeletonTheme>
        </div>
      </div>
    </>
  );
}
