import React from "react";

export interface IconProperty{
    fill?: string | '#56676C';
}
const ListT = (props: IconProperty) => {
    return (
        <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1067 101.654C52.1067 104.921 53.4656 107.97 55.6284 110.133C57.7912 112.295 60.8399 113.654 64.1067 113.654C70.6975 113.654 76.1067 108.245 76.1067 101.654H84.1067C84.1067 112.663 75.1158 121.654 64.1067 121.654C58.6268 121.654 53.5688 119.387 49.9716 115.789C46.3744 112.192 44.1067 107.134 44.1067 101.654H52.1067Z" fill="#07ACDA"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M76.8818 13.8716C90.4282 19.0506 100.107 32.2033 100.107 47.519V62.9324C100.107 64.0783 100.366 65.7556 100.858 67.5478C101.343 69.3182 101.97 70.8918 102.541 71.8909L108.657 82.0477C113.486 90.097 109.626 100.583 100.728 103.528C76.9207 111.481 51.2332 111.48 27.4259 103.526L27.4111 103.521C22.9182 102.001 19.5489 98.8565 18.0424 94.788C16.5341 90.7148 17.0472 86.13 19.506 82.0435L25.631 71.8708L25.6351 71.864C26.2191 70.882 26.8588 69.3143 27.3525 67.5358C27.8477 65.7519 28.1068 64.0771 28.1068 62.9324V47.519C28.1068 32.15 37.7687 19.0113 51.342 13.8531C53.8352 9.36569 58.6226 6.3457 64.1068 6.3457C69.5985 6.3457 74.3915 9.37401 76.8818 13.8716ZM55.6452 20.8244C44.3265 24.4179 36.1068 35.023 36.1068 47.519V62.9324C36.1068 65.041 35.6725 67.4728 35.061 69.6756C34.4492 71.8796 33.5708 74.1754 32.5026 75.9673L32.4936 75.9823L26.3609 86.168C25.0336 88.3743 24.9596 90.4302 25.5446 92.0101C26.1308 93.5934 27.5326 95.1145 29.9687 95.9411C52.1305 103.344 76.0378 103.343 98.1992 95.9385L98.2113 95.9345C102.215 94.6109 104.005 89.8484 101.799 86.1666L95.6281 75.9183C94.6036 74.1424 93.7456 71.8625 93.1426 69.6636C92.5407 67.4692 92.1068 65.0398 92.1068 62.9324V47.519C92.1068 34.9663 83.7192 24.2639 72.2757 20.7437C70.9869 20.395 69.6871 20.1294 68.3721 19.97L68.3573 19.9681C63.8568 19.4056 59.5985 19.7182 55.6452 20.8244Z" fill="#82969C"/>
        </svg>
    );
};

export default ListT;
