import PageTitles from "../../components/ds/PageTitle";
import QuestionO from "../../assets/icons/Question-o";
import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import Button from "../../components/ds/Button";
import DepositO from "../../assets/icons/Deposit-o";
import WithdrawO from "../../assets/icons/Withdraw-o";
import { NavLink, useNavigate } from "react-router-dom";
import cartBg from "./wallet-cart-banner.png";
import AccountService from "../../services/account.service";
import moment from "moment";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import CloseB from "../../assets/icons/Close-b";
import Loading from "../Tickets/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import walletMock from "../../assets/images/mock/wallet.png";
import { isMobile, isDesktop, isTablet } from "react-device-detect";
import useAuth from "../../useAuth";

export default function Wallet() {
  const navigate = useNavigate();

  const text80Body = useTheme().colors.text80Body;
  const text50 = useTheme().colors.text50;
  const surface80Divider = useTheme().colors.surface80Divider;
  const primary20 = useTheme().colors.primary20;
  const text90 = useTheme().colors.text90;
  const text60 = useTheme().colors.text60;
  const primary50Main = useTheme().colors.primary50Main;

  const warning40 = useTheme().colors.warning40;
  const warning50Main = useTheme().colors.warning50Main;
  const success40 = useTheme().colors.success40;
  const success50Main = useTheme().colors.success50Main;
  const error40 = useTheme().colors.error40;
  const error50Main = useTheme().colors.error50Main;
  const surface60 = useTheme().colors.surface60;
  const [result, setResult] = useState<any>([]);
  const mode = useTheme().colors.mode;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [balance, setBalance] = useState("");
  const { token } = useAuth();

  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    onClick: "",
    show: false,
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "50%",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  const customMobileStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function getWalletTransactions() {
    AccountService.walletBalance(token).then((response) => {
      setBalance(response?.data?.amount);
    });
    AccountService.walletTransactions(page, token)
      .then((response: any) => {
        if (response.data.data.length && response.data.total > 15) {
          setHasMore(true);
        } else if (response.data.data.length && response.data.total <= 15) {
          setHasMore(false);
        } else {
          setHasMore(false);
        }
        setResult([...result, ...response?.data?.data]);
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }

  useEffect(() => {
    getWalletTransactions();
  }, [page]);

  function renderModal() {
    if (isMobile || isTablet) {
      return (
        <>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customMobileStyles}
          >
            <div className={"flex justify-between items-center mb-6"}>
              <div className={`text-2xl font-semibold text-[${text90}]`}>
                How to use wallet?
              </div>

              <button onClick={closeModal}>
                <CloseO fill={text60} />
              </button>
            </div>

            <div className={"w-full h-1/2"}>
              <img src={walletMock} alt={"walletMock"} />
            </div>

            <div className={`text-base text-[${text80Body}]`}>
              click <a className={`text-[${primary50Main}]`}>here </a> to see
              more
            </div>
          </Modal>
        </>
      );
    } else if (isDesktop) {
      return (
        <>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div className={"flex justify-between items-center mb-6"}>
              <div className={`text-2xl font-semibold text-[${text90}]`}>
                How to use wallet?
              </div>

              <button onClick={closeModal}>
                <CloseO fill={text60} />
              </button>
            </div>

            <div className={"w-full h-1/2"}>
              <img src={walletMock} alt={"walletMock"} />
            </div>

            <div className={`text-base text-[${text80Body}]`}>
              click <a className={`text-[${primary50Main}]`}>here </a> to see
              more
            </div>
          </Modal>
        </>
      );
    }
  }

  function Status(data: any) {
    if (data === "pending") {
      return (
        <div
          className={`bg-[${warning40}] text-[${warning50Main}] text-center rounded-2xl py-1 px-4`}
        >
          {data}
        </div>
      );
    } else if (data === "success") {
      return (
        <div
          className={`bg-[${success40}] text-[${success50Main}] text-center rounded-2xl py-1 px-4`}
        >
          {data}
        </div>
      );
    } else if (data === "failed") {
      return (
        <div
          className={`bg-[${error40}] text-[${error50Main}] text-center rounded-2xl py-1 px-4`}
        >
          {data}
        </div>
      );
    }
  }

  return (
    <>
      <div className={"mb-4"}>
        <PageTitles
          text={"Wallet"}
          onClickIcon={() => setIsOpen(true)}
          icon={<QuestionO fill={useTheme().colors.primary50Main} />}
        />
      </div>

      <div className={"lg:w-7/12"}>
        <div
          className={`w-full bg-contain bg-no-repeat bg-center  h-48 flex flex-col items-center justify-center rounded-3xl bg-[${
            useTheme().colors.primary20
          }] bg-[url(${cartBg})]`}
        >
          <div
            className={`font-medium text-lg mb-1 text-[${
              useTheme().colors.text90
            }]`}
          >
            Your Balance
          </div>

          <div
            className={`font-semibold text-[44px] text-[${
              useTheme().colors.text90
            }]`}
          >
            {balance}{" "}
            <sub
              className={`font-medium text-base text-[${
                useTheme().colors.text50
              }]`}
            >
              USDT
            </sub>
          </div>
        </div>

        <div className={"flex justify-between gap-3 my-5 lg:my-6"}>
          <NavLink to={"/wallet/deposit"} className={"w-1/2"}>
            <Button
              text={"Deposit"}
              className={
                "flex w-full  h-9 lg:h-12 items-center text-sm lg:text-base font-medium justify-center"
              }
              buttonStyleType={"BPrimary"}
              icon={<DepositO fill={useTheme().colors.text20} />}
            />
          </NavLink>
          <NavLink to={"/wallet/withdraw"} className={"w-1/2"}>
            <Button
              text={"Withdraw"}
              className={
                "flex w-full h-9 lg:h-12 items-center text-sm lg:text-base font-medium justify-center"
              }
              buttonStyleType={"BPrimary"}
              icon={<WithdrawO fill={useTheme().colors.text20} />}
            />
          </NavLink>
        </div>
        {result?.length > 0 && (
          <InfiniteScroll
            dataLength={result?.length} //This is important field to render the next data
            next={() => setPage(page + 1)}
            hasMore={hasMore}
            loader={<Loading />}
            height={"51.5vh"}
          >
            <div
              className={`table lg:rounded-3xl w-full lg:border lg:border-solid lg:border-[${surface80Divider}]`}
            >
              <div className="table-header-group">
                <div className="table-row">
                  <div
                    className={`lg:rounded-tl-3xl px-2 py-3 pl-6 text-left text-xs lg:text-base  font-semibold hidden lg:table-cell  text-[${text50}] lg:bg-[${surface60}]`}
                  >
                    Transaction Number
                  </div>
                  <div
                    className={`table-cell px-2 py-3  text-left text-xs lg:text-base  font-semibold  lg:hidden border-b border-solid text-[${text50}] lg:bg-[${surface60}] border-[${surface80Divider}]`}
                  >
                    Transaction Number/Date and Time
                  </div>
                  <div
                    className={`px-2 py-3 text-left text-xs lg:text-base font-semibold hidden lg:table-cell text-[${text50}] lg:bg-[${surface60}]`}
                  >
                    Date and Time
                  </div>
                  <div
                    className={`px-2 py-3 text-left text-xs lg:text-base font-semibold hidden lg:table-cell text-[${text50}] lg:bg-[${surface60}]`}
                  >
                    Status
                  </div>
                  <div
                    className={`table-cell px-2 lg:rounded-tr-3xl py-3 lg:mr-6 text-center text-xs lg:text-base font-semibold border-b lg:border-b-0 border-solid text-[${text50}] lg:bg-[${surface60}] border-[${surface80Divider}]`}
                  >
                    Amount
                  </div>
                </div>
              </div>
              <div className="table-row-group">
                {result &&
                  result.map((item: any) => {
                    return (
                      <div className={"table-row"} key={item.id}>
                        <div
                          className={`last:border-b-0 border-b border-solid px-2 py-2.5 lg:py-5 pl-6 text-left font-normal text-xs lg:text-base hidden lg:table-cell align-middle text-[${text80Body}] border-[${surface80Divider}]`}
                        >
                          {item.number}
                        </div>
                        <div
                          className={`table-cell border-b border-solid px-2 py-2.5 lg:py-5  text-left   lg:hidden  align-middle  border-[${surface80Divider}]`}
                        >
                          <div
                            className={`font-normal text-xs  text-[${text80Body}]`}
                          >
                            {item.number}
                          </div>
                          <div
                            className={`font-normal text-[10px] mt-[2px] text-[${text50}]`}
                          >
                            {moment(item.created_at).format("MMM Do YYYY")}
                          </div>
                        </div>
                        <div
                          className={`border-b border-solid px-2 py-2.5 lg:py-5 text-left font-normal text-xs lg:text-base hidden lg:table-cell align-middle text-[${text80Body}] border-[${surface80Divider}]`}
                        >
                          {moment(item.created_at).format("MMM Do YYYY , h:mm")}
                        </div>
                        <div
                          className={`border-b border-solid px-2 py-2.5 lg:py-5 text-left font-normal text-xs lg:text-base hidden lg:table-cell align-middle text-[${text80Body}] border-[${surface80Divider}]`}
                        >
                          {Status(item.status)}
                        </div>
                        <div
                          className={`table-cell border-b border-solid px-2 py-2.5 lg:py-5 lg:mr-6 text-center align-middle border-[${surface80Divider}]`}
                        >
                          <div
                            className={`py-1 px-4 rounded-2xl text-xs lg:text-base font-normal mx-auto text-[${text80Body}] bg-[${primary20}]`}
                          >
                            {item.type === 2
                              ? `-${item.amount}`
                              : `${item.amount}`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </InfiniteScroll>
        )}

        {result.length === 0 && (
          <div
            className={`lg:rounded-3xl w-full lg:border lg:border-solid lg:border-[${surface80Divider}]`}
          >
            <div className={`text-center py-5 text-[${text90}]`}>No Result</div>
          </div>
        )}
      </div>

      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}

      {renderModal()}
    </>
  );
}
