import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const ChartO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 12.25C16.09 12.25 15.75 11.91 15.75 11.5V11.2999L12.8199 14.23C12.6599 14.39 12.4499 14.47 12.2199 14.45C11.9999 14.43 11.7999 14.31 11.6699 14.12L10.5799 12.48L8.02994 15.03C7.87994 15.18 7.68994 15.25 7.49994 15.25C7.30994 15.25 7.11994 15.18 6.96994 15.03C6.67994 14.74 6.67994 14.26 6.96994 13.97L10.1699 10.77C10.3299 10.61 10.5399 10.53 10.7699 10.55C10.9899 10.57 11.1899 10.69 11.3199 10.88L12.4099 12.52L14.6799 10.25H14.5C14.09 10.25 13.75 9.91 13.75 9.5C13.75 9.09 14.09 8.75 14.5 8.75H16.5C16.91 8.75 17.25 9.09 17.25 9.5V11.5C17.25 11.91 16.91 12.25 16.5 12.25Z" fill={props.fill}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 22.75H15C20.43 22.75 22.75 20.43 22.75 15V9C22.75 3.57 20.43 1.25 15 1.25H9C3.57 1.25 1.25 3.57 1.25 9V15C1.25 20.43 3.57 22.75 9 22.75ZM2.75 9C2.75 4.39 4.39 2.75 9 2.75H15C19.61 2.75 21.25 4.39 21.25 9V15C21.25 19.61 19.61 21.25 15 21.25H9C4.39 21.25 2.75 19.61 2.75 15V9Z" fill={props.fill}/>
        </svg>
    );
};

export default ChartO;
