import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}
const FilterO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 10.75C7.91578 10.75 6.1727 9.23474 5.81653 7.25H2C1.59 7.25 1.25 6.91 1.25 6.5C1.25 6.09 1.59 5.75 2 5.75H5.81653C6.1727 3.76526 7.91578 2.25 10 2.25C12.34 2.25 14.25 4.16 14.25 6.5C14.25 8.84 12.34 10.75 10 10.75ZM10 3.75C8.48 3.75 7.25 4.98 7.25 6.5C7.25 8.02 8.48 9.25 10 9.25C11.52 9.25 12.75 8.02 12.75 6.5C12.75 4.98 11.52 3.75 10 3.75Z" fill={props.fill}/>
            <path d="M16 7.25H22C22.41 7.25 22.75 6.91 22.75 6.5C22.75 6.09 22.41 5.75 22 5.75H16C15.59 5.75 15.25 6.09 15.25 6.5C15.25 6.91 15.59 7.25 16 7.25Z" fill={props.fill}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1835 18.25H22C22.41 18.25 22.75 17.91 22.75 17.5C22.75 17.09 22.41 16.75 22 16.75H18.1835C17.8273 14.7653 16.0842 13.25 14 13.25C11.66 13.25 9.75 15.16 9.75 17.5C9.75 19.84 11.66 21.75 14 21.75C16.0842 21.75 17.8273 20.2347 18.1835 18.25ZM11.25 17.5C11.25 15.98 12.48 14.75 14 14.75C15.52 14.75 16.75 15.98 16.75 17.5C16.75 19.02 15.52 20.25 14 20.25C12.48 20.25 11.25 19.02 11.25 17.5Z" fill={props.fill}/>
            <path d="M8 18.25H2C1.59 18.25 1.25 17.91 1.25 17.5C1.25 17.09 1.59 16.75 2 16.75H8C8.41 16.75 8.75 17.09 8.75 17.5C8.75 17.91 8.41 18.25 8 18.25Z" fill={props.fill}/>
        </svg>
    );
};

export default FilterO;
