import PageTitles from "../../components/ds/PageTitle";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import { useTheme } from "styled-components";
import MoreO from "../../assets/icons/More-o";
import {
  createSearchParams,
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import WatchListService from "../../services/list.service";
import DashboardService from "../../services/dashboard.service";
import moment from "moment";
import noPhoto from "../../assets/images/no-photo.png";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import CloseB from "../../assets/icons/Close-b";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../Dashboard/Loading";
import CheckB from "../../assets/icons/Check-b";
import Button from "../../components/ds/Button";
import DropDown from "../../components/ds/DropDown";
import { useOutsideClick } from "../../components/ds/Hooks/useOutsideClick";
import listService from "../../services/list.service";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import SmallLoading from "../../assets/icons/Loading";
import { isMobile } from "react-device-detect";
import { Pattern } from "./listCartStyles";
import useAuth from "../../useAuth";
import { usePusher } from "../../hooks/usePusher";

export default function ListDetails() {
  const text60 = useTheme().colors.text60;
  let { id } = useParams();
  const [info, setInfo] = useState<any>("");
  const [result, setResult] = useState<any>([]);

  const text80Body = useTheme().colors.text80Body;
  const text50 = useTheme().colors.text50;
  const text90 = useTheme().colors.text90;
  const surface80Divider = useTheme().colors.surface80Divider;
  const surface50Background = useTheme().colors.surface50Background;
  const success40 = useTheme().colors.success40;
  const error40 = useTheme().colors.error40;
  const success50Main = useTheme().colors.success50Main;
  const error50Main = useTheme().colors.error50Main;
  const surface70 = useTheme().colors.surface70;
  const primary50Main = useTheme().colors.primary50Main;
  const mode = useTheme().colors.mode;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [action, setAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setAction(false);
  });
  const navigate = useNavigate();
  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    onClick: "",
    show: false,
  });

  function ViewChart(fino_chart: any) {
    window.open(fino_chart, "_blank");
  }

  const { token } = useAuth();

  useEffect(() => {
    WatchListService.getOne(id, token)
      .then((data: any) => {
        setInfo(data.data.entity);
        if (data.data.entity) {
          let pairsQuery = "";
          for (let item of data.data.entity?.pairs) {
            pairsQuery += `&pairs[]=${item.id}`;
          }
          DashboardService.getSignals(
            page,
            pairsQuery,
            data.data.entity.strategy,
            data.data.entity.time_frame,
            data.data.entity.position_side,
            token
          )
            .then((response: any) => {
              if (page === 1) setResult([...response?.data?.data]);
              else
                setResult((prevResult: any) => [
                  ...prevResult,
                  ...response?.data?.data,
                ]);

              if (response.data.data.length && response.data.total > 15) {
                setHasMore(true);
              } else if (
                response.data.data.length &&
                response.data.total <= 15
              ) {
                setHasMore(false);
              } else {
                setHasMore(false);
              }
            })
            .catch((err) => {
              setSnackbarConfig({
                text: err.response.data.message,
                icon: <CloseB fill={error50Main} />,
                btnText: "Ok",
                type: ToastTypes.Error,
                onClick: () =>
                  setSnackbarConfig({
                    show: false,
                  }),
                show: true,
              });
              setTimeout(() => {
                if (err.response.status === 401) {
                  navigate("/");
                }
                if (err.response.status === 403) {
                  if (
                    err?.response?.data?.message ===
                    "Your email address is not verified."
                  ) {
                    navigate("/settings/profile");
                  } else {
                    navigate("/plans");
                  }
                }
                if (err.response.status === 404) {
                  navigate("*");
                }
              }, 3000);
            });
        }
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }, [error50Main, id, navigate, page]);

  const handleSearch = () => {
    WatchListService.getOne(id, token)
      .then((data: any) => {
        setInfo(data.data.entity);
        if (data.data.entity) {
          DashboardService.getSignals(
            page,
            "",
            data.data.entity.strategy,
            data.data.entity.time_frame,
            data.data.entity.position_side
          )
            .then((response: any) => {
              setResult(response?.data);
            })
            .catch((err) => {
              setSnackbarConfig({
                text: err.response.data.message,
                icon: <CloseB fill={error50Main} />,
                btnText: "Ok",
                type: ToastTypes.Error,
                onClick: () =>
                  setSnackbarConfig({
                    show: false,
                  }),
                show: true,
              });
            });
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate("*");
        }
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
      });
  };

  function editAction() {
    let currentId: any;
    currentId = id;
    navigate({
      pathname: "/lists/add",
      search: createSearchParams({
        id: currentId,
      }).toString(),
    });
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function deleteAction(id: any) {
    setLoading(true);
    listService
      .delete(id, token)
      .then((response) => {
        if (response.status) {
          setSnackbarConfig({
            text: "List deleted",
            icon: <CheckB fill={success50Main} />,
            btnText: "Ok",
            type: ToastTypes.Error,
            onClick: () =>
              setSnackbarConfig({
                show: false,
              }),
            show: true,
          });
          setLoading(false);
          setIsOpen(false);
          navigate("/lists");
        }
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }


  const customMobileStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      maxWidth: "400px",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  function renderModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customMobileStyles}
      >
        <div className={"flex justify-between items-center mb-6"}>
          <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
            Delete list
          </div>

          <button onClick={closeModal}>
            <CloseO fill={text60} />
          </button>
        </div>

        <div
          className={"font-base font-normal my-6 " + `text-[${text80Body}]`}
        >
          Are you sure you want to delete {info.title} ?
          <br />
          This list contains these coins:
        </div>

        <Pattern className={"flexflex-wrap relative"}>
          {info?.pairs
            ?.filter((item: any, index: number) => index < 5)
            .map((filteredItem: any, i: number) => {
              return (
                <div style={{ left: i * -12 + "px", width: "15%", position: "relative" }}>
                  <img className={'w-full rounded-full'}
                    src={filteredItem.logo ? filteredItem.logo : noPhoto} />
                </div>
              );
            })}
          {info?.pairs?.length > 5 &&
            <div
              className={'relative flex items-center justify-center w-3/12 total rounded-[50px]'}
              style={{ left: 5 * -12 + "px" }}
            >
              {info?.pairs?.length - 5} more
            </div>
          }
        </Pattern>

        <div className={"flex justify-end mt-6 gap-2"}>
          <Button
            text={"Cancel"}
            onClick={() => setIsOpen(false)}
            className={`w-3/12 px-6 rounded-3xl border border-solid font-medium text-base h-12 text-[${text50}] border-[${surface80Divider}]`}
          />
          <Button
            buttonStyleType={"MWarning"}
            text={"Delete"}
            className={"w-3/12 flex h-12 justify-center items-center"}
            loading={loading ? <SmallLoading /> : ""}
            status={loading}
            onClick={() => deleteAction(id)}
          />
        </div>
      </Modal>
    );
  }

  function showMore(item: any, index: any) {
    if (
      (item.strategy.name === "pull-back" ||
        item.strategy.name === "pullback on traingle") &&
      !item.check_params
    ) {
      return (
        <>
          <div
            className={
              "table-row relative  " + `${item.collapse ? "h-[135px]" : ""}`
            }
            onClick={() => {
              let tempData = Object.assign([], result);
              if (tempData[index].collapse) {
                tempData[index].collapse = !tempData[index].collapse;
              } else {
                tempData[index].collapse = true;
              }
              // props.setData(tempData)
            }}
          >
            <div
              className={
                "table-cell align-top py-2.5 border-b border-solid " +
                `border-[${surface80Divider}]`
              }
            >
              <div className={"flex items-center"}>
                <div className={"w-8 min-w-max h-8 rounded-full  mr-1.5 "}>
                  <img
                    className={"h-full rounded-full"}
                    src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                  />
                </div>
                <div>
                  <div className={`text-xs font-semibold text-[${text80Body}]`}>
                    {item.pair.symbol}
                  </div>
                  <div className={`text-[10px] font-medium text-[${text50}]`}>
                    {item.exchange.name}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                "table-cell align-top py-2.5 border-b border-solid " +
                `border-[${surface80Divider}]`
              }
            >
              <div
                className={`font-normal text-xs text-center text-[${text50}]`}
              >
                {moment(item.candle_time).format("HH:mm")}
              </div>
              <div
                className={`px-3 text-xs w-fit mx-auto font-semibold rounded-[20px] ${item.position_side === "BUY"
                  ? `bg-[${success40}]`
                  : `bg-[${error40}]`
                  } ${item.position_side === "BUY"
                    ? `text-[${success50Main}]`
                    : `text-[${error50Main}]`
                  }`}
              >
                {item.position_side}
              </div>
            </div>
            <div
              className={
                "table-cell align-top py-2.5 border-b border-solid text-right " +
                `border-[${surface80Divider}]`
              }
            >
              <div className={`font-normal text-xs text-[${text80Body}]`}>
                {item.strategy.name}
              </div>
              <div
                className={`px-3 mr-0 pl-auto text-xs w-fit font-semibold rounded-[20px] bg-[${surface70}] text-[${text50}]`}
              >
                {item.time_frame}
              </div>
            </div>

            {item.collapse && (
              <div
                className={`w-full absolute left-0 top-[79px] ${item.collapse ? "block" : "hidden"
                  }`}
              >
                {((item.strategy.name === "pull-back" ||
                  item.strategy.name === "pullback on traingle") &&
                  !item.check_params) ||
                  ((item.strategy.name === "pull-back" ||
                    item.strategy.name === "pullback on traingle") &&
                    item.check_params) ? (
                  ""
                ) : (
                  <>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.macd_hist_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        Macd bar divergence
                      </div>
                    </div>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.sig_line_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        Macd hidden divergence
                      </div>
                    </div>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.rsi_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        RSI Hidden Divergence
                      </div>
                    </div>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.stoch_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        Stochastic Divergence
                      </div>
                    </div>
                  </>
                )}
                <Button
                  buttonStyleType={"BPrimary"}
                  text={"View chart"}
                  onClick={() => {
                    ViewChart(item.fino_chart);
                  }}
                  className={"w-full text-xs h-9 mt-2"}
                />
              </div>
            )}
          </div>
        </>
      );
    } else if (
      (item.strategy.name === "pull-back" ||
        item.strategy.name === "pullback on traingle") &&
      item.check_params
    ) {
      return (
        <>
          <div
            className={
              "table-row relative  " + `${item.collapse ? "h-[135px]" : ""}`
            }
            onClick={() => {
              let tempData = Object.assign([], result);
              if (tempData[index].collapse) {
                tempData[index].collapse = !tempData[index].collapse;
              } else {
                tempData[index].collapse = true;
              }
              // props.setData(tempData)
            }}
          >
            <div
              className={
                "table-cell align-top py-2.5 border-b border-solid " +
                `border-[${surface80Divider}]`
              }
            >
              <div className={"flex items-center"}>
                <div className={"w-8 min-w-max h-8 rounded-full  mr-1.5 "}>
                  <img
                    className={"h-full rounded-full"}
                    src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                  />
                </div>
                <div>
                  <div className={`text-xs font-semibold text-[${text80Body}]`}>
                    {item.pair.symbol}
                  </div>
                  <div className={`text-[10px] font-medium text-[${text50}]`}>
                    {item.exchange.name}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                "table-cell align-top py-2.5 border-b border-solid " +
                `border-[${surface80Divider}]`
              }
            >
              <div
                className={`font-normal text-xs text-center text-[${text50}]`}
              >
                {moment(item.candle_time).format("HH:mm")}
              </div>
              <div
                className={`px-3 text-xs w-fit mx-auto font-semibold rounded-[20px] ${item.position_side === "BUY"
                  ? `bg-[${success40}]`
                  : `bg-[${error40}]`
                  } ${item.position_side === "BUY"
                    ? `text-[${success50Main}]`
                    : `text-[${error50Main}]`
                  }`}
              >
                {item.position_side}
              </div>
            </div>
            <div
              className={
                "table-cell align-top py-2.5 border-b border-solid text-right " +
                `border-[${surface80Divider}]`
              }
            >
              <div className={`font-normal text-xs text-[${text80Body}]`}>
                {item.strategy.name}
              </div>
              <div
                className={`px-3 mr-0 pl-auto text-xs w-fit font-semibold rounded-[20px] bg-[${surface70}] text-[${text50}]`}
              >
                {item.time_frame}
              </div>
            </div>

            {item.collapse && (
              <div
                className={`w-full absolute left-0 top-[79px] ${item.collapse ? "block" : "hidden"
                  }`}
              >
                {((item.strategy.name === "pull-back" ||
                  item.strategy.name === "pullback on traingle") &&
                  !item.check_params) ||
                  ((item.strategy.name === "pull-back" ||
                    item.strategy.name === "pullback on traingle") &&
                    item.check_params) ? (
                  ""
                ) : (
                  <>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.macd_hist_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        Macd bar divergence
                      </div>
                    </div>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.sig_line_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        Macd hidden divergence
                      </div>
                    </div>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.rsi_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        RSI Hidden Divergence
                      </div>
                    </div>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.stoch_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        Stochastic Divergence
                      </div>
                    </div>
                  </>
                )}
                <Button
                  buttonStyleType={"BPrimary"}
                  text={"View chart"}
                  onClick={() => {
                    ViewChart(item.fino_chart);
                  }}
                  className={"w-full text-xs h-9 mt-2"}
                />
              </div>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className={
              "table-row relative  " + `${item.collapse ? "h-[263px]" : ""}`
            }
            onClick={() => {
              let tempData = Object.assign([], result);
              if (tempData[index].collapse) {
                tempData[index].collapse = !tempData[index].collapse;
              } else {
                tempData[index].collapse = true;
              }
              // props.setData(tempData)
            }}
          >
            <div
              className={
                "table-cell align-top py-2.5 border-b border-solid " +
                `border-[${surface80Divider}]`
              }
            >
              <div className={"flex items-center"}>
                <div className={"w-8 min-w-max h-8 rounded-full  mr-1.5 "}>
                  <img
                    className={"h-full rounded-full"}
                    src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                  />
                </div>
                <div>
                  <div className={`text-xs font-semibold text-[${text80Body}]`}>
                    {item.pair.symbol}
                  </div>
                  <div className={`text-[10px] font-medium text-[${text50}]`}>
                    {item.exchange.name}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                "table-cell align-top py-2.5 border-b border-solid " +
                `border-[${surface80Divider}]`
              }
            >
              <div
                className={`font-normal text-xs text-center text-[${text50}]`}
              >
                {moment(item.candle_time).format("HH:mm")}
              </div>
              <div
                className={`px-3 text-xs w-fit mx-auto font-semibold rounded-[20px] ${item.position_side === "BUY"
                  ? `bg-[${success40}]`
                  : `bg-[${error40}]`
                  } ${item.position_side === "BUY"
                    ? `text-[${success50Main}]`
                    : `text-[${error50Main}]`
                  }`}
              >
                {item.position_side}
              </div>
            </div>
            <div
              className={
                "table-cell align-top py-2.5 border-b border-solid text-right " +
                `border-[${surface80Divider}]`
              }
            >
              <div className={`font-normal text-xs text-[${text80Body}]`}>
                {item.strategy.name}
              </div>
              <div
                className={`px-3 mr-0 pl-auto text-xs w-fit font-semibold rounded-[20px] bg-[${surface70}] text-[${text50}]`}
              >
                {item.time_frame}
              </div>
            </div>

            {item.collapse && (
              <div
                className={`w-full absolute left-0 top-[79px] ${item.collapse ? "block" : "hidden"
                  }`}
              >
                {((item.strategy.name === "pull-back" ||
                  item.strategy.name === "pullback on traingle") &&
                  !item.check_params) ||
                  ((item.strategy.name === "pull-back" ||
                    item.strategy.name === "pullback on traingle") &&
                    item.check_params) ? (
                  ""
                ) : (
                  <>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.macd_hist_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        Macd bar divergence
                      </div>
                    </div>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.sig_line_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        Macd hidden divergence
                      </div>
                    </div>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.rsi_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        RSI Hidden Divergence
                      </div>
                    </div>
                    <div className={"flex items-center mb-2"}>
                      {item?.params?.stoch_div ? (
                        <CheckB fill={success50Main} />
                      ) : (
                        <CloseB fill={error50Main} />
                      )}
                      <div
                        className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                      >
                        Stochastic Divergence
                      </div>
                    </div>
                  </>
                )}
                <Button
                  buttonStyleType={"BPrimary"}
                  text={"View chart"}
                  onClick={() => {
                    ViewChart(item.fino_chart);
                  }}
                  className={"w-full text-xs h-9 mt-2"}
                />
              </div>
            )}
          </div>
        </>
      );
    }
  }
  return (
    <div>
      <div className={"flex  justify-between"}>
        <div>
          <NavLink to={"/lists"}>
            <PageTitles
              arrow={<ArrowLeft2Outline fill={useTheme().colors.text50} />}
              text={info.title}
            />
          </NavLink>
          <div
            className={
              "font-normal text-xs xl:text-base tracking-tight mb-4 xl:mb-0 " +
              `text-[${useTheme().colors.text50}]`
            }
          >
            {info.description}
          </div>
        </div>
        <div>
          <div ref={wrapperRef}>
            <div
              onClick={(event) => {
                event.preventDefault();
                setAction(!action);
              }}
            >
              <MoreO fill={useTheme().colors.text60} />
            </div>
            {action && (
              <DropDown
                onEdit={() => editAction()}
                onDelete={() => setIsOpen(true)}
              />
            )}
          </div>
        </div>
      </div>
      <InfiniteScroll
        dataLength={result?.length} //This is important field to render the next data
        next={() => setPage(page + 1)}
        hasMore={hasMore}
        loader={<Loading />}
        height={"77.5vh"}
        className={"hidden 2xl:block"}
      >
        <div className="w-full table">
          <div className="table-header-group">
            <div className={"table-row"}>
              <div
                className={`table-cell py-4 px-5 text-left font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Pair
              </div>
              <div
                className={`table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Position
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Signal Candle
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Exchange
              </div>
              <div
                className={`table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Strategy
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Timeframe
              </div>
              <div
                className={`table-cell py-4 px-5 text-right font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Chart
              </div>
            </div>
          </div>
          <div className="table-row-group">
            {result?.map((item: any) => {
              return (
                <div
                  className="table-row"
                  onClick={() => {
                    ViewChart(item.fino_chart);
                  }}
                >
                  <div
                    className={`hover:cursor-pointer table-cell dataRow  relative align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex  items-center"}>
                      <div
                        className={"w-14 min-w-max  h-14 rounded-full  mr-4"}
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                        />
                      </div>

                      <div>
                        <div
                          className={`font-semibold text-base tracking-tight text-[${text80Body}]`}
                        >
                          {item.pair.symbol}
                        </div>
                        <div
                          className={`font-medium text-sm tracking-tight text-[${text50}]`}
                        >
                          {item.pair.description}
                        </div>
                      </div>
                    </div>

                    {((item.strategy.name === "pull-back" ||
                      item.strategy.name === "pullback on traingle") &&
                      !item.check_params) ||
                      ((item.strategy.name === "pull-back" ||
                        item.strategy.name === "pullback on traingle") &&
                        item.check_params) ? (
                      ""
                    ) : (
                      <div
                        className={`details p-4 border border-solid rounded-3xl absolute left-0 top-0 z-20 border-[${surface80Divider}] bg-[${surface50Background}]`}
                      >
                        {/*<img src={'https://picsum.photos/200/300'}*/}
                        {/*     className={'w-72 h-44 rounded-2xl mb-[18px]'}/>*/}

                        <div className={"flex justify-between items-center"}>
                          <div className={"flex items-center"}>
                            <div
                              className={
                                "w-10 min-w-max h-10 rounded-full  mr-2"
                              }
                            >
                              <img
                                className={"h-full w-full rounded-full"}
                                src={
                                  item?.pair?.logo ? item?.pair?.logo : noPhoto
                                }
                              />
                            </div>
                            <div
                              className={`text-base font-semibold text-[${text80Body}]`}
                            >
                              {item.pair.symbol}
                            </div>
                          </div>
                          <div
                            className={`py-1 px-5 pl-3 text-base font-semibold rounded-[24px] ${item.position_side === "BUY"
                              ? `bg-[${success40}]`
                              : `bg-[${error40}]`
                              } ${item.position_side === "BUY"
                                ? `text-[${success50Main}]`
                                : `text-[${error50Main}]`
                              }`}
                          >
                            {item.position_side}
                          </div>
                        </div>

                        <div
                          className={`my-[18px] h-px w-full bg-[${surface80Divider}]`}
                        />

                        <>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.macd_hist_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              Macd bar divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.sig_line_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              Macd hidden divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.rsi_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              RSI Hidden Divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.stoch_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              Stochastic Divergence
                            </div>
                          </div>
                        </>

                        <Button
                          buttonStyleType={"BPrimary"}
                          text={"View chart"}
                          onClick={() => {
                            ViewChart(item.fino_chart);
                          }}
                          className={"w-full h-12 mt-4"}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`py-2 px-5 m-auto w-fit rounded-[20px] font-semibold text-base tracking-tight ${item.position_side === "BUY"
                        ? `bg-[${success40}]`
                        : `bg-[${error40}]`
                        } ${item.position_side === "BUY"
                          ? `text-[${success50Main}]`
                          : `text-[${error50Main}]`
                        }`}
                    >
                      {item.position_side}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-base tracking-tight text-[${text50}]`}
                    >
                      {moment(item.candle_time).format("MMM Do YYYY , HH:mm")}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex items-center justify-center"}>
                      <div
                        className={
                          "h-6 min-w-max w-6  rounded-full mr-2 p-1  bg-[#F3BA2F]"
                        }
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item.exchange.full_logo}
                          alt={"exchange icon"}
                        />
                      </div>
                      <div
                        className={`text-center font-normal text-base tracking-tight text-[${text90}]`}
                      >
                        {item.exchange.full_name}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-base tracking-tight text-[${text90}]`}
                    >
                      {item.strategy.name}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center p-2 font-normal rounded-[18px] text-base w-16 h-9 m-auto tracking-tight text-[${text50}]  bg-[${surface70}]`}
                    >
                      {item.time_frame}
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`hover:cursor-pointer text-right font-semibold text-base tracking-tight text-[${primary50Main}]`}
                      onClick={() => {
                        ViewChart(item.fino_chart);
                      }}
                    >
                      View chart
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </InfiniteScroll>

      <InfiniteScroll
        dataLength={result?.length} //This is important field to render the next data
        next={() => setPage(page + 1)}
        hasMore={hasMore}
        loader={<Loading />}
        height={"77vh"}
        className={"hidden 2xl:hidden xl:block"}
      >
        <div className="w-full table">
          <div className="table-header-group">
            <div className={"table-row"}>
              <div
                className={`table-cell py-4 px-5 text-left font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Pair
              </div>
              <div
                className={`table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Position
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Signal Candle
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Exchange
              </div>
              <div
                className={`table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Strategy
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-5 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Timeframe
              </div>
              <div
                className={`table-cell py-4 px-5 text-right font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Chart
              </div>
            </div>
          </div>
          <div className="table-row-group">
            {result?.map((item: any) => {
              return (
                <div
                  className="table-row"
                  onClick={() => {
                    ViewChart(item.fino_chart);
                  }}
                >
                  <div
                    className={`hover:cursor-pointer table-cell dataRow  relative align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex  items-center"}>
                      <div
                        className={"w-14 min-w-max  h-14 rounded-full  mr-4"}
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                        />
                      </div>

                      <div>
                        <div
                          className={`font-semibold text-base tracking-tight text-[${text80Body}]`}
                        >
                          {item.pair.symbol}
                        </div>
                        <div
                          className={`font-medium text-sm tracking-tight text-[${text50}]`}
                        >
                          {item.pair.description}
                        </div>
                      </div>
                    </div>

                    {((item.strategy.name === "pull-back" ||
                      item.strategy.name === "pullback on traingle") &&
                      !item.check_params) ||
                      ((item.strategy.name === "pull-back" ||
                        item.strategy.name === "pullback on traingle") &&
                        item.check_params) ? (
                      ""
                    ) : (
                      <div
                        className={`details p-4 border border-solid rounded-3xl absolute left-0 top-0 z-20 border-[${surface80Divider}] bg-[${surface50Background}]`}
                      >
                        {/*<img src={'https://picsum.photos/200/300'}*/}
                        {/*     className={'w-72 h-44 rounded-2xl mb-[18px]'}/>*/}

                        <div className={"flex justify-between items-center"}>
                          <div className={"flex items-center"}>
                            <div
                              className={
                                "w-10 min-w-max h-10 rounded-full  mr-2"
                              }
                            >
                              <img
                                className={"h-full w-full rounded-full"}
                                src={
                                  item?.pair?.logo ? item?.pair?.logo : noPhoto
                                }
                              />
                            </div>
                            <div
                              className={`text-base font-semibold text-[${text80Body}]`}
                            >
                              {item.pair.symbol}
                            </div>
                          </div>
                          <div
                            className={`py-1 px-5 pl-3 text-base font-semibold rounded-[24px] ${item.position_side === "BUY"
                              ? `bg-[${success40}]`
                              : `bg-[${error40}]`
                              } ${item.position_side === "BUY"
                                ? `text-[${success50Main}]`
                                : `text-[${error50Main}]`
                              }`}
                          >
                            {item.position_side}
                          </div>
                        </div>

                        <div
                          className={`my-[18px] h-px w-full bg-[${surface80Divider}]`}
                        />

                        <>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.macd_hist_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              Macd bar divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.sig_line_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              Macd hidden divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.rsi_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              RSI Hidden Divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.stoch_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              Stochastic Divergence
                            </div>
                          </div>
                        </>

                        <Button
                          buttonStyleType={"BPrimary"}
                          text={"View chart"}
                          onClick={() => {
                            ViewChart(item.fino_chart);
                          }}
                          className={"w-full h-12 mt-4"}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`py-2 px-5 m-auto w-fit rounded-[20px] font-semibold text-base tracking-tight ${item.position_side === "BUY"
                        ? `bg-[${success40}]`
                        : `bg-[${error40}]`
                        } ${item.position_side === "BUY"
                          ? `text-[${success50Main}]`
                          : `text-[${error50Main}]`
                        }`}
                    >
                      {item.position_side}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-base tracking-tight text-[${text50}]`}
                    >
                      {moment(item.candle_time).format("MMM Do YYYY , HH:mm")}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex items-center justify-center"}>
                      <div
                        className={
                          "h-6 min-w-max w-6  rounded-full mr-2 p-1  bg-[#F3BA2F]"
                        }
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item.exchange.full_logo}
                          alt={"exchange icon"}
                        />
                      </div>
                      <div
                        className={`text-center font-normal text-base tracking-tight text-[${text90}]`}
                      >
                        {item.exchange.full_name}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-base tracking-tight text-[${text90}]`}
                    >
                      {item.strategy.name}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center p-2 font-normal rounded-[18px] text-base w-16 h-9 m-auto tracking-tight text-[${text50}]  bg-[${surface70}]`}
                    >
                      {item.time_frame}
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-5 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`hover:cursor-pointer text-right font-semibold text-base tracking-tight text-[${primary50Main}]`}
                      onClick={() => {
                        ViewChart(item.fino_chart);
                      }}
                    >
                      View chart
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </InfiniteScroll>

      <InfiniteScroll
        dataLength={result?.length} //This is important field to render the next data
        next={() => setPage(page + 1)}
        hasMore={hasMore}
        loader={<Loading />}
        height={"77.5vh"}
        className={"lg:hidden xl:hidden 2xl:hidden"}
      >
        <div className={"w-full table"}>
          <div className={"table-header-group"}>
            <div className={"table-row"}>
              <div
                className={`table-cell py-2  text-left font-semibold text-xs tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Pair/Exchange
              </div>
              <div
                className={`table-cell py-2  text-center font-semibold text-xs tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Position/Candle
              </div>
              <div
                className={`table-cell py-2  text-right font-semibold text-xs tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Strategy
              </div>
            </div>
          </div>

          <div className="table-row-group">
            {result?.map((item: any, index: number) => {
              return <>{showMore(item, index)}</>;
            })}
          </div>
        </div>
      </InfiniteScroll>

      {renderModal()}

      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}
    </div>
  );
}
