import http from './http-common'

let token = localStorage.getItem('token');

class BaseService {
    getPairs(customToken?:string){
        return http.get<any>('pairs',{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    getPairsExchange(id:any,customToken?:string){
        return http.get<any>(`pairs?exchange_id=${id}`,{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    getStrategies(customToken?:string){
        return http.get<any>('strategies',{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    getExchanges(customToken?:string){
        return http.get<any>('exchanges',{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    getTimeFrame(customToken?:string){
        return http.get<any>('time-frame',{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    getDepartments(customToken?:string){
        return http.get<any>('departments',{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }
}

export default new BaseService()
