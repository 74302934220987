import { useTheme } from "styled-components";
import CloseO from "../../../assets/icons/Close-o";

export interface Tag_Options {
  icon?: any;
  text?: any;
  onClick?: () => void;
}
export default function Tag(props: Tag_Options) {
  return (
    <div
      className={
        "cursor-default px-2 h-10 rounded-3xl mr-2 flex items-center justify-between mb-2 " +
        `bg-[${useTheme().colors.surface70}]`
      }
    >
      <div className={"h-6 w-6 rounded-full  mr-2"}>
        <img className={"h-full w-full rounded-full"} src={props.icon} />
      </div>
      <div
        className={
          "text-base font-normal mr-2 " +
          `text-[${useTheme().colors.text80Body}]`
        }
      >
        {props.text}
      </div>

      <div className={"hover:cursor-pointer"} onClick={props.onClick}>
        <CloseO fill={useTheme().colors.text80Body} />
      </div>
    </div>
  );
}
