import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const SettingB = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.9401 5.42L13.7701 2.43C12.7801 1.86 11.2301 1.86 10.2401 2.43L5.02008 5.44C2.95008 6.84 2.83008 7.05 2.83008 9.28V14.71C2.83008 16.94 2.95008 17.16 5.06008 18.58L10.2301 21.57C10.7301 21.86 11.3701 22 12.0001 22C12.6301 22 13.2701 21.86 13.7601 21.57L18.9801 18.56C21.0501 17.16 21.1701 16.95 21.1701 14.72V9.28C21.1701 7.05 21.0501 6.84 18.9401 5.42ZM12.0001 15.25C10.2101 15.25 8.75008 13.79 8.75008 12C8.75008 10.21 10.2101 8.75 12.0001 8.75C13.7901 8.75 15.2501 10.21 15.2501 12C15.2501 13.79 13.7901 15.25 12.0001 15.25Z" fill={props.fill}/>
        </svg>
    );
};

export default SettingB;
