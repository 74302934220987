import { useTheme } from "styled-components";
import React, { useState } from "react";
import AlarmService from "../../services/alarm.service";
import CheckO from "../../assets/icons/Check-o";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import ListCart from "./listCart";
import { createSearchParams, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import { Pattern } from "../Lists/listCartStyles";
import noPhoto from "../../assets/images/no-photo.png";
import Button from "../../components/ds/Button";
import CloseB from "../../assets/icons/Close-b";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "./Loading";
import SmallLoading from "../../assets/icons/Loading";
import CheckB from "../../assets/icons/Check-b";
import { useMedia } from "react-use";
import { isMobile } from "react-device-detect";
import useAuth from "../../useAuth";

export default function DataList(props: any) {
  const text50 = useTheme().colors.text50;
  const text60 = useTheme().colors.text60;
  const text90 = useTheme().colors.text90;
  const text80Body = useTheme().colors.text80Body;
  const surface80Divider = useTheme().colors.surface80Divider;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [currentUser, setCurrentUser] = React.useState<any>("");

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const success50Main = useTheme().colors.success50Main;
  const error50Main = useTheme().colors.error50Main;
  const mode = useTheme().colors.mode;
  const { token } = useAuth();

  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    show: false,
  });

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function editAction(id: any, mode: any) {
    navigate({
      pathname: "/alarms/add",
      search: createSearchParams({
        id: id,
      }).toString(),
    });
  }

  function deleteAction(id: any) {
    setLoading(true);
    AlarmService.delete(id, token)
      .then((response) => {
        if (response.status) {
          props.onDelete(id);
          setSnackbarConfig({
            text: "Alarm deleted",
            icon: <CheckB fill={success50Main} />,
            btnText: "Ok",
            type: ToastTypes.Success,
            onClick: () =>
              setSnackbarConfig({
                show: false,
              }),
            show: true,
          });
          setLoading(false);
          setIsOpen(false);
        }
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }

  const customMobileStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      maxWidth: "400px",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  function renderModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customMobileStyles}
      >
        <div className={"flex justify-between items-center mb-6"}>
          <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
            Delete list
          </div>

          <button onClick={closeModal}>
            <CloseO fill={text60} />
          </button>
        </div>

        <div
          className={"font-base font-normal my-6 " + `text-[${text80Body}]`}
        >
          Are you sure you want to delete {currentUser.name}?
          <br />
          This alarms contains these coins:
        </div>

        <Pattern className={"flex flex-wrap relative "}>
          {currentUser?.pairs
            ?.filter((item: any, index: number) => index < 5)
            .map((filteredItem: any, i: number) => {
              return (

                <div style={{ left: i * -12 + "px", width: "15%", position: "relative" }}>
                  <img className={'w-full rounded-full'}
                    src={filteredItem.logo ? filteredItem.logo : noPhoto} />
                </div>
              );
            })}

          {currentUser?.pairs?.length > 5 &&
            <div
              className={'flex items-center justify-center w-3/12 total rounded-[50px]'}
              style={{ left: 5 * -12 + "px" }}
              >
              {currentUser?.pairs?.length - 5} more
            </div>
          }
        </Pattern>

        <div className={"flex justify-end mt-6 gap-2"}>
          <Button
            text={"Cancel"}
            onClick={() => setIsOpen(false)}
            className={`w-6/12 px-6 rounded-3xl border border-solid font-medium text-base h-12 text-[${text50}] border-[${surface80Divider}]`}
          />
          <Button
            buttonStyleType={"MWarning"}
            text={"Delete"}
            status={loading}
            loading={loading ? <SmallLoading /> : ""}
            className={"w-6/12 flex h-12 justify-center items-center"}
            onClick={() => deleteAction(currentUser.id)}
          />
        </div>
      </Modal>
    );
  }
  return (
    <>
      <InfiniteScroll
        dataLength={props?.data?.length} //This is important field to render the next data
        next={() => props.goNext()}
        hasMore={props.hasMore}
        loader={<Loading />}
        height={"69vh"}
      >
        <div className={"table rounded-3xl w-full  mt-6"}>
          <div className="lg:table-header-group hidden">
            <div className="table-row">
              <div
                className={`table-cell rounded-tl-3xl py-3 pl-6 text-left text-base  font-semibold text-[${useTheme().colors.text50
                  }]`}
              >
                #
              </div>
              <div
                className={`table-cell py-3 text-left text-base font-semibold text-[${useTheme().colors.text50
                  }]`}
              >
                Name
              </div>
              <div
                className={`table-cell  py-3 mr-6 text-left text-base font-semibold text-[${useTheme().colors.text50
                  }]`}
              >
                Coins
              </div>
              <div className={"table-cell py-3"}></div>
              <div className={"table-cell rounded-tr-3xl py-3 mr-6"}></div>
            </div>
          </div>
          <div className="table-row-group">
            {props?.data.map((item: any, index: number) => {
              return (
                <ListCart
                  item={item}
                  index={index}
                  editAction={() => {
                    editAction(item.id, "edit");
                  }}
                  deleteAction={() => {
                    // deleteAction(item.id)
                    setCurrentUser(item);
                    setIsOpen(true);
                  }}
                  changeToggle={(active: boolean) => {
                    let data = props?.data;
                    data[index].is_active = !active;
                    props.editData(data);
                  }}
                />
              );
            })}
          </div>
          <div className={"h-4"}></div>
        </div>
      </InfiniteScroll>

      {renderModal()}
      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}
    </>
  );
}
