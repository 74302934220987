import http from './http-common'

let token = localStorage.getItem('token');

class RegisterService {
    googleAuth(customToken?:string) {
        return http.get<any>('auth/google-login', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    login(data: any) {
        return http.post<any>('auth/login', data, {
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    }

    login2fa(data: any) {
        return http.post<any>('auth/login/2fa', data, {
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    }

    register(data: any) {
        return http.post<any>('auth/register', data, {
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    }

    forgetPassword(data: any) {
        return http.post<any>('auth/password/forget', data, {
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    }

    resetPassword(data: any) {
        return http.post<any>('auth/password/reset', data, {
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    }

    googleLogin(data: any) {
        return http.post<any>('auth/password/reset', data, {
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    }

    verifyEmail(id: any, token: any, time: any, signature: any) {
        return http.get<any>('account/email/verify/'+ id + '/' + token + '?expires=' + time + '&signature=' + signature, {
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    }
}

export default new RegisterService()
