import React from "react";
import {
  CheckAction,
  Item_Type,
} from "../../../components/ds/Checkboxes/types";
import TextField from "../../../components/ds/Textfield";
import { useTheme } from "styled-components";
import EyeO from "../../../assets/icons/Eye-o";
import EyeSlashO from "../../../assets/icons/Eye-slash-o";
import Button from "../../../components/ds/Button";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import AuthService from "../../../services/auth.service";
import Snackbar, { ToastTypes } from "../../../components/ds/Snackbar";
import CloseB from "../../../assets/icons/Close-b";
import { useNavigate } from "react-router-dom";
import AccountService from "../../../services/account.service";
import googleLogo from "../../../assets/images/google-icon.svg";
import useAuth from "../../../useAuth";
import SignInSvg from "../../../assets/svg-files/sign_in.svg";


export default function SignIn() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { token } = useAuth();

  React.useEffect(() => {
    AccountService.profile(localStorage.getItem("token"))
      .then((response) => {
        login(localStorage.getItem("token")).then(() => {
          navigate("/");
        });
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          setSnackbarConfig({
            text: err.response.data.message,
            icon: <CloseB fill={error50Main} />,
            btnText: "Ok",
            type: ToastTypes.Error,
            onClick: () =>
              setSnackbarConfig({
                show: false,
              }),
            show: true,
          });
        }
        setTimeout(() => {
          setSnackbarConfig({
            show: false,
          });
        }, 1000);
      });
  }, []);

  const [showPass, setShowPass] = React.useState(false);
  const [step, setStep] = React.useState("first");
  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    show: false,
  });
  const text50 = useTheme().colors.text50;
  const primary50Main = useTheme().colors.primary50Main;
  const error50Main = useTheme().colors.error50Main;
  const mode = useTheme().colors.mode;

  function google() {
    window.open("https://core-api.finomate.io/api/auth/google-login");
    // AuthService.googleAuth().then((response)=>{
    // })
    // test change
  }

  return (
    <div
      className={
        "flex xl:flex-row flex-col overflow-auto h-screen " +
        `bg-[${useTheme().colors.surface50Background}]`
      }
    >
      <div
        className={
          "xl:w-4/12 w-full mb-[20px] items-center xl:p-28 pt-16 px-28 relative " +
          `xl:bg-[${useTheme().colors.surface60}]`
        }
      >
        <img src={SignInSvg} alt=""/>

        <div
          className={
            "font-normal text-base hidden xl:block absolute text-center right-0 left-0 bottom-8 " +
            `text-[${useTheme().colors.text30}]`
          }
        >
          ©2022{" "}
          <span className={`text-[${useTheme().colors.text50}]`}>Finomate</span>
        </div>
      </div>
      <div
        className={
          "xl:w-8/12 w-full h-full 2xl:px-80 xl:px-56 2xl:pt-40 xl:pt-6 p-4"
        }
      >
        <div
          className={
            "font-normal xl:text-4xl text-3xl mb-2 " +
            `text-[${useTheme().colors.text90}]`
          }
        >
          Welcome Back
        </div>

        <div
          className={
            "font-normal xl:text-base text-xs mb-8 " +
            `text-[${useTheme().colors.text50}]`
          }
        >
          We’re happy to see you again . To use your account, you should log in
          first.
        </div>

        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors: any = {};

            if (!values.password) {
              errors.password = "Required";
            }
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              // alert(JSON.stringify(values, null, 2));
              const params = new URLSearchParams();
              params.append("email", values.email);
              params.append("password", values.password);

              AuthService.login(params)
                .then(({ data }) => {
                  if (data.token_type === "2fa") {
                    navigate("/google-2fa?token=" + data.token);
                  } else {
                    if (data) {
                      login(data.token).then(() => {
                        localStorage.setItem("token", data.token);
                        localStorage.setItem("userEmail", data.user.email);
                        localStorage.setItem(
                          "userFirst_name",
                          data.user.first_name
                        );
                        localStorage.setItem(
                          "userLast_name",
                          data.user.last_name
                        );
                        localStorage.setItem("userRole", data.user.role);
                        navigate("/");
                      });

                      // navigate('/dashboard')
                      // window.location.reload()
                    }
                  }
                })
                .catch((err) => {
                  // Do something
                  setSnackbarConfig({
                    text: err.response.data.message,
                    icon: <CloseB fill={error50Main} />,
                    btnText: "Ok",
                    type: ToastTypes.Error,
                    onClick: () =>
                      setSnackbarConfig({
                        show: false,
                      }),
                    show: true,
                  });

                  setTimeout(() => {
                    if (err.response.status === 401) {
                      navigate("/");
                    }
                    if (err.response.status === 403) {
                      if (
                        err?.response?.data?.message ===
                        "Your email address is not verified."
                      ) {
                        navigate("/settings/profile");
                      } else {
                        navigate("/plans");
                      }
                    }
                    if (err.response.status === 404) {
                      navigate("*");
                    }
                  }, 3000);
                });
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                type={"email"}
                name={"email"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                size={"Big"}
                label={"Email"}
                parentClassName={"xl:mb-6 mb-4"}
              />
              <TextField
                type={showPass ? "text" : "password"}
                name={"password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                size={"Big"}
                label={"Password"}
                parentClassName={"xl:mb-6 mb-4"}
                hint={"Min 6 characters"}
                suffixOnClick={() => setShowPass(!showPass)}
                suffixIcon={
                  showPass ? (
                    <EyeSlashO fill={text50} />
                  ) : (
                    <EyeO fill={text50} />
                  )
                }
              />

              <NavLink to={"/forgetPassword"}>
                <div
                  className={
                    "xl:text-base text-xs font-medium mt-2 text-right " +
                    `text-[${primary50Main}]`
                  }
                >
                  Forgot Password?
                </div>
              </NavLink>

              <Button
                buttonStyleType={"BPrimary"}
                text={"Sign in"}
                status={!isValid}
                className={"w-full h-12 mt-8"}
              />
            </form>
          )}
        </Formik>

        <div
          className={
            "font-normal xl:text-base text-xs 2xl:mt-8 mt-4 text-center " +
            `text-[${useTheme().colors.text50}]`
          }
        >
          Don’t have an account?
          <NavLink
            to={"/signUp"}
            className={
              "font-normal xl:text-base text-xs pl-1.5 " +
              `text-[${useTheme().colors.primary50Main}]`
            }
          >
            Sign up
          </NavLink>
        </div>

        <Button
          buttonStyleType={"MSecondary"}
          text={"Sign in with  Google"}
          onClick={(e: any) => google()}
          className={"w-full h-12 2xl:mt-8 mt-4 mb-4"}
        />
        {snackbarConfig.show ? (
          <Snackbar
            className={"fixed bottom-[24px] left-0 right-0 m-auto"}
            toastType={snackbarConfig.type}
            childMode={mode}
            btnText={snackbarConfig.btnText}
            icon={snackbarConfig.icon}
            onClick={snackbarConfig.onClick}
            text={snackbarConfig.text}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
