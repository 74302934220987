import React, { useEffect, useState } from "react";
import TicketService from "../../services/ticket.service";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import TextAreaField from "../../components/ds/TextAreaField";
import Button from "../../components/ds/Button";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import CloseB from "../../assets/icons/Close-b";
import PageTitles from "../../components/ds/PageTitle";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import AddOutline from "../../assets/icons/Add-outline";
import CheckB from "../../assets/icons/Check-b";
import moment from "moment/moment";
import useAuth from "../../useAuth";
import FileUpload from "../../components/ds/UploadFile";

export default function TicketDetails() {
  let { id } = useParams();
  const [step, setStep] = useState<any>("first");
  const [result, setResult] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [allMessage, setAllMessage] = useState<any>([]);
  const [files, setFiles] = useState<any[]>([]);

  const text90 = useTheme().colors.text90;
  const text50 = useTheme().colors.text50;
  const text20 = useTheme().colors.text20;
  const navigate = useNavigate();
  const mode = useTheme().colors.mode;
  const success50Main = useTheme().colors.success50Main;
  const error50Main = useTheme().colors.error50Main;
  const primary50Main = useTheme().colors.primary50Main;
  const warning50Main = useTheme().colors.warning50Main;
  const surface80Divider = useTheme().colors.surface80Divider;
  const { token } = useAuth();
  console.log(allMessage);

  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    type: "",
    btnText: "",
    show: false,
  });
  useEffect(() => {
    TicketService.getAllMessage(id, token)
      .then((response) => {
        setAllMessage(response?.data?.data);
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });

    TicketService.getOne(id, token)
      .then((response) => {
        setResult(response?.data?.entity);
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }, []);

  function Replay() {
    let form = new FormData();
    form.append("message", message);
    for (let item of files) {
      form.append("files[]", item);
    }
    
    TicketService.addMessage(id, form, token)
      .then((response) => {
        setAllMessage([response.data.entity, ...allMessage]);
        setMessage("")
        setFiles([])
        setSnackbarConfig({
          text: "Message Send",
          icon: <CheckB fill={success50Main} />,
          btnText: "Ok",
          type: ToastTypes.Success,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
        if (response.status) {
          setStep("first");
        }
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
    setMessage("")
  }

  function Steps() {
    if (step === "first") {
      return (
        <>
          <div>
            <label className={`text-lg  font-medium text-[${text90}]`}>
              Subject:
            </label>
            <div className={`text-base mb-5 font-normal text-[${text50}]`}>
              {result?.subject}
            </div>
          </div>
          <div>
            <label className={`text-lg font-medium text-[${text90}]`}>
              Status:
            </label>
            <div className={`text-base mb-5 font-normal text-[${text50}]`}>
              {result?.status_text}
            </div>
          </div>

          {allMessage?.map((item: any) => {
            if (item.type === 0) {
              return (
                <div
                  className={`p-3 border-l border-solid mb-3  border-[${success50Main}]`}
                >
                  <div className={"flex justify-between mb-3"}>
                    <div
                      className={`text-base  font-normal w-3/4 text-[${text50}]`}
                    >
                      {item.text}
                    </div>

                    <div className={`text-sm  font-bold text-[${text50}]`}>
                      {moment(item.created_at).format("MMM Do YYYY , h:mm")}
                    </div>
                  </div>
                  <div>
                    {item.attachments?.map((item: any) => {
                      return (
                        <button
                          className={`border border-[${surface80Divider}] p-2 text-[${text90}] rounded mr-2`}
                          onClick={() =>
                            window.open(
                              `https://core-api.finomate.io/storage/tickets/${id}/${item}`
                            )
                          }
                        >
                          View {item.split(".")[item.split(".").length - 1]}{" "}
                          file
                        </button>
                      );
                    })}
                  </div>
                  <div
                    className={`xl:my-6 my-3 w-full h-px bg-[${surface80Divider}]`}
                  ></div>
                  <label className={`text-sm font-medium text-[${text90}]`}>
                    Fino Bot
                  </label>
                </div>
              );
            } else if (item.type === 1) {
              return (
                <div
                  className={`p-3 border-l border-solid mb-3 border-[${primary50Main}]`}
                >
                  <div className={"flex justify-between mb-3"}>
                    <div
                      className={`text-base  font-normal w-3/4 text-[${text50}]`}
                    >
                      {item.text}
                    </div>

                    <div className={`text-sm  font-bold text-[${text50}]`}>
                      {moment(item.created_at).format("MMM Do YYYY , h:mm")}
                    </div>
                  </div>
                  <div>
                    {item.attachments?.map((item: any) => {
                      return (
                        <button
                          className={`border border-[${surface80Divider}] p-2 text-[${text90}] rounded mr-2`}
                          onClick={() =>
                            window.open(
                              `https://core-api.finomate.io/storage/tickets/${id}/${item}`
                            )
                          }
                        >
                          View {item.split(".")[item.split(".").length - 1]}{" "}
                          file
                        </button>
                      );
                    })}
                  </div>
                  <div
                    className={`xl:my-6 my-3 w-full h-px bg-[${surface80Divider}]`}
                  ></div>
                  <label className={`text-sm font-medium text-[${text90}]`}>
                    {item.user && item.user.first_name + item.user.last_name}
                  </label>
                </div>
              );
            } else if (item.type === 2) {
              return (
                <div
                  className={`p-3 border-l border-solid mb-3  border-[${warning50Main}]`}
                >
                  <div className={"flex justify-between mb-3"}>
                    <div
                      className={`text-base  font-normal w-3/4 text-[${text50}]`}
                    >
                      {item.text}
                    </div>

                    <div className={`text-sm  font-bold text-[${text50}]`}>
                      {moment(item.created_at).format("MMM Do YYYY , h:mm")}
                    </div>
                  </div>
                  <div>
                    {item.attachments?.map((item: any) => {
                      return (
                        <button
                          className={`border border-[${surface80Divider}] p-2 text-[${text90}] rounded mr-2`}
                          onClick={() =>
                            window.open(
                              `https://core-api.finomate.io/storage/tickets/${id}/${item}`
                            )
                          }
                        >
                          View {item.split(".")[item.split(".").length - 1]}{" "}
                          file
                        </button>
                      );
                    })}
                  </div>
                  <div
                    className={`xl:my-6 my-3 w-full h-px bg-[${surface80Divider}]`}
                  ></div>
                  <label className={`text-sm font-medium text-[${text90}]`}>
                    Operator
                  </label>
                </div>
              );
            }
          })}
        </>
      );
    } else if (step === "second") {
      return (
        <>
          <div>
            <label className={`text-lg  font-medium text-[${text90}]`}>
              Replay
            </label>
            <TextAreaField
              placeholder={"Add Message"}
              value={message}
              onChange={(event: any) => setMessage(event.target.value)}
            />
            <FileUpload
              selectFile={(event: any) => {
                setFiles([...files, event]);
              }}
              setFile={(file: any) => setFiles(file)}
            />
            <div
              className={
                "flex xl:w-2/5 items-center justify-between  mr-0 pl-auto  mb-8 py-8 gap-3 "
              }
            >
              <div className={"xl:w-2/4"}>
                <Button
                  onClick={() => setStep("first")}
                  text={"Cancel"}
                  className={`px-6 rounded-3xl border border-solid font-medium text-base h-12 xl:w-full text-[${text50}]`}
                />
              </div>
              <Button
                text={"Save"}
                onClick={() => Replay()}
                buttonStyleType={"BPrimary"}
                className={"xl:w-2/4 h-12"}
              />
            </div>
          </div>
        </>
      );
    }
  }

  function closeTicket() {
    TicketService.close(id, token)
      .then((response: any) => {
        if (response) {
          setSnackbarConfig({
            text: "Ticket closed",
            icon: <CheckB fill={success50Main} />,
            btnText: "Ok",
            type: ToastTypes.Success,
            onClick: () => {
              navigate("/tickets");
            },
            show: true,
          });

          setTimeout(() => {
            navigate("/tickets");
          }, 3000);
        }
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }

  return (
    <div className={"w-full xl:w-4/5 2xl:w-3/5 mt-4"}>
      <div className={"flex items-center justify-between mb-5"}>
        <NavLink to={"/tickets"}>
          <PageTitles
            text={"Tickets Details"}
            arrow={<ArrowLeft2Outline fill={useTheme().colors.text50} />}
          />
        </NavLink>

        <div className={"flex items-center gap-3"}>
          {result?.status_text === "Open" && (
            <Button
              buttonStyleType={"MWarning"}
              text={"Close Ticket"}
              onClick={() => closeTicket()}
              className={"flex h-12 justify-center items-center"}
            />
          )}

          <Button
            className={
              "hidden xl:flex items-center text-base tracking-tight xl:rounded-3xl h-12 font-medium xl:px-6 sm:px-4"
            }
            text={"Replay"}
            buttonStyleType={"MPrimary"}
            onClick={() => setStep("second")}
            icon={<AddOutline fill={text20} />}
          />

          <div className={"xl:hidden"} onClick={() => setStep("second")}>
            <AddOutline fill={primary50Main} />
          </div>
        </div>
      </div>

      {Steps()}
      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}
    </div>
  );
}
