import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const EditO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54 19.52C4.93 19.52 4.36 19.31 3.95 18.92C3.43 18.43 3.18 17.69 3.27 16.89L3.64 13.65C3.71 13.04 4.08 12.23 4.51 11.79L11.3457 4.5546C11.3548 4.54454 11.364 4.5347 11.3736 4.52512L12.72 3.09999C14.77 0.929988 16.91 0.869988 19.08 2.91999C21.25 4.96999 21.31 7.10999 19.26 9.27999L11.05 17.97C10.63 18.42 9.85 18.84 9.24 18.94L6.02 19.49C5.95897 19.4936 5.90052 19.4985 5.84325 19.5032C5.74101 19.5118 5.64256 19.52 5.54 19.52ZM5.6 12.81L11.5184 6.53839C12.2579 8.50749 13.8656 10.0293 15.8708 10.663L9.95 16.93C9.75 17.14 9.27 17.4 8.98 17.45L5.76 18C5.43 18.05 5.16 17.99 4.98 17.82C4.8 17.65 4.72 17.38 4.76 17.05L5.13 13.81C5.17 13.52 5.4 13.02 5.6 12.81ZM18.16 8.23999L17.0548 9.40979C14.9018 9.04453 13.1855 7.40628 12.7129 5.2726L13.81 4.10999C14.49 3.38999 15.16 2.90999 15.93 2.90999C16.55 2.90999 17.24 3.22999 18.04 3.99999C19.85 5.69999 19.4 6.91999 18.16 8.23999Z" fill={props.fill}/>
            <path d="M3 22.75H21C21.41 22.75 21.75 22.41 21.75 22C21.75 21.59 21.41 21.25 21 21.25H3C2.59 21.25 2.25 21.59 2.25 22C2.25 22.41 2.59 22.75 3 22.75Z" fill={props.fill}/>
        </svg>
    );
};

export default EditO;
