import React from "react";

export interface IconProperty {
    fill: string | '#56676C';
}

const EyeO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.67007 12C7.67007 14.39 9.61007 16.33 12.0001 16.33C14.3901 16.33 16.3301 14.39 16.3301 12C16.3301 9.60998 14.3901 7.66998 12.0001 7.66998C9.61007 7.66998 7.67007 9.60998 7.67007 12ZM9.17007 12C9.17007 10.44 10.4401 9.16998 12.0001 9.16998C13.5601 9.16998 14.8301 10.44 14.8301 12C14.8301 13.56 13.5601 14.83 12.0001 14.83C10.4401 14.83 9.17007 13.56 9.17007 12Z" fill={props.fill}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25008 15C4.69008 18.82 8.24008 21.02 12.0001 21.02C15.7501 21.02 19.3001 18.82 21.7401 15C22.8001 13.34 22.8001 10.65 21.7401 8.99998C19.3001 5.17998 15.7501 2.97998 12.0001 2.97998C8.25008 2.97998 4.70008 5.17998 2.25008 8.99998C1.19008 10.66 1.19008 13.35 2.25008 15ZM3.52008 9.80998C5.68008 6.41998 8.77008 4.47998 12.0001 4.47998C15.2301 4.47998 18.3201 6.41998 20.4801 9.80998C21.2301 10.98 21.2301 13.02 20.4801 14.19C18.3201 17.58 15.2301 19.52 12.0001 19.52C8.77008 19.52 5.68008 17.58 3.52008 14.19C2.77008 13.02 2.77008 10.98 3.52008 9.80998Z" fill={props.fill}/>
        </svg>
    );
};

export default EyeO;
