// fixme add
/*export enum Mode {
    Dark = "dark"
}*/
export enum Mode {
    Light = "light",
    Dark = "dark"
}

export interface ThemeProviderProps {
    children: React.ReactNode;
}

export interface ThemeContext {
    changeMode: (mode: Mode) => void;
    mode?: Mode;
}

export interface Theme {
    colors: {
        mode: string;
        surface40: string;
        surface50Background: string;
        surface60: string;
        surface70: string;
        surface80Divider: string;
        primary20: string;
        primary30: string;
        primary40: string;
        primary50Main: string;
        primary60: string;
        primary70: string;
        primary80: string;
        text20: string;
        text30: string;
        text40: string;
        text50: string;
        text60: string;
        text70: string;
        text80Body: string;
        text90: string;
        error20: string;
        error30: string;
        error40: string;
        error50Main: string;
        error60: string;
        success40: string;
        success50Main: string;
        success60: string;
        warning40: string;
        warning50Main: string;
    };
}
