import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import { useTheme } from 'styled-components'


export default function BillingLoading() {
    return (
       <>
           <div className="block w-full mt-4">
               <div className={`flex gap-7 rounded px-4 h-14 items-center mb-4 bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-2/3 '}>
                          <div className={'w-1/2'}>
                              <Skeleton/>
                          </div>
                       </div>
                       <div className={'w-1/3'}>
                           <Skeleton/>
                       </div>
                       <div className={'w-1/3'}>
                           <Skeleton/>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={`flex gap-7 rounded px-4 h-14 items-center mb-4 bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-2/3 '}>
                           <div className={'w-1/2'}>
                               <Skeleton/>
                           </div>
                       </div>
                       <div className={'w-1/3'}>
                           <Skeleton/>
                       </div>
                       <div className={'w-1/3'}>
                           <Skeleton/>
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={`flex gap-7 rounded px-4 h-14 items-center mb-4 bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-2/3 '}>
                           <div className={'w-1/2'}>
                               <Skeleton/>
                           </div>
                       </div>
                       <div className={'w-1/3'}>
                           <Skeleton/>
                       </div>
                       <div className={'w-1/3'}>
                           <Skeleton/>
                       </div>
                   </SkeletonTheme>
               </div>
           </div>
       </>
    )
}