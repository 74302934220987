import {useTheme} from "styled-components";
import Button from "../../components/ds/Button";
import CopyO from "../../assets/icons/Copy-o";
import React, {useEffect, useState} from "react";
import AccountService from "../../services/account.service";
import Snackbar, {ToastTypes} from "../../components/ds/Snackbar";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CloseB from "../../assets/icons/Close-b";
import CheckB from "../../assets/icons/Check-b";
import {useNavigate} from "react-router-dom";
import useAuth from "../../useAuth";

export default function Gifts() {
    const [resultGifts, setResultGifts] = useState<any>([]);
    const [resultCoupons, setResultCoupons] = useState<any>([]);
    const surface80Divider = useTheme().colors.surface80Divider;
    const text20 = useTheme().colors.text20;
    const text90 = useTheme().colors.text90;
    const text50 = useTheme().colors.text50;
    const surface70 = useTheme().colors.surface70;
    const error50Main = useTheme().colors.error50Main;
    const success50Main = useTheme().colors.success50Main;
    const mode = useTheme().colors.mode
    const navigate = useNavigate();
    const {token} = useAuth();

    const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
        text: '',
        icon: '',
        type: '',
        btnText: '',
        show: false,
    })
    useEffect(() => {
        AccountService.gifts(token).then((response: any) => {
            setResultGifts(response?.data.gifts)
            setResultCoupons(response?.data.coupons)
        }).catch((err) => {
            setSnackbarConfig({
                text: err.response.data.message,
                icon: <CloseB fill={error50Main}/>,
                btnText: 'Ok',
                type: ToastTypes.Error,
                onClick: () =>setSnackbarConfig({
                    show: false,
                }),
                show: true,
            })
            setTimeout(() => {
                if (err.response.status === 401) {
                    navigate('/')
                }
                if (err.response.status === 403) {
                    if (err?.response?.data?.message === 'Your email address is not verified.') {
                        navigate('/settings/profile')
                    } else {
                        navigate('/plans')
                    }
                }
                if (err.response.status === 404) {
                    navigate('*')
                }
            }, 3000);

        })
    }, [])

    function ActiveGift(id: any) {
        AccountService.activeGift(id,token).then((response: any) => {
            setSnackbarConfig({
                text: response.data.message,
                icon: <CheckB fill={success50Main}/>,
                btnText: 'Ok',
                type: ToastTypes.Success,
                onClick: () => setSnackbarConfig({
                    show: false,
                }),
                show: true,
            })
        }).catch((err) => {
            setSnackbarConfig({
                text: err.response.data.message,
                icon: <CloseB fill={error50Main}/>,
                btnText: 'Ok',
                type: ToastTypes.Error,
                onClick: () =>setSnackbarConfig({
                    show: false,
                }),
                show: true,
            })
            setTimeout(() => {
                if (err.response.status === 401) {
                    navigate('/')
                }
                if (err.response.status === 403) {
                    if (err?.response?.data?.message === 'Your email address is not verified.') {
                        navigate('/settings/profile')
                    } else {
                        navigate('/plans')
                    }
                }
                if (err.response.status === 404) {
                    navigate('*')
                }
            }, 3000);

        })
    }

    function giftStatus(item: any){
      if(!item.is_activated && !item.is_expired){
          return(
              <Button text={'Activate'} buttonStyleType={'BPrimary'}
                      onClick={() => ActiveGift(item.id)}
                      className={'flex text-xs lg:text-base items-center py-2 px-4 lg:px-6 h-9 lg:h-12 '}/>
          )
      }else if(item.is_activated && !item.is_expired){
          return (
              <div
                  className={`cursor-not-allowed py-2 px-4 flex items-center lg:px-6 rounded-3xl text-xs lg:text-base font-medium h-9 lg:h-12 bg-[${surface70}] text-[${text50}]`}>
                  Activated
              </div>
          )
      }else if(item.is_expired){
          return (
              <div
                  className={`cursor-not-allowed py-2 px-4 flex items-center lg:px-6 rounded-3xl text-xs lg:text-base font-medium h-9 lg:h-12 bg-[${surface70}] text-[${text50}]`}>
                  Expired
              </div>
          )
      }
    }
    return (
        <>
            <div className={'w-full lg:w-4/5 2xl:w-4/5'}>
                {resultCoupons?.map((item: any,index:number) => {
                    return (
                        <div
                            className={`flex items-center flex-col lg:flex-row justify-between border-b border-solid pb-6 mb-6 last:border-0 border-[${surface80Divider}]`}>
                            <div>
                                <div className={`font-medium text-base  lg:text-lg text-[${text90}]`}>
                                    {item.is_percent ? '%' : '$'}
                                    {item.is_percent ? `${Number.parseFloat(item.value).toFixed(0)} ` : `${item.value} `}
                                     discount for {item.title}
                                </div>
                                {/*<div className={'font-medium text-[10px] lg:text-sm ' + `text-[${text50}]`}>Valid*/}
                                {/*    through 10 days - Disposable*/}
                                {/*</div>*/}
                            </div>
                            <div className={'flex justify-end flex-row-reverse gap-3 mt-2 lg:mt-0'}>
                                <div>
                                    <CopyToClipboard text={item.code}
                                                     onCopy={() => {
                                                         let temp = [...resultCoupons]
                                                         temp = temp.map((t:any)=> {
                                                             if (t.copied) t.copied = false
                                                             return t
                                                         })

                                                         temp[index].copied=true;
                                                         setResultCoupons(temp)
                                                     }}>
                                        <Button text={item?.copied ? 'Copied' :'Copy'} buttonStyleType={'BPrimary'}
                                                className={'flex text-xs lg:text-base items-center py-2 px-4 lg:px-6 h-9 lg:h-12 '}
                                                icon={<CopyO fill={text20}/>}/>
                                    </CopyToClipboard>

                                </div>

                                <div
                                    className={`py-2 px-4 flex items-center lg:px-6 rounded-3xl text-xs lg:text-base font-medium h-9 lg:h-12 bg-[${surface70}] text-[${text50}]`}>
                                    {item.code}
                                </div>
                            </div>
                        </div>
                    )
                })}

                {resultGifts?.map((item: any) => {
                    return (
                        <div
                            className={`flex flex-col lg:flex-row items-center justify-between border-b border-solid pb-6 mb-6 last:border-0 border-[${surface80Divider}]`}>
                            <div>
                                {/*<div className={'font-medium text-base  lg:text-lg ' + `text-[${text90}]`}>40,000 Rial discount for subscription</div>*/}
                                <div className={`font-medium text-[10px] lg:text-sm text-[${text50}]`}>Valid
                                    through {item.days} days - Disposable
                                </div>
                            </div>
                            <div className={'flex justify-end flex-row-reverse gap-3 mt-2 lg:mt-0'}>
                                {giftStatus(item)}
                            </div>
                        </div>
                    )
                })}
            </div>
            {snackbarConfig.show ? <Snackbar className={'fixed bottom-[24px] left-0 right-0 m-auto'}
                                             toastType={snackbarConfig.type}
                                             childMode={mode}
                                             btnText={snackbarConfig.btnText}
                                             icon={snackbarConfig.icon}
                                             onClick={snackbarConfig.onClick}
                                             text={snackbarConfig.text}/> : ''}
        </>
    )
}
