import Button from "../../components/ds/Button";
import UploadO from "../../assets/icons/Upload-o";
import { useTheme } from "styled-components";
import TextField from "../../components/ds/Textfield";
import avatarPreview from "../../assets/images/avatarPreview.png";
import React, { ChangeEvent, useEffect, useState } from "react";
import AccountService from "../../services/account.service";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import CloseB from "../../assets/icons/Close-b";
import CheckB from "../../assets/icons/Check-b";
import { useNavigate } from "react-router-dom";
import useAuth from "../../useAuth";

export default function Profile() {
  const navigate = useNavigate();
  const text50 = useTheme().colors.text50;
  const success40 = useTheme().colors.success40;
  const error50Main = useTheme().colors.error50Main;
  const success50Main = useTheme().colors.success50Main;

  const [result, setResult] = useState<any>("");
  const mode = useTheme().colors.mode;
  const [avatar, setAvatar] = useState<any>(null);
  const hiddenFileInput = React.useRef<any>(null);
  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    type: "",
    action: "",
    show: false,
  });

  const { token } = useAuth();

  useEffect(() => {
    AccountService.profile(token)
      .then((response: any) => {
        setResult(response.data.data);
        setAvatar(response.data.data.avatar);
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }, []);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAvatar({
        file: e.target.files[0],
        displayMode: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  function updateProfile() {
    let formData = new FormData();
    formData.append("_method", "patch");
    formData.append("first_name", result.first_name);
    formData.append("last_name", result.last_name);
    if (avatar) {
      formData.append("avatar", avatar.file);
      if (avatar.file.size >= 1024000) {
        setSnackbarConfig({
          text: "Pleas upload a picture smaller than 1MB",
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
      } else {
        AccountService.updateProfile(formData, token)
          .then((response: any) => {
            setSnackbarConfig({
              text: response.data.message,
              icon: <CheckB fill={success50Main} />,
              btnText: "Ok",
              type: ToastTypes.Success,
              onClick: () =>
                setSnackbarConfig({
                  show: false,
                }),
              show: true,
            });
            AccountService.profile(token)
              .then((response: any) => {
                setResult(response.data.data);
                console.log(response.data.data.avatar);
                console.log(response.data.data.avatar);
                setAvatar(response.data.data.avatar);
              })
              .catch((err) => {
                setSnackbarConfig({
                  text: err.response.data.message,
                  icon: <CloseB fill={error50Main} />,
                  btnText: "Ok",
                  type: ToastTypes.Error,
                  onClick: () =>
                    setSnackbarConfig({
                      show: false,
                    }),
                  show: true,
                });
                setTimeout(() => {
                  if (err.response.status === 401) {
                    navigate("/");
                  }
                  if (err.response.status === 403) {
                    if (
                      err?.response?.data?.message ===
                      "Your email address is not verified."
                    ) {
                      navigate("/settings/profile");
                    } else {
                      navigate("/plans");
                    }
                  }
                  if (err.response.status === 404) {
                    navigate("*");
                  }
                }, 3000);
              });
          })
          .catch((err) => {
            setSnackbarConfig({
              text: err.response.data.message,
              icon: <CloseB fill={error50Main} />,
              btnText: "Ok",
              type: ToastTypes.Error,
              onClick: () =>
                setSnackbarConfig({
                  show: false,
                }),
              show: true,
            });
            setTimeout(() => {
              if (err.response.status === 401) {
                navigate("/");
              }
              if (err.response.status === 403) {
                if (
                  err?.response?.data?.message ===
                  "Your email address is not verified."
                ) {
                  navigate("/settings/profile");
                } else {
                  navigate("/plans");
                }
              }
              if (err.response.status === 404) {
                navigate("*");
              }
            }, 3000);
          });
      }
    } else {
      AccountService.updateProfile(formData, token)
        .then((response: any) => {
          setSnackbarConfig({
            text: response.data.message,
            icon: <CheckB fill={success50Main} />,
            btnText: "Ok",
            type: ToastTypes.Success,
            onClick: () =>
              setSnackbarConfig({
                show: false,
              }),
            show: true,
          });
        })
        .catch((err) => {
          setSnackbarConfig({
            text: err.response.data.message,
            icon: <CloseB fill={error50Main} />,
            btnText: "Ok",
            type: ToastTypes.Error,
            onClick: () =>
              setSnackbarConfig({
                show: false,
              }),
            show: true,
          });
          setTimeout(() => {
            if (err.response.status === 401) {
              navigate("/");
            }
            if (err.response.status === 403) {
              if (
                err?.response?.data?.message ===
                "Your email address is not verified."
              ) {
                navigate("/settings/profile");
              } else {
                navigate("/plans");
              }
            }
            if (err.response.status === 404) {
              navigate("*");
            }
          }, 3000);
        });
    }
  }

  function ConfirmEmail() {
    if (!result?.email_verified_at) {
      AccountService.verifyEmail(token)
        .then((response: any) => {
          setSnackbarConfig({
            text: "Email confirm successfully , Please check your email",
            icon: <CheckB fill={success50Main} />,
            btnText: "Ok",
            type: ToastTypes.Success,
            onClick: () =>
              setSnackbarConfig({
                show: false,
              }),
            show: true,
          });
        })
        .catch((err) => {
          setSnackbarConfig({
            text: err.response.data.message,
            icon: <CloseB fill={error50Main} />,
            btnText: "Ok",
            type: ToastTypes.Error,
            onClick: () =>
              setSnackbarConfig({
                show: false,
              }),
            show: true,
          });
          setTimeout(() => {
            if (err.response.status === 401) {
              navigate("/");
            }
            if (err.response.status === 403) {
              if (
                err?.response?.data?.message ===
                "Your email address is not verified."
              ) {
                navigate("/settings/profile");
              } else {
                navigate("/plans");
              }
            }
            if (err.response.status === 404) {
              navigate("*");
            }
          }, 3000);
        });
    }
  }

  function clearAvatar() {
    setAvatar(null);
  }

  function renderAvatar() {
    console.log(avatar);
    if (avatar) {
      return (
        <img
          className={"h-full w-full object-cover rounded-full"}
          src={avatar.displayMode ? avatar.displayMode : avatar}
        />
      );
    } else {
      return (
        <img
          className={"h-full w-full object-cover rounded-full"}
          src={avatarPreview}
        />
      );
    }
  }

  return (
    <div className={"w-full lg:w-4/5  2xl:w-4/5"}>
      <div className={"flex items-center mb-12"}>
        <div
          className={"w-12 h-12 xl:w-24 xl:h-24 rounded-full bg-cyan-100 mr-4"}
        >
          {renderAvatar()}
        </div>
        <input
          type="file"
          onChange={handleFileChange}
          ref={hiddenFileInput}
          style={{ display: "none" }}
        />
        <Button
          onClick={() => hiddenFileInput.current.click()}
          buttonStyleType={"BPrimary"}
          text={"Upload"}
          className={"flex h-12 justify-center items-center"}
          icon={<UploadO fill={useTheme().colors.text20} />}
        />

        {result.avatar && (
          <Button
            text={"Delete"}
            onClick={() => clearAvatar()}
            className={
              "px-6 rounded-3xl border border-solid font-medium text-base h-12 pl-2 " +
              `text-[${text50}]`
            }
          />
        )}
      </div>

      <div className={"xl:flex mb-6 gap-3"}>
        <div className={"xl:w-1/2"}>
          <TextField
            size={"Big"}
            label={"First Name"}
            name={"first_name"}
            onChange={(event: any) =>
              setResult({ ...result, first_name: event.target.value })
            }
            value={result.first_name}
          />
        </div>
        <div className={"xl:w-1/2"}>
          <TextField
            size={"Big"}
            label={"Last Name"}
            name={"last_name"}
            onChange={(event: any) =>
              setResult({ ...result, last_name: event.target.value })
            }
            value={result.last_name}
          />
        </div>
      </div>

      <div className={"xl:flex items-end mb-6 gap-3"}>
        <TextField
          size={"Big"}
          label={"Email"}
          readonly={true}
          value={result?.email}
          parentClassName={"w-full"}
        />

        <Button
          text={result?.email_verified_at ? "Verified" : "Confirm email"}
          onClick={() => ConfirmEmail()}
          className={
            "block px-4 mt-2 mr-0 pl-auto  xl:mt-0 xl:px-6 xl:w-40 r" +
            "ounded-3xl border border-solid font-medium text-xs xl:text-base h-12 box-content " +
            `${
              result?.email_verified_at
                ? ` text-[${success50Main}]`
                : ` text-[${text50}]`
            }` +
            ` border-[${useTheme().colors.surface80Divider}]` +
            `${
              result?.email_verified_at
                ? ` hover:cursor-not-allowed`
                : ` hover:cursor-pointer`
            }`
          }
        />
      </div>

      <div
        className={
          "flex xl:w-2/4 items-center justify-end   mr-0 pl-auto mt-12 py-8 gap-3 "
        }
      >
        {/*<Button text={'Cancel'}*/}
        {/*        className={'px-6 rounded-3xl border border-solid font-medium text-base h-12 xl:w-2/4 ' + `text-[${useTheme().colors.text50}]`}/>*/}
        <Button
          text={"Save changes"}
          onClick={() => updateProfile()}
          buttonStyleType={"BPrimary"}
          className={"font-medium xl:wfull h-12"}
        />
      </div>

      {/*<div className={'flex'}>*/}
      {/*    <AddOutline fill={useTheme().colors.primary50Main}/>*/}
      {/*    <div className={'font-base font-semibold ' + `text-[${useTheme().colors.primary50Main}]`}>*/}
      {/*        Add phone number*/}
      {/*    </div>*/}
      {/*</div>*/}

      {/*<div className={'flex mb-6 gap-3'}>*/}
      {/*    <div className={'w-full xl:w-1/2'}>*/}
      {/*        <TextField size={'Big'} label={'Phone Number'} suffixIcon={<EditO fill={useTheme().colors.primary50Main}/>}/>*/}
      {/*    </div>*/}
      {/*</div>*/}

      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}
    </div>
  );
}
