import PageTitles from "../../components/ds/PageTitle";
import QuestionO from "../../assets/icons/Question-o";
import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import TextField from "../../components/ds/Textfield";
import SearchO from "../../assets/icons/Search-o";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../components/ds/Button";
import AddOutline from "../../assets/icons/Add-outline";
import DataList from "./dataList";
import Empty from "../../components/ds/ResultPage/empty";
import NotificationT from "../../assets/icons/icon-128/Notification-t";
import TicketService from "../../services/ticket.service";
import SearchResult from "../../components/ds/ResultPage/searchResult";
import SearchT from "../../assets/icons/icon-128/Search-t";
import Search64T from "../../assets/icons/icon-64/Search-t";
import Loading from "./Loading";
import CloseB from "../../assets/icons/Close-b";
import { ToastTypes } from "../../components/ds/Snackbar";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import ticketMock from "../../assets/images/mock/tickets.png";
import { useDebouncedCallback } from "../../components/ds/Hooks/useDebounce";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import useAuth from "../../useAuth";

export default function Tickets() {
  const [search, setSearch] = useState<any>("");
  const [result, setResult] = useState<any>([]);
  const [data, setData] = useState<any>({
    hasMore: false,
    data: [],
    loading: true,
  });
  const [filterParam, setFilterParam] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const text90 = useTheme().colors.text90;
  const text60 = useTheme().colors.text60;
  const text20 = useTheme().colors.text20;
  const text50 = useTheme().colors.text50;
  const text80Body = useTheme().colors.text80Body;
  const primary50Main = useTheme().colors.primary50Main;
  const error50Main = useTheme().colors.error50Main;
  const [goPlan, setGoPlan] = React.useState(false);

  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { token } = useAuth();

  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    show: false,
  });
  function BuyPlan() {
    navigate("/plans");
  }

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "50%",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  const customMobileStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  const makeRequest = useDebouncedCallback((event: string) => {
    getTickets(1, event);
  }, 1500);

  function getTickets(pageEnter?: number, param?: string) {
    let isNew = filterParam !== param;
    setFilterParam(param);
    setPage(pageEnter || 1);

    TicketService.getAll(pageEnter ? pageEnter : page, param, token)
      .then((response: any) => {
        if (pageEnter === 1 || isNew)
          setData({
            data: response?.data?.data,
            loading: false,
            hasMore: response.data.data.length && response.data.total > 15,
          });
        else
          setData({
            data: [...result, ...response?.data?.data],
            loading: false,
            hasMore: response.data.data.length && response.data.total > 15,
          });
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response.data.message ===
          "The user does not have an active subscription"
        ) {
          setGoPlan(true);
        }
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: goPlan
            ? () => BuyPlan()
            : () =>
                setSnackbarConfig({
                  show: false,
                }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }

  useEffect(() => {
    getTickets();
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, []);

  function renderContent() {
    if (data.data.length === 0) {
      if (filterParam) {
        //render not found
        return (
          <>
            <div className="flex justify-between pb-6">
              <div className={"basis-full lg:basis-1/4"}>
                <TextField
                  size={"Big"}
                  prefixIcon={<SearchO fill={text50} size={"Big"} />}
                  onChange={(event: any) => {
                    // setFilterParam(event.target.value)
                    if (event.target.value.length > 2) {
                      makeRequest(event.target.value);
                      setSearch(event.target.value);
                    } else if (event.target.value.length === 0)
                      makeRequest(event.target.value);
                    setSearch(event.target.value);
                  }}
                  placeholder={"Search in tickets"}
                />
              </div>

              <NavLink to={"/tickets/add"} className={"hidden lg:block"}>
                <Button
                  className={
                    "flex items-center text-base tracking-tight lg:rounded-3xl h-12 font-medium lg:px-6 sm:px-4"
                  }
                  text={"Add new Ticket"}
                  buttonStyleType={"MPrimary"}
                  icon={<AddOutline fill={text20} />}
                />
              </NavLink>
            </div>

            <SearchResult
              icon={<SearchT />}
              mobileIcon={<Search64T />}
              title={`No results with "${search}"`}
            />
          </>
        );
      } else {
        //render no data
        return (
          <Empty
            icon={<NotificationT />}
            title={"Always have your ear to the ground"}
            des={
              "Alarms help you to be aware of latest changes you are curious about them by sending messages, emails, etc."
            }
            linkText={"Create first Ticket"}
            to={"/tickets/add"}
            linkIcon={<AddOutline fill={text20} />}
          />
        );
      }
    } else {
      //render data
      return (
        <>
          <div className="flex justify-between pb-6">
            <div className={"basis-full lg:basis-1/4"}>
              <TextField
                size={"Big"}
                prefixIcon={<SearchO fill={text50} size={"Big"} />}
                onChange={(event: any) => {
                  if (event.target.value.length > 2) {
                    makeRequest(event.target.value);
                    setSearch(event.target.value);
                  } else if (event.target.value.length === 0)
                    makeRequest(event.target.value);
                  setSearch(event.target.value);
                }}
                placeholder={"Search in tickets"}
              />
            </div>

            <NavLink to={"/tickets/add"} className={"hidden lg:block"}>
              <Button
                className={
                  "flex items-center text-base tracking-tight xl:rounded-3xl h-12 font-medium lg:px-6 sm:px-4"
                }
                text={"Add new Ticket"}
                buttonStyleType={"MPrimary"}
                icon={<AddOutline fill={text20} />}
              />
            </NavLink>
          </div>

          <DataList
            data={data.data}
            goNext={() => setPage(page + 1)}
            hasMore={data.hasMore}
          />
        </>
      );
    }
  }

  function renderModal() {
    if (isMobile || isTablet) {
      return (
        <>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customMobileStyles}
          >
            <div className={"flex justify-between items-center mb-6"}>
              <div className={`text-2xl font-semibold text-[${text90}]`}>
                How to use tickets?
              </div>

              <button onClick={closeModal}>
                <CloseO fill={text60} />
              </button>
            </div>

            <div className={"w-full h-1/2"}>
              <img src={ticketMock} alt={"ticketMock"} />
            </div>

            <div className={`text-base text-[${text80Body}]`}>
              click <a className={`text-[${primary50Main}]`}>here </a> to see
              more
            </div>
          </Modal>
        </>
      );
    } else if (isDesktop) {
      return (
        <>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div className={"flex justify-between items-center mb-6"}>
              <div className={`text-2xl font-semibold text-[${text90}]`}>
                How to use tickets?
              </div>

              <button onClick={closeModal}>
                <CloseO fill={text60} />
              </button>
            </div>

            <div className={"w-full h-1/2"}>
              <img src={ticketMock} alt={"ticketMock"} />
            </div>

            <div className={`text-base text-[${text80Body}]`}>
              click <a className={`text-[${primary50Main}]`}>here </a> to see
              more
            </div>
          </Modal>
        </>
      );
    }
  }

  return (
    <>
      <div className={"flex  justify-between items-center lg:mb-6 mb-4"}>
        <PageTitles
          text={"Tickets"}
          onClickIcon={() => setIsOpen(true)}
          icon={<QuestionO fill={primary50Main} />}
        />

        <NavLink to={"/tickets/add"} className={"lg:hidden"}>
          <AddOutline fill={primary50Main} />
        </NavLink>
      </div>

      {data.loading ? <Loading /> : <>{renderContent()}</>}

      {renderModal()}
    </>
  );
}
