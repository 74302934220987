import React from "react";

export interface IconProperty{
    fill?: string | '#56676C';
}
const Search64T = (props: IconProperty) => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3333 7.33301C17.1846 7.33301 7.33331 17.1842 7.33331 29.333C7.33331 41.4818 17.1846 51.333 29.3333 51.333C41.4821 51.333 51.3333 41.4818 51.3333 29.333C51.3333 28.2284 52.2287 27.333 53.3333 27.333C54.4379 27.333 55.3333 28.2284 55.3333 29.333C55.3333 43.6909 43.6912 55.333 29.3333 55.333C14.9754 55.333 3.33331 43.6909 3.33331 29.333C3.33331 14.9751 14.9754 3.33301 29.3333 3.33301C30.4379 3.33301 31.3333 4.22844 31.3333 5.33301C31.3333 6.43758 30.4379 7.33301 29.3333 7.33301Z" fill="#676B70"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.275 47.9192C52.2231 47.9036 50.3767 48.713 49.2503 50.2769C48.1248 51.8394 47.9369 53.8506 48.5807 55.7997C49.3859 58.2305 50.9593 60.3111 53.3741 60.6348C55.7886 60.9584 57.8521 59.3672 59.2657 57.2392C60.6088 55.2166 61.1785 52.8413 60.0862 50.8017C58.9946 48.7633 56.7048 47.9212 54.275 47.9192ZM52.3786 54.5445C52.9867 56.3796 53.7337 56.6472 53.9054 56.6702C54.0776 56.6933 54.8674 56.6312 55.9337 55.0261M52.3786 54.5445C52.0627 53.5875 52.2481 52.9588 52.496 52.6147C52.7412 52.2743 53.2644 51.9105 54.2489 51.9191L54.2664 51.9192C55.9401 51.9192 56.4462 52.4775 56.56 52.69C56.6744 52.9037 56.8571 53.6355 55.9337 55.0261" fill="#676B70"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3334 13.333C35.3334 12.2284 36.2288 11.333 37.3334 11.333H53.3334C54.4379 11.333 55.3334 12.2284 55.3334 13.333C55.3334 14.4376 54.4379 15.333 53.3334 15.333H37.3334C36.2288 15.333 35.3334 14.4376 35.3334 13.333Z" fill="#2BBBB2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3334 21.333C35.3334 20.2284 36.2288 19.333 37.3334 19.333H45.3334C46.4379 19.333 47.3334 20.2284 47.3334 21.333C47.3334 22.4376 46.4379 23.333 45.3334 23.333H37.3334C36.2288 23.333 35.3334 22.4376 35.3334 21.333Z" fill="#2BBBB2"/>
        </svg>
    );
};

export default Search64T;
