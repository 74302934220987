import axios from "axios";

const url = "https://core-api.finomate.io/api";
// const url = "http://127.0.0.1:8000/api";

let api = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});

export default api;
