import { useTheme } from "styled-components";
import React from "react";
import ListCart from "./listCart";
import CheckO from "../../assets/icons/Check-o";
import TicketService from "../../services/ticket.service";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "./Loading";
import CloseB from "../../assets/icons/Close-b";
import { ToastTypes } from "../../components/ds/Snackbar";
import { useNavigate } from "react-router-dom";
import useAuth from "../../useAuth";

export default function DataList(props: any) {
  const success50Main = useTheme().colors.success50Main;
  const navigate = useNavigate();
  const error50Main = useTheme().colors.error50Main;
  const { token } = useAuth();

  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    show: false,
  });
  function editAction() {}

  function deleteAction(id: any) {
    TicketService.delete(id, token)
      .then((response) => {
        if (response.status) {
          props.onDelete(id);
          setSnackbarConfig({
            text: "Item deleted successfully",
            icon: <CheckO fill={success50Main} />,
            btnText: "",
            show: true,
          });
        }
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }
  return (
    <>
      <InfiniteScroll
        dataLength={props?.data?.length} //This is important field to render the next data
        next={() => props.goNext()}
        hasMore={props.hasMore}
        loader={<Loading />}
        height={"69vh"}
      >
        <div className={"table rounded-3xl w-full  mt-6"}>
          <div className="lg:table-header-group hidden">
            <div className="table-row">
              <div
                className={`table-cell rounded-tl-3xl py-3 pl-6 text-left text-base  font-semibold text-[${
                  useTheme().colors.text50
                }]`}
              >
                #
              </div>
              <div
                className={`table-cell py-3 text-left text-base font-semibold text-[${
                  useTheme().colors.text50
                }]`}
              >
                Subject
              </div>
              <div
                className={`hidden xl:table-cell py-3 text-left text-base font-semibold text-[${
                  useTheme().colors.text50
                }]`}
              >
                Department
              </div>
              <div
                className={`hidden xl:table-cell py-3 text-left text-base font-semibold text-[${
                  useTheme().colors.text50
                }]`}
              >
                Status
              </div>
              <div
                className={`table-cell  py-3 mr-6 text-left text-base font-semibold text-[${
                  useTheme().colors.text50
                }]`}
              >
                Sender
              </div>
              <div className={"table-cell rounded-tr-3xl py-3 mr-6"}></div>
            </div>
          </div>
          <div className="table-row-group">
            {props?.data?.map((item: any, index: number) => {
              return (
                <ListCart
                  item={item}
                  index={index}
                  editAction={() => editAction()}
                  deleteAction={(id: any) => deleteAction(id)}
                />
              );
            })}
          </div>
          <div className={"h-4"}></div>
        </div>
      </InfiniteScroll>
    </>
  );
}
