import styled, {css} from "styled-components";

export const Item = styled.div`
  background-color: ${({theme}) => theme.colors.surface50Background};
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 1400px) {
    height: 48px;
  }
  @media screen and (max-width: 1280px) {
    height: 32px;
  }
    &.activeItem {
    position: relative;

    ::after {
      position: absolute;
      content: '';
      background: #07ACDA;
      box-shadow: 0px 0px 24px rgba(7, 172, 218, 0.32);
      border-radius: 0px 6px 6px 0px;
      width: 6px;
      height: 56px;
      left: -40px;
      @media screen and (max-width: 1280px){
        width: 4px;
        height: 32px;
        left: -24px;
      }
    }
  }
`

export const Link = styled.div`
  display: flex;
  align-items: center;
`
export const Icon = styled.div`
  margin-right: 8px;
`
export const Title = styled.div<{ status: boolean, collapse: boolean }>`
  color: ${({theme}) => theme.colors.text50};
  text-transform: capitalize;
  opacity: 1;
  transition: opacity 2s;

  ${({status, collapse}) => {
    if(collapse){
      return css`
        opacity: 0;
        transition: opacity 2s;
      `
    }
    if (status) {
      return css`
        color: ${({theme}) => theme.colors.text90};
      `
    }
  }}
`
export const Badge = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.error50Main};
  color: ${({theme}) => theme.colors.text20}
`