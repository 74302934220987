import styled, {css} from "styled-components";

export const Toast = styled.div<{
    styleType: string,
    childMode: string,
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  width: 640px;
  border-radius: 32px;
  z-index: 100000;
  
  ${({childMode}) => {
    if (childMode === "light") {
      return css`
        color: #BBC6C9;
        background-color: #1B1E22;
        border: 1px solid #1B1E22;
      `;
    } else {
      return css`
        color: #414345;
        background-color: #F4F7F8;;
        border: 1px solid #F4F7F8;;
      `
    }
  }}
  @media screen and (max-width: 1280px) {
    border-radius: 24px;
    width: 344px;
    height: 48px;
  }
`

export const Inline = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`
export const ActionBtn = styled.button<{ childMode: string }>`
  border-radius: 24px;
  padding: 0px 24px;
  height: 48px;
  @media screen and (max-width: 1280px) {
    padding: 0px 16px;
    height: 36px;
  }
  ${({childMode}) => {
    if (childMode === "light") {
      return css`
        color: #82969C;
        background-color: #FFFFFF;
        border: 1px solid #D3D8DA;
      `;
    } else {
      return css`
        color: #676B70;
        background-color: #151719;
        border: 1px solid #3A3E46;
      `
    }
  }}
`
