import styled from "styled-components";


export const ItemCart = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${({theme}) => theme.colors.surface80Divider};
  border-radius: 24px;
  position: relative;
  height: 184px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover{
   background-image: url("cartPattern.png");
  }
`

export const CartTitle = styled.div`
  color: ${({theme}) => theme.colors.text90};
`

export const CartDes = styled.div`
  color: ${({theme}) => theme.colors.text50};
`

export const Pattern = styled.div`
  .logo {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    background: ${({theme}) => theme.colors.surface50Background};
    border-radius: 64px;
    position: relative;
    @media screen and (max-width: 1280px){
      width: 32px;
      min-width: 32px;
      max-width: 32px;
      height: 32px;
    }
  }

  .total{
    background: ${({theme}) => theme.colors.surface50Background};
    color: ${({theme}) => theme.colors.text80Body};
  }
`
