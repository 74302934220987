import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const MoreO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 10.5C11.1771 10.5 10.5 11.1606 10.5 12C10.5 12.8394 11.1771 13.5 12 13.5C12.8261 13.5 13.5 12.8261 13.5 12C13.5 11.1739 12.8261 10.5 12 10.5Z" fill={props.fill}/>
            <path d="M12 16.5C11.1771 16.5 10.5 17.1606 10.5 18C10.5 18.8394 11.1771 19.5 12 19.5C12.8261 19.5 13.5 18.8261 13.5 18C13.5 17.1739 12.8261 16.5 12 16.5Z" fill={props.fill}/>
            <path d="M12 4.5C11.1771 4.5 10.5 5.1606 10.5 6C10.5 6.8394 11.1771 7.5 12 7.5C12.8261 7.5 13.5 6.82614 13.5 6C13.5 5.17386 12.8261 4.5 12 4.5Z" fill={props.fill}/>
        </svg>
    );
};

export default MoreO;
