import React, { ChangeEvent, useState } from "react";
import { useTheme } from "styled-components";
import Button from "../Button";
import UploadO from "../../../assets/icons/Upload-o";
import CloseB from "../../../assets/icons/Close-b";

function FileUpload(props: any) {
  const [files, setFiles] = useState<any[]>([]);
  const surface70 = useTheme().colors.surface70;
  const hiddenFileInput = React.useRef<any>(null);
  const text90 = useTheme().colors.text90;
  const error50Main = useTheme().colors.error50Main;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles([
        ...files,
        {
          file: e.target.files[0],
          displayMode: URL.createObjectURL(e.target.files[0]),
        },
      ]);
      props.selectFile(e.target.files[0]);
    }
  };

  function FileIcon(data: any) {
    if (data === "image/png" || data === "image/jpg" || data === "image/jpeg") {
      return (
        <>
          <svg
            fill={text90}
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
          >
            <path d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14V5H5v14Zm1-2h12l-3.75-5-3 4L9 13Zm-1 2V5v14Z" />
          </svg>
        </>
      );
    } else if (data === "application/pdf") {
      return (
        <>
          <svg
            fill={text90}
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
          >
            <path d="M9 12.5h1v-2h1q.425 0 .713-.288Q12 9.925 12 9.5v-1q0-.425-.287-.713Q11.425 7.5 11 7.5H9Zm1-3v-1h1v1Zm3 3h2q.425 0 .713-.288.287-.287.287-.712v-3q0-.425-.287-.713Q15.425 7.5 15 7.5h-2Zm1-1v-3h1v3Zm3 1h1v-2h1v-1h-1v-1h1v-1h-2ZM8 18q-.825 0-1.412-.587Q6 16.825 6 16V4q0-.825.588-1.413Q7.175 2 8 2h12q.825 0 1.413.587Q22 3.175 22 4v12q0 .825-.587 1.413Q20.825 18 20 18Zm0-2h12V4H8v12Zm-4 6q-.825 0-1.412-.587Q2 20.825 2 20V6h2v14h14v2ZM8 4v12V4Z" />
          </svg>
        </>
      );
    } else if (data === "audio/mpeg") {
      return (
        <>
          <svg
            fill={text90}
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
          >
            <path d="M2 22V4q0-.825.588-1.413Q3.175 2 4 2h16q.825 0 1.413.587Q22 3.175 22 4v12q0 .825-.587 1.413Q20.825 18 20 18H6Zm2-4.825L5.175 16H20V4H4ZM4 4v13.175Zm7.25 11h1.5V5h-1.5ZM8.5 13H10V7H8.5ZM6 11h1.5V9H6Zm8 2h1.5V7H14Zm2.5-2H18V9h-1.5Z" />
          </svg>
        </>
      );
    }
  }

  return (
    <div className={`border-[${surface70}] border mt-3 p-5  rounded-3xl`}>
      <div>
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/png, image/jpeg,.pdf,video/mp4,.mp3"
          ref={hiddenFileInput}
          style={{ display: "none" }}
        />

        <Button
          onClick={() => hiddenFileInput.current.click()}
          type={"button"}
          buttonStyleType={"BPrimary"}
          text={"Choose file"}
          className={"flex h-12 justify-center items-center m-auto"}
          icon={<UploadO fill={useTheme().colors.text20} />}
        />
      </div>

      {files.map((file: any, index: number) => {
        return (
          <div className={"flex items-center w-full justify-between my-5"}>
            <div className={""}>
              <div className={"flex items-center"}>
                {FileIcon(file?.file?.type)}

                <div className={`pl-3 text-[${text90}]`}>
                  {file && file.file && `${file?.file?.name}`}
                </div>
              </div>
              {/*{file?.displayMode &&  <img className={'w-24 h-24'} src={file?.displayMode} alt="preview image"/>}*/}
            </div>

            <div
              onClick={() => {
                let temp = [...files];
                temp.splice(index, 1);
                setFiles([...temp]);
                props.setFile(temp.map((t) => t.file));
              }}
            >
              <CloseB fill={error50Main} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default FileUpload;
