import * as React from "react";

const authContext = React.createContext();

function useAuth() {
  const [authed, setAuthed] = React.useState(false);
  const [token, setToken] = React.useState("");

  return {
    authed,
    token: localStorage.getItem("token") || "",
    login(customToken) {
      return new Promise((res) => {
        setAuthed(true);
        setToken(customToken);
        res();
      });
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false);
        setToken("");
        res();
      });
    },
    checkUserToken() {
      const userToken = localStorage.getItem("token");
      if (!userToken || userToken === "undefined") {
        setAuthed(false);
        return false;
      }
      setToken(userToken);
      return true;
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
