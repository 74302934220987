import React, {useEffect} from "react";

export interface IconProperty {
    fill: string | '#56676C';
    size: string | 'Medium'
}

const SearchO = (props: IconProperty) => {

    const [size, setSize] = React.useState(props.size)

    useEffect(()=>{
        setSize(props.size)
    },[])
    const renderIcon = () => {
        if (size === 'Medium') {
            return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.833008 7.33331C0.833008 10.92 3.74634 13.8333 7.33301 13.8333C10.9197 13.8333 13.833 10.92 13.833 7.33331C13.833 3.74665 10.9197 0.833313 7.33301 0.833313C3.74634 0.833313 0.833008 3.74665 0.833008 7.33331ZM1.83301 7.33331C1.83301 4.29998 4.29967 1.83331 7.33301 1.83331C10.3663 1.83331 12.833 4.29998 12.833 7.33331C12.833 10.3666 10.3663 12.8333 7.33301 12.8333C4.29967 12.8333 1.83301 10.3666 1.83301 7.33331Z"
                      fill={props.fill}/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.2864 15.18C13.3331 15.1866 13.3864 15.1933 13.4397 15.1933C13.7731 15.1933 14.2864 15.0466 14.7597 14.3333C15.1397 13.7666 15.2064 13.18 14.9664 12.72C14.7264 12.26 14.1931 12 13.5131 12C12.9864 12 12.5264 12.2133 12.2531 12.5933C11.9797 12.9733 11.9197 13.4733 12.0864 13.9733C12.4064 14.9266 12.9731 15.14 13.2864 15.18ZM13.4197 14.1933C13.3131 14.18 13.1531 14.0066 13.0397 13.66C12.9731 13.4666 12.9864 13.2933 13.0664 13.18C13.1531 13.0666 13.3064 13 13.5131 13C13.8597 13 14.0464 13.1 14.0931 13.1933C14.1397 13.2866 14.1264 13.4933 13.9331 13.78C13.7264 14.08 13.5264 14.2066 13.4197 14.1933Z"
                      fill={props.fill}/>
            </svg>;
        } else if (size === 'Big') {
            return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.25 11C1.25 16.38 5.62 20.75 11 20.75C16.38 20.75 20.75 16.38 20.75 11C20.75 5.62 16.38 1.25 11 1.25C5.62 1.25 1.25 5.62 1.25 11ZM2.75 11C2.75 6.45 6.45 2.75 11 2.75C15.55 2.75 19.25 6.45 19.25 11C19.25 15.55 15.55 19.25 11 19.25C6.45 19.25 2.75 15.55 2.75 11Z"
                      fill={props.fill}/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19.9301 22.77C20.0001 22.78 20.0801 22.79 20.1601 22.79C20.6601 22.79 21.4301 22.57 22.1401 21.5C22.7101 20.65 22.8101 19.77 22.4501 19.08C22.0901 18.39 21.2901 18 20.2701 18C19.4801 18 18.7901 18.32 18.3801 18.89C17.9701 19.46 17.8801 20.21 18.1301 20.96C18.6101 22.39 19.4601 22.71 19.9301 22.77ZM20.1301 21.29C19.9701 21.27 19.7301 21.01 19.5601 20.49C19.4601 20.2 19.4801 19.94 19.6001 19.77C19.7301 19.6 19.9601 19.5 20.2701 19.5C20.7901 19.5 21.0701 19.65 21.1401 19.79C21.2101 19.93 21.1901 20.24 20.9001 20.67C20.5901 21.12 20.2901 21.31 20.1301 21.29Z"
                      fill={props.fill}/>
            </svg>;
        }
    }

    return (
        <>
            {renderIcon()}
        </>
    )

};

export default SearchO;
