import styled, {css} from "styled-components";

export const Btn = styled.button<{
    styleType: string,
}>`
  svg {
    margin: 0 8px 0 0;
  }
  position: relative;
  .loading-icon{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  ${({styleType}) => {
    if (styleType === "BPrimary") {
      return css`
        color: ${({theme}) => theme.colors.text20};
        background-color: ${({theme}) => theme.colors.primary50Main};
        border: 1px solid ${({theme}) => theme.colors.primary50Main};
        padding: 0px 32px;
        border-radius: 28px;
        @media screen and (max-width: 1280px) {
          padding: 0px 20px;
          //border-radius: 22px;
        }

        &:hover {
          color: ${({theme}) => theme.colors.text20};
          background-color: ${({theme}) => theme.colors.primary40};
          border: 1px solid ${({theme}) => theme.colors.primary40};
        }

        &:disabled {
          color: ${({theme}) => theme.colors.text20};
          background-color: ${({theme}) => theme.colors.primary20};
          border: 1px solid ${({theme}) => theme.colors.primary20};
          cursor: not-allowed;
        }

        &:focus-within {
          color: ${({theme}) => theme.colors.text20};
          background-color: ${({theme}) => theme.colors.primary60};
          border: 1px solid ${({theme}) => theme.colors.primary60};
        }
      `;
    }
    if (styleType === 'MPrimary') {
      return css`
        color: ${({theme}) => theme.colors.text20};
        background-color: ${({theme}) => theme.colors.primary50Main};
        border: 1px solid ${({theme}) => theme.colors.primary50Main};
        @media screen and (max-width: 1280px) {
          //border-radius: 18px;
        }

        &:hover {
          color: ${({theme}) => theme.colors.text20};
          background-color: ${({theme}) => theme.colors.primary40};
          border: 1px solid ${({theme}) => theme.colors.primary40};
        }

        &:disabled {
          color: ${({theme}) => theme.colors.text20};
          background-color: ${({theme}) => theme.colors.primary20};
          border: 1px solid ${({theme}) => theme.colors.primary20};
          cursor: not-allowed;
        }

        &:focus-within {
          color: ${({theme}) => theme.colors.text20};
          background-color: ${({theme}) => theme.colors.primary60};
          border: 1px solid ${({theme}) => theme.colors.primary60};
        }
      `
    }
    if (styleType === 'MSecondary') {
      return css`
        color: ${({theme}) => theme.colors.text50};
        background-color: ${({theme}) => theme.colors.surface50Background};
        border: 1px solid ${({theme}) => theme.colors.surface80Divider};
        padding: 0px 24px;
        border-radius: 24px;
        @media screen and (max-width: 1280px) {
          padding: 0px 16px;
          //border-radius: 18px;
        }

        &:hover {
          color: ${({theme}) => theme.colors.text50};
          background-color: ${({theme}) => theme.colors.surface60};
          border: 1px solid ${({theme}) => theme.colors.surface80Divider};
        }

        &:disabled {
          color: ${({theme}) => theme.colors.text30};
          background-color: ${({theme}) => theme.colors.surface70};
          border: 1px solid ${({theme}) => theme.colors.surface80Divider};
          cursor: not-allowed;
        }

        &:focus-within {
          color: ${({theme}) => theme.colors.text60};
          background-color: ${({theme}) => theme.colors.surface70};
          border: 1px solid ${({theme}) => theme.colors.surface80Divider};
        }
      `
    }
    if (styleType === 'MWarning') {
      return css`
        color: ${({theme}) => theme.colors.error50Main};
        background-color: ${({theme}) => theme.colors.error30};
        border: 1px solid ${({theme}) => theme.colors.error30};
        padding: 0px 24px;
        border-radius: 24px;
        @media screen and (max-width: 1280px) {
          padding: 0px 16px;
          //border-radius: 18px;
        }

        &:hover {
          color: ${({theme}) => theme.colors.error50Main};
          background-color: ${({theme}) => theme.colors.error20};
          border: 1px solid ${({theme}) => theme.colors.error20};
        }

        &:disabled {
          color: ${({theme}) => theme.colors.error40};
          background-color: ${({theme}) => theme.colors.error30};
          border: 1px solid ${({theme}) => theme.colors.error30};
          cursor: not-allowed;
        }

        &:focus-within {
          color: ${({theme}) => theme.colors.error50Main};
          background-color: ${({theme}) => theme.colors.error40};
          border: 1px solid ${({theme}) => theme.colors.error40};
        }
      `
    }
  }}
`
