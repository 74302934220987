import "./assets/styles/_style.scss";
import "react-loading-skeleton/dist/skeleton.css";

import { Link, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import useAuth from "./useAuth";
import SignIn from "./pages/Auth/signIn";
import Dashboard from "./pages/Dashboard";
import Inbox from "./pages/Dashboard/inbox";
import SignUp from "./pages/Auth/signUp";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import NewPassword from "./pages/Auth/NewPassword";
import GoogleAuth from "./pages/Auth/GoogleAuth";
import Layout from "./pages/layout";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import Google2Fa from "./pages/Auth/Google2Fa";
import Lists from "./pages/Lists";
import AddList from "./pages/Lists/add";
import ListDetails from "./pages/Lists/listDetails";
import Alarms from "./pages/Alarms";
import Tickets from "./pages/Tickets";
import AddAlarm from "./pages/Alarms/add";
import AddTicket from "./pages/Tickets/add";
import TicketDetails from "./pages/Tickets/details";
import Chart from "./pages/Chart";
import Wallet from "./pages/Wallet";
import Deposit from "./pages/Wallet/deposit";
import Withdraw from "./pages/Wallet/withdraw";
import Settings from "./pages/Settings";
import Profile from "./pages/Settings/profile";
import Security from "./pages/Settings/security";
import ExchangeAPI from "./pages/Settings/exchangeAPI";
import Billing from "./pages/Settings/billing";
import Gifts from "./pages/Settings/gifts";
import Invites from "./pages/Settings/invites";
import Sessions from "./pages/Settings/sessions";
import Plan from "./pages/Plan";
import NotFoundPage from "./pages/Other/404";
import SessionExpiredPage from "./pages/Other/419";
import NoService from "./pages/Other/503";


import { useEffect, useState } from "react";
import AccountService from "./services/account.service";
import moment from "moment";
import CloseB from "./assets/icons/Close-b";
import { useTheme } from "styled-components";
import { ToastTypes } from "./components/ds/Snackbar";


function RequireAuth({ children }) {

  const { authed, checkUserToken } = useAuth();

  return authed === true || checkUserToken() ? (
    children
  ) : (
    <Navigate to="/login" replace />
  );
}


function App() {

  // const [profile, setProfile] = useState("");
  // const [gitsDays, setGitsDays] = useState("");
  // const { token } = useAuth();
  // const [snackbarConfig, setSnackbarConfig] = useState({
  //   text: "",
  //   icon: "",
  //   btnText: "",
  //   onClick: "",
  //   show: false,
  // });
  // const error50Main = useTheme().colors.error50Main;

  // useEffect(() => {
  //   AccountService.profile(token)
  //     .then((response) => {
  //       let profileData = response.data.data;
  //       if (profileData.active_plan?.expire_at) {
  //         let expireDate = new Date(
  //           profileData?.active_plan?.expire_at
  //         ).getTime();
  //         let now = new Date().getTime();
  //         profileData.daysToExpire = Math.round(
  //           (expireDate - now) / (60 * 60 * 24 * 1000)
  //         );
  //       }
  //       if (profileData?.created_at) {
  //         let createdAt = new Date(profileData.created_at).getTime();
  //         let now = new Date().getTime();
  //         profileData.createdDay = Math.round(
  //           (createdAt - now) / (60 * 60 * 24 * 1000)
  //         );
  //         if (profileData.createdDay < 0) {
  //           profileData.createdDay = 0;
  //         }
  //       }
  //       const dateFrom = moment(profile.created_at)
  //         .subtract(7, "d")
  //         .format("YYYY-MM-DD");
  //       setProfile(profileData);
  //     })
  //     .catch((err) => {
  //       setSnackbarConfig({
  //         text: err.response.data.message,
  //         icon: <CloseB fill={error50Main} />,
  //         btnText: "Ok",
  //         type: ToastTypes.Error,
  //         onClick: () =>
  //           setSnackbarConfig({
  //             show: false,
  //           }),
  //         show: true,
  //       });
  //     });

  //   AccountService.giftsDays(token)
  //     .then((response) => {
  //       setGitsDays(response?.data?.days);
  //     })
  //     .catch((err) => {
  //       setSnackbarConfig({
  //         text: err.response.data.message,
  //         icon: <CloseB fill={error50Main} />,
  //         btnText: "Ok",
  //         type: ToastTypes.Error,
  //         onClick: () =>
  //           setSnackbarConfig({
  //             show: false,
  //           }),
  //         show: true,
  //       });
  //     });
  // }, []);


  return (
    <Routes>
      <Route path={"/login"} element={<SignIn />} />
      <Route path={"/signUp"} element={<SignUp />} />
      <Route path={"/forgetPassword"} element={<ForgetPassword />} />
      <Route path={"/resetPass/:id"} element={<NewPassword />} />
      <Route path={"/verifyEmail/:id/:token"} element={<VerifyEmail />} />
      <Route path={"/google-callback"} element={<GoogleAuth />} />
      <Route path={"/google-2fa"} element={<Google2Fa />} />

      <Route path={"*"} element={<NotFoundPage />} />
      <Route path={"/419"} element={<SessionExpiredPage />} />
      <Route path={"/503"} element={<NoService />} />

      <Route
        path={"/"}
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route
          path={"/"}
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path={"/inbox"}
          element={
            <RequireAuth>
              <Inbox />
            </RequireAuth>
          }
        />
        <Route
          path={"/lists"}
          element={
            <RequireAuth>
              <Lists />
            </RequireAuth>
          }
        />
        <Route
          path={"/lists/add"}
          element={
            <RequireAuth>
              <AddList />
            </RequireAuth>
          }
        />
        <Route
          path={"/lists/details/:id"}
          element={
            <RequireAuth>
              <ListDetails />
            </RequireAuth>
          }
        />
        <Route
          path={"/alarms"}
          element={
            <RequireAuth>
              <Alarms />
            </RequireAuth>
          }
        />
        <Route
          path={"/alarms/add"}
          element={
            <RequireAuth>
              <AddAlarm />
            </RequireAuth>
          }
        />
        <Route
          path={"/tickets"}
          element={
            <RequireAuth>
              <Tickets />
            </RequireAuth>
          }
        />
        <Route
          path={"/tickets/add"}
          element={
            <RequireAuth>
              <AddTicket />
            </RequireAuth>
          }
        />
        <Route
          path={"/tickets/details/:id"}
          element={
            <RequireAuth>
              <TicketDetails />
            </RequireAuth>
          }
        />
        <Route
          path={"/chart"}
          element={
            <RequireAuth>
              <Chart />
            </RequireAuth>
          }
        />
        <Route
          path={"/wallet"}
          element={
            <RequireAuth>
              <Wallet />
            </RequireAuth>
          }
        />
        <Route
          path={"/wallet/deposit"}
          element={
            <RequireAuth>
              <Deposit />
            </RequireAuth>
          }
        />
        <Route
          path={"/wallet/withdraw"}
          element={
            <RequireAuth>
              <Withdraw />
            </RequireAuth>
          }
        />
        <Route
          path={"/settings"}
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        >
          <Route
            path={"/settings/profile"}
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path={"/settings/security"}
            element={
              <RequireAuth>
                <Security />
              </RequireAuth>
            }
          />
          <Route
            path={"/settings/exchangeAPI"}
            element={
              <RequireAuth>
                <ExchangeAPI />
              </RequireAuth>
            }
          />
          <Route
            path={"/settings/billing"}
            element={
              <RequireAuth>
                <Billing />
              </RequireAuth>
            }
          />
          <Route
            path={"/settings/gifts"}
            element={
              <RequireAuth>
                <Gifts />
              </RequireAuth>
            }
          />
          <Route
            path={"/settings/invites"}
            element={
              <RequireAuth>
                <Invites />
              </RequireAuth>
            }
          />
          <Route
            path={"/settings/sessions"}
            element={
              <RequireAuth>
                <Sessions />
              </RequireAuth>
            }
          />
        </Route>

        <Route
          path={"/plans"}
          element={
            <RequireAuth>
              <Plan />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
