import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import { useTheme } from 'styled-components'


export default function Loading() {
    return (
       <>
           <div className="hidden xl:block w-full">
               <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/5 gap-3 flex items-center'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>

               <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/5 gap-3 flex items-center'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/5 gap-3 flex items-center'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/5 gap-3 flex items-center'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/5 gap-3 flex items-center'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-1/5 gap-3 flex items-center'}>
                           <div className={'w-10 h-10 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>
           </div>

           <div className="block xl:hidden w-full">
               <div className={'flex gap-7 rounded-3xl p-4 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-2/5 gap-3 flex items-center'}>
                           <div className={'w-7 h-7 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'flex gap-7 rounded-3xl p-4 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-2/5 gap-3 flex items-center'}>
                           <div className={'w-7 h-7 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'flex gap-7 rounded-3xl p-4 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-2/5 gap-3 flex items-center'}>
                           <div className={'w-7 h-7 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>
               <div className={'flex gap-7 rounded-3xl p-4 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                   <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                       <div className={'w-2/5 gap-3 flex items-center'}>
                           <div className={'w-7 h-7 rounded-full relative'} style={{left: (0 * (-12)) + 'px'}}>
                               <Skeleton  circle
                                          height="100%"/>
                           </div>

                           <div className={'w-full'}>
                               <div className={'w-1/6 '}>
                                   <Skeleton/>
                               </div>

                               <Skeleton/>

                           </div>
                       </div>

                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                       <div className={'w-1/6'}>
                           <Skeleton />
                       </div>
                   </SkeletonTheme>
               </div>

           </div>
       </>
    )
}