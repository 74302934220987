import React, { useState } from "react";
type contextType = {
  setHasUnRead(value: boolean): void;
  hasUnRead: boolean;
  // ...
};
export const Context = React.createContext<contextType | null>({
  hasUnRead: true,
  setHasUnRead(value: boolean) {},
});
export const ContextProvider = ({ children }: any) => {
  const [hasUnRead, setHasUnRead] = useState<boolean>(false);

  return (
    <Context.Provider value={{ hasUnRead, setHasUnRead }}>
      {children}
    </Context.Provider>
  );
};
