import PageTitles from "../../components/ds/PageTitle";
import { useTheme } from "styled-components";
import React, { useState } from "react";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import Button from "../../components/ds/Button";
import { NavLink, useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import AccountService from "../../services/account.service";
import TextField from "../../components/ds/Textfield";
import WithdrawO from "../../assets/icons/Withdraw-o";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import CheckB from "../../assets/icons/Check-b";
import CloseB from "../../assets/icons/Close-b";
import useAuth from "../../useAuth";

export default function Deposit() {
    const navigate = useNavigate();

    const text20 = useTheme().colors.text20;
    const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
        text: '',
        icon: '',
        btnText: '',
        show: false,
    })
    const success50Main = useTheme().colors.success50Main;
    const error50Main = useTheme().colors.error50Main;
    const mode = useTheme().colors.mode

    const [url, setUrl] = useState('')

    function payment() {
        window.open(url, "_blank")
    }
    const { token } = useAuth();

    return (
        <>
            <div className={'mb-4 xl:mb-6'}>
                <NavLink to={'/wallet'}>
                    <PageTitles text={'Deposit'} arrow={<ArrowLeft2Outline fill={useTheme().colors.text50} />} />
                </NavLink>
            </div>

            <div className={'lg:w-2/5'}>
                <div className={`text-xs xl:text-base font-normal text-[${useTheme().colors.text80Body}]`}>
                    please press ok to redirect the payment gateway
                </div>
                <Formik
                    initialValues={{ amount: '', wallet_address: '' }}
                    validate={values => {
                        const errors: any = {};
                        if (!values.amount) {
                            errors.amount = 'Please enter amount value'
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        AccountService.walletDeposit(values, token).then((response: any) => {
                            console.log(response);
                            if (response) {
                                setUrl(response.data.url)
                                setSnackbarConfig({
                                    text: response.data.message,
                                    icon: <CheckB fill={success50Main} />,
                                    btnText: 'Ok',
                                    type: ToastTypes.Success,
                                    onClick: () => payment(),
                                    show: true,
                                })
                                if (response.data.url) {
                                    setTimeout(() => {
                                        window.open(response.data.url, "_blank")
                                        setSnackbarConfig({
                                            show: false,
                                        })
                                    }, 2000);
                                }
                            }
                        }).catch((err) => {
                            setSnackbarConfig({
                                text: err.response.data.message,
                                icon: <CloseB fill={error50Main} />,
                                btnText: 'Ok',
                                type: ToastTypes.Error,
                                onClick: () => setSnackbarConfig({
                                    show: false,
                                }),
                                show: true,
                            })
                            setTimeout(() => {
                                if (err.response.status === 401) {
                                    navigate('/')
                                }
                                if (err.response.status === 403) {
                                    if (err?.response?.data?.message === 'Your email address is not verified.') {
                                        navigate('/settings/profile')
                                    } else {
                                        navigate('/wallet')
                                    }
                                }
                                if (err.response.status === 404) {
                                    navigate('*')
                                }
                            }, 3000);
                        })
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        isValid,
                        handleChange,
                        handleSubmit,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <TextField size={'Big'} placeholder={'Amount'} name={'amount'} value={values.amount}
                                    onChange={handleChange} />
                                <ErrorMessage name="amount" component="div" className={`text-[${error50Main}]`} />



                                <Button text={'Confirm Deposit request'}
                                    className={'flex w-full text-xs xl:text-base mt-4 mb-4 h-12 items-center justify-center '}
                                    status={!isValid} buttonStyleType={'BPrimary'}
                                    icon={<WithdrawO fill={text20} />} />
                            </div>

                        </form>

                    )}
                </Formik>
                <div
                    className={`w-full p-4 flex items-center rounded-2xl gap-4 bg-[${useTheme().colors.warning40}]`}>
                    <div>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4"
                                d="M29.0133 21.228L20.48 5.86797C19.3333 3.8013 17.7466 2.66797 16 2.66797C14.2533 2.66797 12.6666 3.8013 11.52 5.86797L2.98664 21.228C1.90664 23.188 1.78664 25.068 2.6533 26.548C3.51997 28.028 5.22664 28.8413 7.46664 28.8413H24.5333C26.7733 28.8413 28.48 28.028 29.3466 26.548C30.2133 25.068 30.0933 23.1746 29.0133 21.228Z"
                                fill="#ECBF1D" />
                            <path
                                d="M16.0001 19.6667C15.4534 19.6667 15.0001 19.2133 15.0001 18.6667V12C15.0001 11.4533 15.4534 11 16.0001 11C16.5467 11 17.0001 11.4533 17.0001 12V18.6667C17.0001 19.2133 16.5467 19.6667 16.0001 19.6667Z"
                                fill="#ECBF1D" />
                            <path
                                d="M16.0001 24C15.9201 24 15.8267 23.9867 15.7334 23.9733C15.6534 23.96 15.5734 23.9333 15.4934 23.8933C15.4134 23.8667 15.3334 23.8267 15.2534 23.7733C15.1867 23.72 15.1201 23.6667 15.0534 23.6133C14.8134 23.36 14.6667 23.0133 14.6667 22.6667C14.6667 22.32 14.8134 21.9733 15.0534 21.72C15.1201 21.6667 15.1867 21.6133 15.2534 21.56C15.3334 21.5067 15.4134 21.4667 15.4934 21.44C15.5734 21.4 15.6534 21.3733 15.7334 21.36C15.9067 21.32 16.0934 21.32 16.2534 21.36C16.3467 21.3733 16.4267 21.4 16.5067 21.44C16.5867 21.4667 16.6667 21.5067 16.7467 21.56C16.8134 21.6133 16.8801 21.6667 16.9467 21.72C17.1867 21.9733 17.3334 22.32 17.3334 22.6667C17.3334 23.0133 17.1867 23.36 16.9467 23.6133C16.8801 23.6667 16.8134 23.72 16.7467 23.7733C16.6667 23.8267 16.5867 23.8667 16.5067 23.8933C16.4267 23.9333 16.3467 23.96 16.2534 23.9733C16.1734 23.9867 16.0801 24 16.0001 24Z"
                                fill="#ECBF1D" />
                        </svg>
                    </div>


                    <div className={`text-xs xl:text-base font-normal text-[${useTheme().colors.text80Body}]`}>
                        Please be careful about the correct address. You might lose all of your money
                    </div>
                </div>
            </div>


            {snackbarConfig.show ? <Snackbar className={'fixed bottom-[24px] left-0 right-0 m-auto'}
                toastType={snackbarConfig.type}
                childMode={mode}
                btnText={snackbarConfig.btnText}
                icon={snackbarConfig.icon}
                onClick={snackbarConfig.onClick}
                text={snackbarConfig.text} /> : ''}
        </>
    )
}
