import React, { useEffect } from "react";

export function useOutsideClick(
  //Refrence of the element we defined useRef for.
  ref: React.RefObject<HTMLElement>,
  //The callBack that we will call when user clicks --
  //outside of the element.
  callBack: () => void
) {
  /* On each change of ref we will call handleClickOutside */
  useEffect(() => {
    /*
    So when ref status changes we will call this function.
    This function checks if the original ref that we passed as first argument,--
    does or does not contain the event.target from the event listener.
   */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    }
    //Adding an event listener on mouse down and as its--
    //callback we pass the handleClickOutside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //The clean up function
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callBack]);
}
