import styled, {css} from "styled-components";

export const TextArea = styled.div<{ required: boolean, size: string }>`
  label {
    font-weight: 500;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: ${({theme}) => theme.colors.text50};
  }

  ${({required}) => {
      if(required){
          return css`
            label{
              &:after{
                content: '*';
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.02em;
                margin-left: 4px;
                color: ${({theme}) => theme.colors.error50Main};
              }
            }
          `
      }
  }}
  
  .inputHolderWrapper{
    min-width: 100%;
    background: ${({theme}) => theme.colors.surface50Background};
    border-color: ${({theme}) => theme.colors.surface80Divider};
    border-width: 1px;
    border-style: solid;
    border-radius: 24px;
    display: flex;
    align-items: center;
    textarea{
      height: calc(100% - 2px);
      width: 100%;
      border: unset;
      border-radius: 24px;
      background: ${({theme}) => theme.colors.surface50Background};
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: ${({theme}) => theme.colors.text80Body};
      padding: 12px 20px;

      &::placeholder{
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color:${({theme}) => theme.colors.text30};
        text-transform: capitalize;
      }
      
      &:focus-visible{
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
  
  ${({size}) =>{
      if(size === 'Big'){
          return css`
            .inputHolderWrapper{
              padding: 0 20px;
            }
          `
      }
      
      if(size === 'Medium'){
          return css`
            .inputHolderWrapper{
              padding: 0 12px;
            }
          `
      }
  }}
`


export const Hint = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({theme}) => theme.colors.text30};
  margin-top: 8px;
`
