import type {Theme} from "../types/theme";

export const LightTheme: Theme = {
    colors: {
        mode:'light',
        surface40: '#F4F7F8',
        surface50Background: '#FFFFFF',
        surface60: '#F4F7F8',
        surface70: '#E9EFF1',
        surface80Divider: '#D3D8DA',
        primary20: '#E6F9FE',
        primary30: '#7EE0FB',
        primary40: '#1BC8F8',
        primary50Main: '#07ACDA',
        primary60: '#0698C1',
        primary70: '#034A5E',
        primary80: '#011F28',
        text20: '#FFFFFF',
        text30: '#BBC6C9',
        text40: '#96A7AC',
        text50: '#82969C',
        text60: '#56676C',
        text70: '#3B474A',
        text80Body: '#191E1F',
        text90: '#080808',
        error20: '#FEF1F2',
        error30: '#FDD9DC',
        error40: '#FAAEB4',
        error50Main: '#F23645',
        error60: '#E60F20',
        success40: '#E0FAE9',
        success50Main: '#22D35E',
        success60: '#1BA74B',
        warning40: '#FDFAED',
        warning50Main: '#ECBF1D',
    }
};

export const DarkTheme: Theme = {
    colors: {
        mode:'dark',
        surface40: '#1B1E22',
        surface50Background: '#131722',
        surface60: '#1B1E22',
        surface70: '#2B3036',
        surface80Divider: '#3A3E46',
        primary20: '#1e5c58',
        primary30: '#217773',
        primary40: '#24928e',
        primary50Main: '#2BBBB2',
        primary60: '#40ccc6',
        primary70: '#9bece9',
        primary80: '#c6f5f3',
        text20: '#121416',
        text30: '#414345',
        text40: '#54575A',
        text50: '#676B70',
        text60: '#878A8E',
        text70: '#CBCDCF',
        text80Body: '#ECEDEE',
        text90: '#FFFFFF',
        error20: '#262428',
        error30: '#2E292E',
        error40: '#402C31',
        error50Main: '#E54350',
        error60: '#EB6F79',
        success40: '#292E2E',
        success50Main: '#3C9059',
        success60: '#4BB46F',
        warning40: '#232219',
        warning50Main: '#C8A31E',
    }
};
