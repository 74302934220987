import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const DepositO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.75 13.77C19.75 14.18 19.41 14.52 19 14.52C18.59 14.52 18.25 14.18 18.25 13.77V5.31006L5.53006 18.03C5.38006 18.18 5.19006 18.25 5.00006 18.25C4.81006 18.25 4.62006 18.18 4.47006 18.03C4.18006 17.74 4.18006 17.26 4.47006 16.97L17.1901 4.25H8.73C8.32 4.25 7.98 3.91 7.98 3.5C7.98 3.09 8.32 2.75 8.73 2.75H19C19.1112 2.75 19.2172 2.77499 19.3126 2.81959C19.3917 2.85539 19.4656 2.90553 19.5301 2.97C19.5952 3.03514 19.6457 3.10987 19.6816 3.18988C19.7254 3.2847 19.75 3.38982 19.75 3.5V13.77Z" fill={props.fill}/>
            <path d="M3.5 22.75H20.5C20.91 22.75 21.25 22.41 21.25 22C21.25 21.59 20.91 21.25 20.5 21.25H3.5C3.09 21.25 2.75 21.59 2.75 22C2.75 22.41 3.09 22.75 3.5 22.75Z" fill={props.fill}/>
        </svg>
    );
};

export default DepositO;
