import { Count, PageTitle, SubTitle } from "./styles";
import { useTheme } from "styled-components";

export interface Title_Options {
  arrow?: any;
  icon?: any;
  text?: any;
  subTitle?: any;
  from?: any;
  to?: any;
  activePlan?: any;
  onClickIcon?: any;
}

export default function PageTitles(props: Title_Options) {
  const text50 = useTheme().colors.text50;

  return (
    <PageTitle className={"flex items-center"}>
      {props.arrow ? <div className={"mr-2"}>{props.arrow}</div> : ""}

      <div
        className={
          "font-semibold tracking-tight mr-2 text-2xl xl:text-[1.75rem] " +
          `text-[${useTheme().colors.text90}]`
        }
      >
        {props.text}
      </div>

      {props.subTitle ? (
        <div
          className={
            "font-semibold tracking-tight mr-2 text-2xl xl:text-[1.75rem] " +
            `text-[${text50}]`
          }
        >
          / {props.subTitle}
        </div>
      ) : (
        ""
      )}

      {!props.activePlan && props.to && props.from ? (
        <Count
          className={"font-normal tracking-tight text-xs xl:text-base mr-2"}
        >
          ({props.from}/{props.to})
        </Count>
      ) : (
        ""
      )}

      <div onClick={props.onClickIcon}>{props.icon}</div>
    </PageTitle>
  );
}
