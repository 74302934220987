import {useTheme} from "styled-components";
import Button from "../Button";
import {NavLink} from "react-router-dom";

export interface Empty_Options {
    icon: any;
    mobileIcon?: any;
    title: any;
    des: any;
    onclick?: any;
    linkText: any;
    linkIcon: any;
    to?: any;
}
export default function Empty(props: Empty_Options){
    return(
        <div className={'flex xl:h-[90%] items-center justify-center flex-col mt-20 xl:mt-0'}>
            <div className={'mb-4 hidden xl:block'}>
                {props.icon}
            </div>

            <div className={'mb-4 block xl:hidden'}>
                {props.mobileIcon}
            </div>

            <div className={'font-semibold text-2xl xl:text-3xl tracking-tight text-center ' + ` text-[${useTheme().colors.text90}]`}>
                {props.title}
            </div>

            <div className={'font-normal text-sm xl:text-base text-center tracking-tight my-6' + ` text-[${useTheme().colors.text80Body}]`}>
                {props.des}
            </div>

           <NavLink to={props.to}>
               <Button className={'flex items-center font-semibold text-base tracking-tight xl:rounded-3xl rounded-[22px] h-12  xl:px-6 px-4'}
                       text={props.linkText}
                       buttonStyleType={'MPrimary'}
                       icon={props.linkIcon}/>
           </NavLink>

        </div>
    )
}
