import http from './http-common'

let token = localStorage.getItem('token');

class AccountService {
    walletBalance(customToken?:string) {
        return http.get<any>('account/wallet/balance', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    walletTransactions(page: any,customToken?:string) {
        return http.get<any>(`account/wallet/transactions?page=${page}`, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    walletDeposit(data: any, customToken?:string) {
        return http.post<any>('account/wallet/deposit', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    walletWithdraw(data: any, customToken?:string) {
        return http.post<any>('account/wallet/withdraw', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    profile(customToken?:any) {
        return http.get<any>('account/profile', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    billings(customToken?:string) {
        return http.get<any>('account/billings', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    gifts(customToken?:string) {
        return http.get<any>('account/gifts', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    giftsDays(customToken?:string){
        return http.get<any>('account/gifts/days',{
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    activeGift(id: any, customToken?:string) {
        return http.post<any>('account/gifts/' + id + '/active', '', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    upgradePlan(data: any,customToken?:string) {
        return http.post<any>('account/plan/upgrade', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
    }

    changePassword(data: any, customToken?:string) {
        return http.patch<any>('account/change-password', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    changeEmail(data: any, customToken?:string) {
        return http.patch<any>('account/change-email', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    verifyEmail(customToken?:string) {
        return http.post<any>('account/email/resend', '', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
            },
        })
    }


    updateProfile(data: any,customToken?:string) {
        return http.post<any>('account/update-profile', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-type': 'multipart/form-data',
            },
        })
    }

    sessions(customToken?:string) {
        return http.get<any>('account/sessions', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    revoke(id: any, customToken?:string) {
        return http.delete<any>(`account/sessions/${id}/revoke`, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    logout(customToken?:string) {
        return http.post<any>('account/logout', '', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }



    cancelSubscription(customToken?:string){
        return http.post<any>('account/plan/cancel-subscription', '', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    generateKey(customToken?:string){
        return http.post<any>('account/2fa/get-secret-key', '', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    enable2fa(data: any,customToken?:string){
        return http.post<any>('account/2fa/enable', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    }

    disable2fa(customToken?:string){
        return http.post<any>('account/2fa/disable', '', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }
}

export default new AccountService()
