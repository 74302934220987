import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import window from "../../assets/icons/device/Windows.svg";
import chrome from "../../assets/icons/device/Chrome.svg";
import safari from "../../assets/icons/device/Safari.png";
import firefox from "../../assets/icons/device/Firefox.svg";
import android from "../../assets/icons/device/Android.svg";
import apple from "../../assets/icons/device/Apple.svg";
import linux from "../../assets/icons/device/linux.png";
import AccountService from "../../services/account.service";
import moment from "moment/moment";
import Snackbar, { ToastTypes } from "../../components/ds/Snackbar";
import CloseB from "../../assets/icons/Close-b";
import SessionLoading from "./SessionLoading";
import { useNavigate } from "react-router-dom";
import CheckB from "../../assets/icons/Check-b";
import useAuth from "../../useAuth";

export default function Sessions() {
  const text80Body = useTheme().colors.text80Body;
  const surface60 = useTheme().colors.surface60;
  const error50Main = useTheme().colors.error50Main;
  const success50Main = useTheme().colors.success50Main;
  const mode = useTheme().colors.mode;
  const surface80Divider = useTheme().colors.surface80Divider;
  const text50 = useTheme().colors.text50;
  const primary50Main = useTheme().colors.primary50Main;
  const [result, setResult] = useState<any>([]);
  const [snackbarConfig, setSnackbarConfig] = React.useState<any>({
    text: "",
    icon: "",
    btnText: "",
    onClick: "",
    show: false,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { token } = useAuth();

  useEffect(() => {
    AccountService.sessions(token)
      .then((response: any) => {
        let data = [];
        for (let key in response?.data) {
          data.push(response?.data[key]);
        }
        setResult(data);
        setLoading(false);
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }, []);

  function BrowserLogo(data: any) {
    if (data === "Chrome") {
      return <img src={chrome} alt={"chrome icon"} />;
    } else if (data === "Firefox") {
      return <img src={firefox} alt={"firefox icon"} />;
    } else if (data === "Safari") {
      return <img src={safari} alt={"Safari icon"} className={"w-8 h-8"} />;
    }
  }

  function Platform(data: any) {
    if (data === "Windows" || data === "Windows NT") {
      return <img src={window} alt={"window icon"} />;
    } else if (data === "OS X" || data === "Macintosh" || data === "iOS") {
      return <img className={"w-6"} src={apple} alt={"apple icon"} />;
    } else if (data === "Debian" || data === "Ubuntu") {
      return <img className={"w-6"} src={linux} alt={"linux icon"} />;
    } else if (data === "AndroidOS") {
      return <img className={"w-6"} src={android} alt={"android icon"} />;
    }
  }

  function revoke(data: any) {
    AccountService.revoke(data.id, token)
      .then((response: any) => {
        setResult(result.filter((i: any) => i.id !== data.id));
        setSnackbarConfig({
          text: "Device revoked",
          icon: <CheckB fill={success50Main} />,
          btnText: "Ok",
          type: ToastTypes.Success,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });
      })
      .catch((err) => {
        setSnackbarConfig({
          text: err.response.data.message,
          icon: <CloseB fill={error50Main} />,
          btnText: "Ok",
          type: ToastTypes.Error,
          onClick: () =>
            setSnackbarConfig({
              show: false,
            }),
          show: true,
        });

        setTimeout(() => {
          if (err.response.status === 401) {
            navigate("/");
          }
          if (err.response.status === 403) {
            if (
              err?.response?.data?.message ===
              "Your email address is not verified."
            ) {
              navigate("/settings/profile");
            } else {
              navigate("/plans");
            }
          }
          if (err.response.status === 404) {
            navigate("*");
          }
        }, 3000);
      });
  }

  return (
    <>
      <div className={"w-full lg:w-4/5 2xl:w-3/5"}>
        {loading ? (
          <SessionLoading />
        ) : (
          <div
            className={
              "table lg:rounded-3xl w-full lg:border lg:border-solid mt-6 mb-4 " +
              `lg:border-[${surface80Divider}]`
            }
          >
            <div className="table-header-group">
              <div className="table-row">
                <div
                  className={
                    "table-cell rounded-tl-3xl py-3 lg:pl-6 text-left text-xs  lg:text-base  font-semibold " +
                    `text-[${text50}]` +
                    ` lg:bg-[${surface60}]`
                  }
                >
                  Device
                </div>
                <div
                  className={
                    "table-cell py-3 text-left text-xs  lg:text-base font-semibold " +
                    `text-[${text50}]` +
                    ` lg:bg-[${surface60}]`
                  }
                >
                  Last accessed
                </div>
                <div
                  className={
                    "lg:table-cell hidden  py-3  text-left text-xs  lg:text-base font-semibold " +
                    `text-[${text50}]` +
                    ` lg:bg-[${surface60}]`
                  }
                >
                  Signed in
                </div>
                <div
                  className={
                    "table-cell py-3 lg:mr-6 rounded-tr-3xl " +
                    ` lg:bg-[${surface60}]`
                  }
                ></div>
              </div>
            </div>
            <div className="table-row-group">
              {result?.map((item: any) => {
                return (
                  <div className={"table-row"}>
                    <div
                      className={
                        "table-cell border-b border-solid align-middle py-2.5 lg:py-5 lg:pl-6 text-left font-normal text-xs  lg:text-base " +
                        `text-[${text80Body}]` +
                        ` border-[${surface80Divider}]`
                      }
                    >
                      <div className={"flex gap-2"}>
                        {Platform(item.platform)}
                        {BrowserLogo(item.browser)}
                        {item.ip}
                      </div>
                    </div>
                    <div
                      className={
                        "table-cell border-b border-solid align-middle  py-2.5 lg:py-5 text-left font-normal text-xs  lg:text-base " +
                        `text-[${text80Body}]` +
                        ` border-[${surface80Divider}]`
                      }
                    >
                      {moment(item.last_used_at).format("MMM Do YYYY")}
                    </div>
                    <div
                      className={
                        "lg:table-cell hidden border-b border-solid align-middle  py-2.5 lg:py-5 text-left font-normal text-base " +
                        `text-[${text80Body}]` +
                        ` border-[${surface80Divider}]`
                      }
                    >
                      {moment(item.created_at).format("MMM Do YYYY")}
                    </div>
                    <div
                      className={
                        "table-cell border-b border-solid align-middle  py-2.5 lg:py-5 lg:mr-6 text-[10px] lg:text-sm font-normal text-right " +
                        `text-[${text50}]` +
                        ` border-[${surface80Divider}]`
                      }
                    >
                      {item.is_current ? (
                        "Current Session"
                      ) : (
                        <div
                          className={
                            "text-xs  lg:text-base font-semibold cursor-pointer " +
                            `text-[${primary50Main}]` +
                            ` border-[${surface80Divider}]`
                          }
                          onClick={() => revoke(item)}
                        >
                          Revoke
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      {snackbarConfig.show ? (
        <Snackbar
          className={"fixed bottom-[24px] left-0 right-0 m-auto"}
          toastType={snackbarConfig.type}
          childMode={mode}
          btnText={snackbarConfig.btnText}
          icon={snackbarConfig.icon}
          onClick={snackbarConfig.onClick}
          text={snackbarConfig.text}
        />
      ) : (
        ""
      )}
    </>
  );
}
