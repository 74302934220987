import {Hint, TextArea} from "./styles";


export interface TextAreaField_Options {
    icon?: any;
    label?: any;
    placeholder?: any;
    hint?: any;
    size?: any;
    name?: any;
    value?: string;
    required?:any
    onChange?: any
    onBlur?: any;
}

export default function TextAreaField(props: TextAreaField_Options) {
    return (
        <TextArea size={props.size} required={props.required}>
            <label>{props.label}</label>
            <div className={'inputHolderWrapper mt-2'}>
                <textarea placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange} onBlur={props.onBlur}/>
            </div>
            {props.hint?<Hint>{props.hint}</Hint>:''}
        </TextArea>
    )
}